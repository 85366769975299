var $ = require('jquery');
var SearchComponent = require('./search-components');

module.exports = SearchComponent.extend({
  options: {
    destinationAddress:{
      latitude: 'destinationAddress.latitude', 
      longitude: 'destinationAddress.longitude',
      city: 'destinationAddress.city',
      stateProvince: 'destinationAddress.stateProvince',
      country: 'destinationAddress.country'
    }
  },
  events: {
    'click .js-single-search-incity': 'inCityClickHandler',
    'click .js-single-search-nearaddress': 'nearAddressClickHandler',
    'click .js-single-search-propertySearch': 'propertySearchClickHandler',
    'keydown .js-single-search-incity': 'inCityClickHandler',
    'keydown .js-single-search-nearaddress': 'nearAddressClickHandler',
    'keydown .js-single-search-propertySearch': 'propertySearchClickHandler'
  },
  init: function() {
    var _self = this;
    _self.defineGlobalVariables();
    _self.subscribeDOMEvents();
    _self.$el.tabs({
      activate: function(event, ui) {
        ui.oldPanel.addClass('ui-tabs-hide');
        ui.newPanel.removeClass('ui-tabs-hide');
        ui.oldTab.removeClass('ui-tabs-selected');
        ui.newTab.addClass('ui-tabs-selected');
      },
      create: function (event, ui) {
        ui.tab.parent().find('li,a').removeAttr('tabindex').removeClass('tab-active');
        ui.tab.addClass('ui-tabs-selected');
        if (_self.$el.find('.single-search-type').val() === "true") {
          if(_self.$el.find('.js-single-search-propertySearch').length){
            _self.setPropertySearchAttributes();  
          }
          else{
            _self.setNearAddressAttributes();
          }
          _self.$el.tabs({ active: _self.$el.find('.js-single-search-nearaddress').parent().index() });
        } else {
            _self.setInCityAttributes();
        }
      }
    });
  },

  defineGlobalVariables: function() {
    var _self = this;
    if (_self.currentTemplate !== 'searchForm-findAndReserve') {
      _self.searchForm = _self.$el.parents('.m-search-form').length ? _self.$el.parents('.m-search-form') : _self.$el;
      _self.locationTrigger = $('.location-trigger');
      _self.meetingEventEditSearchLocation = $('.m-search-tabs #meetingevent-city-airport #editsearch-location');
      _self.meetingEventSingleSearchDestination = $('.m-search-tabs #meetingevent-city-airport .single-search-destination');
    } else {
      _self.searchForm = _self.$el;
    }
    _self.singleSearchLocation = _self.searchForm.find('.single-search-location');
    _self.searchType = _self.searchForm.find('input[name="searchType"]');
    _self.singleSearchLocationTitleLabel = _self.searchForm.find('.single-search-location-title label');
    _self.singleSearchDestination = _self.searchForm.find('.single-search-destination');
    _self.searchTabs = _self.searchForm.find('.ui-tabs-nav li');
  },

  setInCityAttributes: function() {
    var _self = this;
    _self.parent.autoCompleteHandler.$input = _self.$el.find('.single-search-destination');
    _self.searchType.attr({'value': 'InCity'});
    _self.singleSearchLocation.css('display', 'none');
    _self.searchTabs.removeAttr('tabindex');
    _self.singleSearchDestination.css('display', 'block');
  },

  setNearAddressAttributes: function() {
    var _self = this;
    _self.parent.autoCompleteHandler.$input = _self.$el.find('.single-search-location');
    _self.searchType.attr({'value': 'NearAddress'});
    _self.singleSearchLocation.css('display', 'block');
    _self.searchTabs.removeAttr('tabindex');
    _self.singleSearchDestination.css('display', 'none');
  },
  setPropertySearchAttributes: function() {
    this.parent.autoCompleteHandler.$input = this.$el.find('.single-search-location');
    this.searchType.attr({'value': 'InHotelSearch'});
    this.singleSearchLocation.css('display', 'block');
    this.searchTabs.removeAttr('tabindex');
    this.singleSearchDestination.css('display', 'none');
  },

  inCityClickHandler: function(event) {
    var _self = this;
    var code = (event.keyCode ? event.keyCode : event.which);
    if (code === 13 || event.type === 'click') {
      _self.resetInputs(_self.parent.autoCompleteHandler.inCityInputs);
      _self.setInCityAttributes();
      _self.searchForm.find('input[name="destinationAddress.addressLine1"],input[name="destinationAddress.city"],input[name="destinationAddress.stateProvince"], input[name="destinationAddress.country"], input[name="destinationAddress.postalCode"]').val('');
      _self.singleSearchLocationTitleLabel.attr('for', 'editsearch-location');
      _self.searchForm.find('.meetings-near-address-search').css('display', 'block');
      if (_self.meetingEventEditSearchLocation.hasClass('hidden')) {
        _self.meetingEventSingleSearchDestination.css('display', 'none');
      }
      _self.locationTrigger.css('display', 'block');
      _self.searchForm.find('input#city-airport').attr({'name': _self.options.destinationAddress.city});
      _self.searchForm.find('input.destinationAddress.stateProvince').attr({'name': _self.options.destinationAddress.stateProvince});
      _self.searchForm.find('input.destinationAddress.country').attr({'name': _self.options.destinationAddress.country});
      _self.searchForm.find('.search-locality,.search-adminDistrict,.search-countryRegion').attr({'name': ''});
    }
  },

  nearAddressClickHandler: function(event) {
    var _self = this;
    var code = (event.keyCode ? event.keyCode : event.which);
    if (code === 13 || event.type === 'click') {
      _self.resetInputs(_self.parent.autoCompleteHandler.addressInputs);
      _self.setNearAddressAttributes();
      _self.singleSearchLocationTitleLabel.attr('for', 'editsingle-location');
      if (!_self.meetingEventEditSearchLocation.hasClass('hidden')) {
        _self.meetingEventSingleSearchDestination.css('display', 'block');
      }
      _self.locationTrigger.css('display', 'none');
      _self.searchForm.find('input#city-airport,input.destinationAddress.stateProvince,input.destinationAddress.country').attr({'name': ''});
      _self.searchForm.find('.search-locality').attr({'name': _self.options.destinationAddress.city});
      _self.searchForm.find('.search-adminDistrict').attr({'name': _self.options.destinationAddress.stateProvince});
      _self.searchForm.find('.search-countryRegion').attr({'name': _self.options.destinationAddress.country});
    }
  },
  propertySearchClickHandler:function(event){
    var code = (event.keyCode ? event.keyCode : event.which);
    if (code === 13 || event.type === 'click') {
      this.resetInputs();
      this.setPropertySearchAttributes();
      this.singleSearchLocationTitleLabel.attr('for', 'editsingle-location');
      if (!this.meetingEventEditSearchLocation.hasClass('hidden')) {
        this.meetingEventSingleSearchDestination.css('display', 'block');
      }
      this.locationTrigger.css('display', 'none');
      this.searchForm.find('input#city-airport,input#destinationAddress.stateProvince,input#destinationAddress.country').attr({'name': ''});
      this.searchForm.find('.search-locality').attr({'name': this.options.destinationAddress.city});
      this.searchForm.find('.search-adminDistrict').attr({'name': this.options.destinationAddress.stateProvince});
      this.searchForm.find('.search-countryRegion').attr({'name': this.options.destinationAddress.country});
      if (this.searchForm.find('input[name="associateViewFlag"]').length) {
        var $hotelInput = this.searchForm.find('.js-hotel-search');
        if ($hotelInput.length > 1 && $hotelInput.val() === "" && $hotelInput[1].dataset && $hotelInput[1].dataset.defaultPropertyCode) {
          $($hotelInput[1]).val($hotelInput[1].dataset.defaultPropertyName)
          this.searchForm.find('.search-property-code').val($hotelInput[1].dataset.defaultPropertyCode);
        }
      }
    }
  },
  resetInputs: function(inputMap) {
    var _self = this;
    _self.parent.autoCompleteHandler.resetInputs();
    _.forEach(inputMap, function(value, name) {
      _self.parent.autoCompleteHandler.$parent.$el.find(_self.parent.autoCompleteHandler.options.inputs[name]).val(value || '');
    }, _self);
  }
});
