var Picker = require('pickadate/lib/picker');
require('./pickadate/picker.date.js');
Picker.lang = {
  'DE': {
    labelMonthPrev: 'zurück',
    labelMonthNext: 'Vor',
    firstDay: 1,
    format: 'ddd, d mmm, yyyy',
    formatShort: 'ddd, d mmm',
    formatSubmit: 'dd.mm.yyyy'
  },
  'AU': {
    labelMonthPrev: 'Previous month',
    labelMonthNext: 'Next month',
    firstDay: 1,
    format: 'ddd, d mmm, yyyy',
    formatShort: 'ddd, d mmm',
    formatSubmit: 'dd/mm/yyyy'
  },
  'GB': {
    labelMonthPrev: 'Previous month',
    labelMonthNext: 'Next month',
    firstDay: 1,
    format: 'ddd, d mmm, yyyy',
    formatShort: 'ddd, d mmm',
    formatSubmit: 'dd/mm/yyyy'
  },
  'US': {
    labelMonthPrev: 'Previous month',
    labelMonthNext: 'Next month',
    format: 'ddd, mmm d, yyyy',
    formatShort: 'ddd, mmm d',
    formatSubmit: 'mm/dd/yyyy'
  },
  'FR': {
    labelMonthPrev: 'Précédent',
    labelMonthNext: 'Suivant',
    firstDay: 1,
    format: 'ddd d mmm yyyy',
    formatShort: 'ddd d mmm',
    formatSubmit: 'dd/mm/yyyy'
  },
  'es': {
    labelMonthPrev: 'Ant',
    labelMonthNext: 'Sig',
    firstDay: 1,
    format: 'ddd, d mmm, yyyy',
    formatShort: 'ddd, d mmm',
    formatSubmit: 'dd/mm/yyyy'
  },
  'JP': {
    labelMonthPrev: '前',
    labelMonthNext: '次',
    firstDay: 0,
    format: 'yyyy年 mmm d日 (ddd)',
    formatShort: 'mmmm d日 (ddd)',
    formatSubmit: 'yyyy/mm/dd'
  },
  'CN': {
    labelMonthPrev: '上月',
    labelMonthNext: '下月',
    firstDay: 0,
    format: 'yyyy年 mmm d日, ddd',
    formatShort: 'mmm d日, ddd',
    formatSubmit: 'yyyy-mm-dd',
    formatLength: 12
  },
  'BR': {
    labelMonthPrev: 'Anterior',
    labelMonthNext: 'Pr&oacute;ximo',
    firstDay: 0,
    format: 'ddd, d mmm, yyyy',
    formatShort: 'ddd, d mmm',
    formatSubmit: 'dd/mm/yyyy'
  },
  'IT': {
    labelMonthPrev: 'Mese precedente',
    labelMonthNext: 'Prossimo mese',
    firstDay: 1,
    format: 'ddd, d mmm, yyyy',
    formatShort: 'ddd, d mmm ',
    formatSubmit: 'dd/mm/yyyy'
  },
  'KR': {
    labelMonthPrev: '이전 달',
    labelMonthNext: '다음 달',
    firstDay: 0,
    format: 'yyyy년 mm월 dd일',
    formatShort: 'mmm dd, ddd',
    formatSubmit: 'yyyy.mm.dd'
  },
  'RU': {
    labelMonthPrev: 'Предыдущий месяц',
    labelMonthNext: 'В следующем месяце',
    firstDay: 1,
    format: 'ddd, d mmm, yyyy',
    formatShort: 'ddd, d mmm',
    formatSubmit: 'dd.mm.yyyy'
  },
  'TW': {
     labelMonthPrev: '上個月',
     labelMonthNext: '下個月',
     firstDay: 0,
     format: 'yyyy年 m月 d日',
     formatShort: 'mmm d日, ddd',
     formatLength: 15,
     formatSubmit: 'mm/dd/yyyy'
   },
   'AE': {
    labelMonthPrev: "الشهر السابق",
    labelMonthNext: "الشهر القادم",
    firstDay: 1,
    format: "dddd, d mmmm, yyyy ",
    formatShort: "ddd, mmm d",
    formatSubmit: "yyyy/mm/dd"
  }
};

module.exports = Picker;