
/**
This javascript library is used to animate placeholder text inside input field.
**/

module.exports = {
  init: function($el) {
   
    this.$el = $el;

    if (this.$el.hasClass('animate-search-form')) {
      this.bindEvents();
      this.animateElement = this.$el.find('.animate-placeholder-text');
      this.inputElement = this.$el.find('.single-search-destination');
      this.counter = 0;
      this.pauseAnimate = false;
      this.checkPlaceholderAnimation();
      setTimeout(function(){
        this.startPlaceholderAnimation();
      }.bind(this),2000);
    }
  },

  bindEvents: function() {
    var _self = this;
    _self.$el.find(".single-search").on('keyup', function(){
        _self.checkPlaceholderAnimation()
    });
    _self.$el.find(".js-clear-trigger").on('mousedown', function(){
        _self.resumePlaceholderAnimation()
    });
  },

   /**
    * This function is used to start placeholder animation after interval of 5 seconds.
    * Once the list is fully iterated then it will stop the animation by using clear interval
    **/
  startPlaceholderAnimation: function() {
    var _self = this;
    var dataList = _self.$el.data('placeholderList').split('#');
    dataList.push(dataList[0]);
    var animateInterval = setInterval(function(){
      if(!_self.pauseAnimate) {
        _self.animateElement.fadeOut(function(){
          $(this).html(dataList[_self.counter]).fadeIn();
        }); 
        _self.counter++;
        if(_self.counter >= dataList.length) {
          clearInterval(animateInterval);
        } 
      }
    },5800);
    this.inputElement.attr('placeholder','');
    this.animateElement.text(dataList[0]);
    this.checkPlaceholderAnimation();
  },

  /**
    * This function is used to check if user starts typing in input box then it will set pauseAnimate flag to true and hide plaeholder text.
  **/
 checkPlaceholderAnimation: function() {
    if(this.inputElement.val()) {
      this.animateElement.hide();
      this.pauseAnimate = true;
    } else {
      this.resumePlaceholderAnimation();
    }      
  },

  /**
    * This function is used to reume placeholder animation if user click on cross icon in input field
  **/
 resumePlaceholderAnimation: function() {
    this.animateElement.show();
    this.pauseAnimate = false;
  }
}