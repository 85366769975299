var extend = require('extend');
var localStorageFallback = require('local-storage-fallback');
var localStorage = localStorage || localStorageFallback.default;
var cache = {};

/**
 * Instantiates recent search searvice for autocomplete component
 * @param {string} key - Recent search localstorage key 
 * @param {Number} limit - Max limit for recent search
 */
function RecentSearch(key, limit) {
  this.key = key;
  this.limit = limit;
  this.recentSearch = localStorage.getItem(this.key) ? JSON.parse(localStorage.getItem(this.key)) : [];
  this.recentSearch = this.recentSearch.slice(0, limit);
}

var RecentSearchApi = {
  /**
   * Returns recent search list
   * @returns {Array} Recent search array
   */
  get: function() {
    return this.recentSearch;
  },

  /**
   * Add new recent search item to the list and localStorage
   * @param {Object} data - New recent search item
   */
  set: function(data, uniqueKey) {
    var existingSearch = this.recentSearch.filter(function(item) {
      return item[uniqueKey] === data[uniqueKey];
    });

    if (existingSearch.length > 0) {
      return false;
    }

    this.recentSearch.unshift(data);
    this.recentSearch = this.recentSearch.slice(0, this.limit);
    localStorage.setItem(this.key, JSON.stringify(this.recentSearch));
  },

  /**
   * Clear recent search
   */
  clear: function() {
    this.recentSearch = [];
    localStorage.setItem(this.key, JSON.stringify(this.recentSearch));
  }
};


extend(RecentSearch.prototype, RecentSearchApi);

module.exports = {

  /**
   * Returns recent search instance
   * @param {string} key - localStorage key
   * @param {Number} limit - maximum recent search limit
   * @returns {Object} - RecentSearch instance
   */
  get: function(key, limit) {
    if (cache[key]) {
      return cache[key];
    }

    cache[key] = new RecentSearch(key, limit);
    return cache[key];
  }
};