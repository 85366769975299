/* global msgResources */
var AriesComponent = require('libs/aries-component');
var $ = require('jquery');
var _ = require('lodash');
var Popup = require('libs/popup');
var Constants = require('libs/constants');
var getMaxToDate = require('../utils/getMaxToDate');

module.exports = AriesComponent.extend({
  init: function(parent) {
    
    this.parent = parent;
    var options = this.options = parent.options;
    var _self = this;
    
    //Set Locale Options
    _.extend(options, this.setLocaleOptions());
    var lang = this.parent.$el.data('country-code');

    if (parent.singleDate) {
      if (lang === "") {
        lang = this.options.country;
      }
    }

    lang = lang || 'US';
    var localeDefaults = this.localeDefaults = ((lang && (options.lang[lang]) || (options.lang[lang.toLowerCase()])) || options.lang['US'] || options.lang['en-US']);
    _self.readonly = (window.innerWidth <= 760 && $('html').hasClass('touch')) ? true : false;
    
    if (lang == 'CN' || lang == 'JP' ||  lang == 'zh-CN' || lang == 'ja-JP') {
      localeDefaults.formatLong = localeDefaults.format;
      localeDefaults.format = localeDefaults.formatShort;
    }

    localeDefaults.formatLong = localeDefaults.format;

    _.extend($.fn.pickadate.defaults, localeDefaults);
    _.extend($.fn.pickadate.defaults, options.locale);

    //Initialize plugin
    if (parent.singleDate) {
      this.setOptionsForSingleDate();
      return;
    } else {
      this.setOptionsForDualDates(localeDefaults);
    }
  },
  setOptionsForSingleDate: function() {
    var _self = this;
    this.parent.from.$input.pickadate({
      min: new Date(this.parent.from.$input.data('min')),
      max: new Date(this.parent.from.$input.data('max')),
      formatSubmit: this.parent.format || "yyyy-mm-dd",
      hiddenName: true,
      today: '',
      clear: this.options.txtClear,
      close: this.options.txtDone,
      onRender: _.bind(_self.renderSingleDate, _self),
      editable: _self.parent.bindSingleDateEvents ? true : false,
      onOpen: function() {
        $(_self.parent.bindSingleCalendar).addClass(_self.options.dropdownActive).removeClass(_self.options.dropdownInActive);
      },
      onClose: function() {
        $(_self.parent.bindSingleCalendar).addClass(_self.options.dropdownInActive).removeClass(_self.options.dropdownActive);
        $(_self.parent.bindSingleCalendar).addClass('arrow-down-close');
        setTimeout(function(){$(_self.parent.bindSingleCalendar).removeClass('arrow-down-close');},1000);
      }
    });
  },
  setOptionsForDualDates: function(localeDefaults) {
    var parent = this.parent;
    var _self = this;
    var $parentComponent = _self.parent.$parent;
    var $setOptionredEye = $parentComponent.$el.find('.js-red-eye');
    var minDate = new Date(parent.$dateFromTo.data('min')).setHours(0, 0, 0, 0);
    var defaultCheckInDate = (new Date()).setHours(0, 0, 0, 0);
    var defaultCheckOutDate;

    parent.from.$input.add(parent.to.$input).attr('size', localeDefaults.formatLength || localeDefaults.format.length).prop('readonly', parent.readonly);
    if($setOptionredEye.length != 0) {
      _self.options.dates = {
        fromMin: new Date($setOptionredEye.val()),
        toMax: new Date(parent.$dateFromTo.data('max')),
        todaysDate: defaultCheckInDate
      };  
    }
    else {
      _self.options.dates = {
        fromMin: new Date(minDate),
        toMax: new Date(parent.$dateFromTo.data('max')),
        todaysDate: minDate > defaultCheckInDate ? minDate : defaultCheckInDate
      };
    }

    if(parent.hasFixedDateRange) {
      _self.options.dates.toMin = new Date(_self.options.dates.fromMin.getTime() + parent.SEC_IN_DAY)
    }else{
      _self.options.dates.toMin = _self.options.dates.fromMin;
    }

    _self.options.dates.fromMax = new Date(_self.options.dates.toMax.getTime() - parent.SEC_IN_DAY);
    defaultCheckOutDate = new Date(_self.options.dates.todaysDate);
    defaultCheckOutDate.setDate(defaultCheckOutDate.getDate() + 1);

    //Init Plugin
    parent.$dateFromTo.pickadate({
      container: parent.$el,
      min: _self.options.dates.fromMin,
      max: _self.options.dates.fromMax,
      today: '',
      clear: '',
      close: '',
      onRender: _.bind(_self.renderThis, _self),
      onOpen: _.bind(_self.openThis, _self),
      editable: 'true',
      closeOnSelect: parent.$parent.isAddSegmentTile ? false : true
    });
    parent.$dateFromToPicker = parent.$dateFromTo.pickadate('picker');

    if (_self.options.alwaysOnTop == true) {
      $('body').append(parent.$dateFromToPicker.$root.addClass(options.alwaysOnTopClass));
    }
    //Append plugin dependencies
    if(!parent.$parent.isAddSegmentTile) {
      parent.$dateFromToPicker.$holder.addClass(_self.options.styleClass).hide();
    }
    parent.$dateFromToPicker.$frame = parent.$dateFromToPicker.$holder.find(_self.options.pickerFrame);
    parent.pickerState = 'from';

    //Callback for setting date from calendar
    parent.$dateFromToPicker.on({
      'set': this.calendarDateSelected.bind(this)
    });


    if (parent.from.$submit.val() && parent.from.$submit.val() !== -1) {
      this.setDateFromPicker(parent.from.$submit.val(), 'from');
    }else if (!parent.isNonDatedSearch){
      this.setDateFromPicker(_self.options.dates.todaysDate, 'from');
    }

    if (parent.to.$submit.val() && parent.to.$submit.val() !== -1) {
      this.setDateFromPicker(parent.to.$submit.val(), 'to');
    }else if (!parent.isNonDatedSearch){
      this.setDateFromPicker(defaultCheckOutDate, 'to');
    }
  },
  setLocaleOptions: function() {
    var options = {};
    var datePickerOptions = this.parent.$el.find('.js-date-pick-container').data('options');
    
    if (typeof datePickerOptions != 'undefined') {
      options.locale = {
        monthsFull: datePickerOptions.monthFullList.split(','),
        monthsShort: datePickerOptions.monthShortList.split(','),
        weekdaysFull: datePickerOptions.weekdayFullList.split(','),
        weekdaysShort: datePickerOptions.weekdayShortList.split(',')
      },
      options.txtNight = datePickerOptions.nightLabel,
      options.txtNights = datePickerOptions.nightsLabel,
      options.txtClear = datePickerOptions.clearText,
      options.txtReset = datePickerOptions.resetText;
    } else if (typeof msgResources != 'undefined') {
      options.locale = {
        labelMonthPrev: msgResources.prevMonthText,
        labelMonthNext: msgResources.nextMonthText,
        monthsFull: $.parseJSON(msgResources.monthFullList),
        monthsShort: $.parseJSON(msgResources.monthShortList),
        weekdaysFull: $.parseJSON(msgResources.weekdayFullList),
        weekdaysShort: $.parseJSON(msgResources.weekdayShortList)
      },
      options.txtNight = msgResources.nightText,
      options.txtNights = msgResources.nightsText,
      options.txtClear = msgResources.clearText,
      options.txtDone = msgResources.doneText;
      options.country = msgResources.country;
    }
    return options;
  },
  calendarDateSelected: function(event) {
    var parent = this.parent,
        _self = this,
        yesterday = new Date(),
        selectedDateObject = _self.parent.$dateFromToPicker.get('select'),
        redEyeAlreadySelected,
        yesterdaysDate,
        yesterdaysMonth,
        yesterdaysYear,
        isEngageCalendar,
        redDate,
        $parentComponent = _self.parent.$parent.$el,
        $pickerTable = $parentComponent.find('.picker__table'),
        $redEyePopupElement = $parentComponent.find('.js-red-eye-popup'),
        $redEyeLength = $parentComponent.find('.js-red-eye').length,
        daysGap = parent.daysGap ? parent.daysGap : 1;        
    yesterday.setDate(yesterday.getDate() - 1);
    yesterdaysDate = yesterday.getDate();
    yesterdaysMonth = yesterday.getMonth();
    yesterdaysYear = yesterday.getFullYear();
    redDate = new Date(yesterdaysYear, yesterdaysMonth, yesterdaysDate, 0, 0, 0, 0);
    isEngageCalendar = parent.$parent.isAssociateView || parent.$parent.isAddSegmentTile || parent.$parent.isForceSellOverlay

    if (event.select) {
      if (parent.pickerState === 'from') {
        parent.$el.find('.picker__table [data-pick=' + redDate.getTime() + ']').each(function(index, item){
          if($(item).hasClass('t-date-start') ) {
            redEyeAlreadySelected = true;
          }
        });
        // in case if redEyeDate/yesterdays Date is selected and enagage flow is used
        if (isEngageCalendar && selectedDateObject.date === yesterdaysDate && selectedDateObject.month === yesterdaysMonth && selectedDateObject.year === yesterdaysYear && !redEyeAlreadySelected && parent.flexdateState !== 'flex') {
          /*
           * if datepicker is in addsegment overlay we open the redEye confirmation popup in another 
           * overlay on top of it
           */
          if(parent.$parent.isAddSegmentTile || parent.$parent.isForceSellOverlay){
            $parentComponent.find('.js-red-eye-confirmation-modal').show();
            $parentComponent.find('.js-red-eye-overlay').addClass('l-red-eye-overlay');
            $parentComponent.find('.js-red-eye-popup').addClass('l-add-segment-redeye-overlay');
          } else {
            var redEyePopup = new Popup({
              open: true,
              items: {
                src: '.js-red-eye-popup',
                type: 'inline'
              },
              selector: '.l-red-eye-date',
              alternateCloseMarkup: true,
              sourceBlock: '.modal-content',
              mainClass: 'modal-content'
            });
            redEyePopup.register();
          }
          var okayButton = $redEyePopupElement.find('.js-okay-btn'),
              cancelButton = $redEyePopupElement.find('.js-cancel-btn');

          cancelButton.off().on('click', function(e){
            // stop propagation to avoid document click which closes the datepicker on advanced search page
            e.stopPropagation();
            $pickerTable.find('div:contains('+(yesterdaysDate)+')').each(function(index, item){
              if($(item).html() == yesterdaysDate && $redEyeLength) {
                $(item).removeClass('picker__day--selected').addClass('l-red-eye-date');
              }
            });
            e.preventDefault();
            if(parent.$parent.isAddSegmentTile || parent.$parent.isForceSellOverlay) {
              $parentComponent.find('.js-red-eye-confirmation-modal').hide();
            }
            else {
              _self.pubsub.publish('POPUP_CLOSE');
            }
            parent.$el.find('.js-date-from').trigger('focus');
          });
          okayButton.off().on('click', function(e){
            // stop propagation to avoid document click which closes the datepicker
            e.stopPropagation();
            parent.from.$input.removeClass(_self.options.iserror);
            _self.setDateFromPicker();
            _self.handleFormat();
            if (parent.flexdateState === 'flex') {
              _self.setDateFromPicker(parent.datePlusDays(parent.from.date, parent.flex.$length.val()), 'to');
            } else if (!parent.to.date || parent.to.date <= parent.from.date) {
              _self.setDateFromPicker(parent.datePlusDays(parent.from.date, 1), 'to');
            }
            parent.updateHeader('from');
            if(parent.$parent.isAddSegmentTile || parent.$parent.isForceSellOverlay) {
              $parentComponent.find('.js-red-eye-confirmation-modal').hide();
            }
            else {
              _self.pubsub.publish('POPUP_CLOSE');
            }
            if (!parent.$parent.responsiveUtils.isMobileOrTablet()){
              if (parent.to.$input && parent.to.$input.is(':visible')) {
                setTimeout(function() {
                  parent.$el.find('.js-date-to').trigger('focus');
                });
              }
            }else {
              if (parent.to.$input && parent.to.$input.is(':visible')) {
                parent.openTo();
              }
            }
          });
        }
        else {
          parent.from.$input.removeClass(_self.options.iserror);
          _self.setDateFromPicker();
          this.handleFormat();
           //To date reset on days exceed 30
           const date1 = new Date(parent.to.date);
           const date2 = new Date(parent.from.date);
           const diffTime = Math.abs(date2 - date1);
           const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
           //
          if (parent.flexdateState === 'flex') {
            _self.setDateFromPicker(parent.datePlusDays(parent.from.date, parent.flex.$length.val()), 'to');
          } else if (!parent.to.date || parent.to.date <= parent.from.date || diffDays >= 30) {
            _self.setDateFromPicker(parent.datePlusDays(parent.from.date, daysGap), 'to');
          }
          parent.updateHeader('from');
          if (!parent.$parent.responsiveUtils.isMobileOrTablet()){
            if (parent.to.$input && parent.to.$input.is(':visible')) {
              setTimeout(function() {
                parent.$el.find('.js-date-to').trigger('focus');
              });
            }
          }else {
            if (parent.to.$input && parent.to.$input.is(':visible')) {
              parent.openTo();
            }
          }
        }
      } else {
        parent.to.$input.removeClass(_self.options.iserror);
        _self.setDateFromPicker();
        this.handleFormat();
        parent.updateHeader('to');
        if (!parent.$parent.responsiveUtils.isMobileOrTablet()){

          parent.close(parent);
        }else {
          parent.close(parent);
          parent.hideTakeoverScreen();
        }
      }
      parent.toggleLabel(null, parent);
      if (parent.flexdateState !== 'flex' && parent.from.date && parent.to.date) {
        var days = Math.round((parent.to.date - parent.from.date) / parent.SEC_IN_DAY);
        if (days > 9) {
          days = 1;
        }
        parent.flex.$length.val(days || 1);
      }
    }
    else {
      if($redEyeLength !== 0 && $parentComponent.find('.l-red-eye-date').length === 0) {
        var yesterday = new Date(),
          dd,
          mm,
          yy,
          redDate;
        yesterday.setDate(yesterday.getDate() - 1);
        dd = yesterday.getDate();
        mm = yesterday.getMonth();
        yy = yesterday.getFullYear();
        redDate = new Date(yy, mm, dd, 0, 0, 0, 0);

        $pickerTable.find("[data-pick='" + redDate.getTime() + "']").each(function(index, item){
          var $item = $(item);
          if(!$item.hasClass('t-date-start') && $redEyeLength) {
            $item.addClass('l-red-eye-date');
          }
        });
      }
    }
  },
  renderThis: function() {
    var _self = this.parent;
    var options = this.options;
    var fromDate = _self.$el.find('.js-date-from');
    var toDate = _self.$el.find('.js-date-to');
    if (!_self.$dateFromTo.hasClass(options.pickerActive) || (!_self.from.$input && !_self.to.$input)) {
      return;
    }
    if (_self.pickerState === 'from') {
      fromDate.addClass(options.pickerActive);
      toDate.removeClass(options.pickerActive);
    } else {
      fromDate.removeClass(options.pickerActive);
      toDate.addClass(options.pickerActive);
    }
    fromDate = this.strDateToLong(_self.from.date) || null;
    toDate = this.strDateToLong(_self.to.date) || null;
    var $root = _self.$dateFromToPicker.$root;
    var $lastRow = $root.find('.picker__table tr:last');
    $root.find('.' + options.inRangeClass).removeClass(options.inRangeClass);
    $root.find('.' + options.startDayClass).removeClass(options.startDayClass);
    $root.find('.' + options.endDayClass).removeClass(options.endDayClass);

    if ($lastRow.find('.picker__day--outfocus').length === 7) {
      $lastRow.addClass('is-hidden');
    }
    if (fromDate) {
      $root.find('[data-pick="' + fromDate + '"]').addClass(options.startDayClass);
    }
    if (fromDate && toDate && fromDate !== toDate) {
      var days = $root.find('.picker__day');
      var idx = 0;
      var dataPick = null;
      var node = null;

      while (node = days[idx++]) {
        if ((dataPick = node.getAttribute('data-pick')) && dataPick >= fromDate && dataPick <= toDate) {
          node.parentElement.className += ' ' + options.inRangeClass;
        } else if (dataPick && dataPick > toDate) {
          break;
        }
      }
    }
    if (toDate) {
      $root.find('[data-pick="' + toDate + '"]').addClass(options.endDayClass);
    }
    _self.toggleLabel(_self.pickerState, _self);
  },
  openThis: function() {
    var _self = this.parent;
    _self.$el.addClass(_self.options.contActive);
    _self.$dropdownIcon.addClass(_self.options.dropdownActive);
    _self.toggleLabel();
    this.renderThis();
  },
  /**
  * This function changes date format according to view port
  */
  handleFormat: function() {
    var _self = this.parent;
    var formattedDate, format, formatLength;
    var isAvailibiltyHWS = (typeof _self.$parent.getTakeOverVariation === 'function') ? (_self.$parent.getTakeOverVariation() === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS): false;
    var maxDateWidth = isAvailibiltyHWS ? Constants.SCREEN_WIDTH.DATE_SHORT_FORMAT_END_WIDTH_HWS : Constants.SCREEN_WIDTH.DATE_SHORT_FORMAT_END_WIDTH;
    if ((isAvailibiltyHWS || window.innerWidth > Constants.SCREEN_WIDTH.DATE_SHORT_FORMAT_START_WIDTH) && window.innerWidth < maxDateWidth) {
      format = this.localeDefaults.formatShort;      
    } else {
      format = this.localeDefaults.formatLong || this.localeDefaults.format;
    }
    formatLength = this.localeDefaults.formatLength;
    formattedDate = this.changeFormat(format);
    _self.$el.find('.js-date-from').val(formattedDate.from).attr('size',formatLength||format.length).prop('readonly', _self.readonly);
    _self.$el.find('.js-date-to').val(formattedDate.to).attr('size',formatLength||format.length).prop('readonly', _self.readonly);
  },
  /**
  * This function changes date format according to provided format and provide from date and to date object
  * @param {String} Date Format
  * @returns {Object} Object containing from date and to date
  */
  changeFormat: function(format) {
    var parent = this.parent;
    var result = {
      from: '',
      to: ''
    };
    this.setDateOnFormatChange(parent, 'from');
    result.from = this.parent.$dateFromToPicker.get('select', format);
    this.setDateOnFormatChange(parent, 'to');
    result.to = this.parent.$dateFromToPicker.get('select', format);
    return result;
  },

  /**
  * This function modifies plugin to support current selection of dates according to provided config and picker state
  * @param {Object} Reference to date handler class
  * @returns {Sting} from|to current picker state
  */
  setDateOnFormatChange: function(parent, pickerState) {
    var $parentComponent = this.parent.$parent,
    showPickerDays = parent.showPickerDays ? parent.showPickerDays : 30;// default 30 days
    if (pickerState === 'to') {
      max = parent.options.dates.toMax;
      if (parent.hasFixedDatePeriod && !parent.from.date) {
        min = parent.options.dates.fromMin;
      } 
      else if (parent.hasFixedDatePeriod && parent.from.date) {
        min = parent.from.date;
        max = getMaxToDate(parent.from.date, parent.options.dates.toMax, showPickerDays);
      }else if (parent.from.date && parent.from.date > parent.options.dates.todaysDate) {
        min = parent.from.date;
      } 
      else if($parentComponent.$el.find('.js-red-eye').length && parent.from.date && parent.from.date < parent.options.dates.todaysDate){
        min = parent.options.dates.todaysDate;
      }
      else if (parent.hasFixedDateRange && parent.options.dates.toMin) {
        min = parent.options.dates.toMin;
      }
      else if (parent.disableDates) {
        min = parent.options.dates.fromMin
      }
      else {
        if(parent.allowPastDates) {
          min = parent.options.dates.fromMin;
        } else {
          min = 1;
        }
      }
    } else {
      if(parent.hasFixedDatePeriod && parent.to.date && !parent.from.date){
        var minDate = new Date(parent.to.date);
        minDate.setDate(minDate.getDate() - 29);
        max = parent.to.date;
        min = minDate;
      } else {
        min = parent.options.dates.fromMin;
        max = parent.options.dates.fromMax;
      }
    }

    if (parent.dateFromData.showKGroupDate) {
      max = new Date(parent.dateFromData.kGroupDate);
    }

    parent.$dateFromToPicker.set({
      'min': min,
      'max': max,
      'select': (pickerState === 'to') ? parent.to.date : parent.from.date
    }, {
      muted: true
    });
  },
  setDateFromPicker: function(dateVal, fromTo) {
    var _self = this.parent;
    var min, max;
    _self.preventChangePropagation = true;
    _self.pickerState = fromTo || _self.pickerState;
    if (dateVal && dateVal !== -1) {
      if(_self.pickerState === 'from') {
        min = _self.options.dates.fromMin;
        max = _self.options.dates.fromMax;
      }else {
        min = _self.options.dates.toMin;
        max = _self.options.dates.toMax;
      }

      if (_self.dateFromData.showKGroupDate) {
        max = new Date(_self.dateFromData.kGroupDate);
      }

      if (typeof dateVal === 'string') {
         _self.$dateFromToPicker.set({
            'min': min,
            'max': max,
            'select': dateVal,
            format: this.localeDefaults.formatSubmit
          }, {
            muted: true,
            format: this.localeDefaults.formatSubmit
          });
      } else {
        _self.$dateFromToPicker.set({
            'min': min,
            'max': max,
            'select': dateVal,
          }, {
            muted: true,
          });
      }
    }
    if (!_self[_self.pickerState].$input.hasClass(_self.options.iserror)) {
      _self[_self.pickerState].date = _self.$dateFromToPicker.get('select') && _self.$dateFromToPicker.get('select').obj;
    }
    _self[_self.pickerState].$submit.val(_self.$dateFromToPicker._hidden.value);
    _self[_self.pickerState].$input.val(_self.$dateFromToPicker.$node.val());

    _self.preventChangePropagation = false;
  },
  strDateToLong: function(strDate) {
    return (strDate && (new Date(strDate)).getTime()) || 0;
  },
  //mkhad236: adding fuctionality to have events trigger as the user interacts with the calendar.
  //for reservationLookup tile
  addHiddenText: function(text, elem) {
    if (elem.find('.is-hidden-text').length < 1) {
      elem.prepend('<span class="is-hidden-text">' + text + '</span>');
    }
  },
  renderSingleDate: function() {
    var form = this.parent.$el.parents('form');
    var iconDot = form.find('.icon-dot');
    var nextMonth = form.find('.picker__nav--next');
    var preMonth =  form.find('.picker__nav--prev');
    //adding hidden text for previous month and next month icon for accessibility
    this.addHiddenText(this.localeDefaults.labelMonthNext, nextMonth);
    this.addHiddenText(this.localeDefaults.labelMonthPrev, preMonth);
    //adding icon dot before close btn inside pickadate calendar
    if (iconDot.length < 1) {
      form.find('.picker__button--close')
      .before('<span class="icon icon-dot t-font-xs t-medGray"></span>');
    }
  }
});
