var extend = require('extend');

/**
 * Instantiates a form fields mapper for automcomplete
 * @param {HTMLElement} DOM element for FORM
 */
function AutocompleteFormFieldMapper(form, mapper) {
  this.form = form;
  this.mapper = mapper;
}

var AutocompleteFormFieldMapperAPI = {
  /**
   * Returns autocomplete form data in key/value hash
   * @returns {Object} Autocomplete data
   */
  getFormData: function() {
    var self = this,
      data = {};

    Object.keys(self.mapper).forEach(function(formField) {
      var key = self.mapper[formField];

      if (self.form[formField]) {
        data[key] = self.form[formField].value;
      }
    });

    return data;
  },


  /**
   * Update form fields value with data
   * @param {Object} Form data
   */
  setFormData: function(data) {
    var self = this,
      key;

    Object.keys(self.mapper).forEach(function(formField) {
      key = self.mapper[formField];

      if (self.form[formField]) {
        self.form[formField].value = data[key] || '';
      }
    });
  },

  /**
   * Reset form fields with empty values
   */
  resetFormFields: function() {
    var self = this;
    Object.keys(self.mapper).forEach(function(formField) {
      if (self.form[formField]) {
        self.form[formField].value = '';
      }
    });
  }
};

extend(AutocompleteFormFieldMapper.prototype, AutocompleteFormFieldMapperAPI);

module.exports = AutocompleteFormFieldMapper;