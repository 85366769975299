var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"l-red-eye-confirmation-modal js-red-eye-confirmation-modal is-hidden\">\n    <div class=\"js-red-eye-overlay "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"associateSearch") : depths[1]),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":108}}})) != null ? stack1 : "")
    + "\"></div>\n    <div class=\"js-red-eye-popup l-red-eye-popup "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"associateSearch") : depths[1]),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":152}}})) != null ? stack1 : "")
    + "\">\n      <h3 class=\"t-font-xxl l-pos-rel l-padding-none l-margin-right-seven-quarters t-border-none l-margin-top-none l-margin-bottom-one-and-half t-font-weight-bold "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"associateSearch") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":163},"end":{"line":5,"column":208}}})) != null ? stack1 : "")
    + "\">\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"popUpTitleLabel") || (depth0 != null ? lookupProperty(depth0,"popUpTitleLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"popUpTitleLabel","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":27}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"associateSearch") : depths[1]),{"name":"unless","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "      </h3>\n      <div class=\"modal-body t-line-height-l l-left-align l-padding-none l-margin-bottom-double\">\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"popUpMessage") || (depth0 != null ? lookupProperty(depth0,"popUpMessage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"popUpMessage","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":24}}}) : helper)))
    + "\n      </div>\n      <div class=\"l-margin-top-double\">\n        <button type=\"button\" class=\"js-okay-btn l-margin-right-five-quarters m-button m-button-primary\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"popUpYesLabel") || (depth0 != null ? lookupProperty(depth0,"popUpYesLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"popUpYesLabel","hash":{},"data":data,"loc":{"start":{"line":15,"column":105},"end":{"line":15,"column":122}}}) : helper)))
    + "</button>\n        <button type=\"button\" class=\"js-cancel-btn m-button m-button-secondary\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"popUpCancelLabel") || (depth0 != null ? lookupProperty(depth0,"popUpCancelLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"popUpCancelLabel","hash":{},"data":data,"loc":{"start":{"line":16,"column":80},"end":{"line":16,"column":100}}}) : helper)))
    + "</button>\n      </div>\n    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " l-pos-absolute t-bg-standard-10";
},"4":function(container,depth0,helpers,partials,data) {
    return " l-pos-absolute t-bg-standard-20 l-add-segment-redeye-overlay ";
},"6":function(container,depth0,helpers,partials,data) {
    return "t-capitalize";
},"8":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"t-icon t-icon-cross l-cross-icon l-pos-absolute t-cursor-pointer js-cancel-btn\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"redEyeConfirmationPopup") : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});