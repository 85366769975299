var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"l-margin-bottom-three-quarters l-l-col-4 l-pos-rel js-clearinput  l-hsearch-loc clearfix\">\n        <h2 class=\"l-xs-col-2 is-hidden-label\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"headerText") : stack1), depth0))
    + "</h2>\n\n        <div id=\"saved-hotel-container\">\n            <select name=\"propertyCode\" id=\"saved-hotel\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"labels"))) && lookupProperty(stack1,"ada"))) && lookupProperty(stack1,"savedHotel")), depth0))
    + "\">\n                <option value=\"\" selected=\"selected\" disabled=\"disabled\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"savedHotels") : stack1), depth0))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"savedProperties") : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":13,"column":25}}})) != null ? stack1 : "")
    + "            </select>        \n            <span class=\"l-close-icon icon m-dropdown-trigger\"></span>\n        </div>\n    </div>\n\n    <div class=\"l-find-container js-find-container l-margin-bottom-three-quarters l-l-col-4 l-pos-rel \">\n        <span class=\"icon t-icon l-float-left t-icon-search \"></span> "
    + alias2(((helper = (helper = lookupProperty(helpers,"headerTitle") || (depth0 != null ? lookupProperty(depth0,"headerTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"headerTitle","hash":{},"data":data,"loc":{"start":{"line":20,"column":70},"end":{"line":20,"column":85}}}) : helper)))
    + "\n    </div>\n\n    <input type=\"hidden\" class=\"autosuggest-cat-headers\" data-category-params=\"{'city':'"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"categoryparams") : stack1)) != null ? lookupProperty(stack1,"city") : stack1), depth0))
    + "', 'state': '"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"categoryparams") : stack1)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + "', 'country':'"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"categoryparams") : stack1)) != null ? lookupProperty(stack1,"country") : stack1), depth0))
    + "', 'airport':'"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"categoryparams") : stack1)) != null ? lookupProperty(stack1,"airport") : stack1), depth0))
    + "', 'poi':'"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"categoryparams") : stack1)) != null ? lookupProperty(stack1,"poi") : stack1), depth0))
    + "', 'label':'"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"categoryparams") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "'}\" /> \n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"propertyCode") || (depth0 != null ? lookupProperty(depth0,"propertyCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"propertyCode","hash":{},"data":data,"loc":{"start":{"line":12,"column":31},"end":{"line":12,"column":47}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"propertyName") || (depth0 != null ? lookupProperty(depth0,"propertyName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"propertyName","hash":{},"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":65}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"location") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":24,"column":7}}})) != null ? stack1 : "");
},"useData":true});