var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-field-control l-rooms-guests js-rooms-guests js-hform-fields l-em-reset "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"associateSearch") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":86},"end":{"line":2,"column":182}}})) != null ? stack1 : "")
    + " l-margin-bottom-three-quarters "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),"eq","advanced-search-form",{"name":"if-cond","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":214},"end":{"line":2,"column":289}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPLESearchForm") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":289},"end":{"line":2,"column":336}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),"eq","FindAndReserveSearchActionForm",{"name":"if-cond","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":336},"end":{"line":2,"column":421}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),"eq","edit-search-form",{"name":"if-cond","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":421},"end":{"line":2,"column":527}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isErsModifyRLM") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":528},"end":{"line":2,"column":567}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"gxpAgentPropertyCode")),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":568},"end":{"line":2,"column":667}}})) != null ? stack1 : "")
    + ">\n  <span class=\"field-title\">"
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"roomsGuestsHead") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n\n    <a href=\"#\" aria-controls=\"rooms-guests-panel\" aria-label=\""
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"roomsGuestsHead") : stack1), depth0)) != null ? stack1 : "")
    + "\" class=\"l-display-block l-rooms-guests-trigger-box js-rooms-guests-trigger l-h-field analytics-click l-pos-rel\" data-options='{\"room\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"singleRoom") : stack1), depth0))
    + "\", \"rooms\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleRooms") : stack1), depth0))
    + "\", \"adult\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"singleAdult") : stack1), depth0))
    + "\", \"adults\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleAdults") : stack1), depth0))
    + "\",\"child\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"child") : stack1), depth0))
    + "\", \"children\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"children") : stack1), depth0))
    + "\", \"perroom\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"slashPerRoom") : stack1), depth0))
    + "\", \"perroomMobile\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"slashPerRm") : stack1), depth0))
    + "\",\"perroomfull\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"perRoom") : stack1), depth0))
    + "\",\"age\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"age") : stack1), depth0))
    + "\", \"lessthan\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"lessthan") : stack1), depth0))
    + "\", \"defaultAge\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"rooms"))) && lookupProperty(stack1,"guests"))) && lookupProperty(stack1,"children"))) && lookupProperty(stack1,"ages"))) && lookupProperty(stack1,"labels"))) && lookupProperty(stack1,"age")), depth0))
    + "\", \"forfirstroom\":\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"forFirstRoom") : stack1), depth0))
    + "\", \"maxAgeLimit\":\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"maxAgeLimit") : stack1), depth0))
    + "\"}' data-target=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"target") : stack1), depth0))
    + "\">\n    <p class=\"l-display-text\">\n      <span class=\"js-number-of-rooms\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideRooms") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":39},"end":{"line":7,"column":95}}})) != null ? stack1 : "")
    + ">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1), depth0))
    + "</span><span class=\"js-rooms-text\">"
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"gt",1,{"name":"if-cond","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":7,"column":159},"end":{"line":7,"column":306}}})) != null ? stack1 : "")
    + "</span><span class=\"js-adlt\">: </span><span class=\"js-number-of-adults js-adlt\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1), depth0))
    + "</span><span class=\"js-adults-text js-adlt\">"
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"gt",1,{"name":"if-cond","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":7,"column":455},"end":{"line":7,"column":595}}})) != null ? stack1 : "")
    + "</span><span class=\"js-chld "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"eq","0",{"name":"if-cond","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":623},"end":{"line":7,"column":696}}})) != null ? stack1 : "")
    + "\">, </span><span class=\"js-adults-perroom js-adlt "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"gt",0,{"name":"if-cond","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":746},"end":{"line":7,"column":817}}})) != null ? stack1 : "")
    + "\"><span>"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"slashPerRoom") : stack1), depth0))
    + "</span></span>\n      <span class=\"js-chld js-number-of-children "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"eq","0",{"name":"if-cond","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":122}}})) != null ? stack1 : "")
    + "\">"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1), depth0))
    + "</span><span class=\"js-chld js-children-text "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"eq","0",{"name":"if-cond","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":203},"end":{"line":8,"column":276}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"eq","1",{"name":"if-cond","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.program(29, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":8,"column":278},"end":{"line":8,"column":435}}})) != null ? stack1 : "")
    + "<span>"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"slashPerRoom") : stack1), depth0))
    + "</span></span>\n    </p>\n    <!-- <span class=\"l-close-icon icon m-dropdown-trigger\"></span> -->\n    <span class=\"l-float-right t-icon t-icon-arrow-down l-middle-valign t-font-xs m-icon-trigger\"></span>\n  </a>\n  <div data-takeover-label=\""
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"roomsGuestsHead") : stack1), depth0)) != null ? stack1 : "")
    + "\" class=\"l-rooms-guests-panel js-rooms-guests-panel "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"associateSearch") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.program(33, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":13,"column":108},"end":{"line":13,"column":171}}})) != null ? stack1 : "")
    + " is-hidden\" aria-expanded=\"false\">\n    <div class=\"rooms-guest-wrapper\">\n      <div class=\" clearfix\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideRooms") : depth0),{"name":"unless","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":6},"end":{"line":45,"column":17}}})) != null ? stack1 : "")
    + "        <div class=\"js-all-guests\">\n          <div class=\"l-adults js-adults js-guests\">\n            <div class=\"labelContainer field-title\">"
    + ((stack1 = alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleAdults") : stack1), depth0)) != null ? stack1 : "")
    + "<span class=\"l-guests-error-msg\"> "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"maxGuestPerRoom") : stack1), depth0))
    + "</span></div>\n            <div class=\"clearfix l-s2-stepper\">\n              <label for=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_guestCountBox\" class=\"is-hidden-label\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"guestCount") : stack1), depth0))
    + "</label>\n              <input type=\"text\" id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_guestCountBox\" name=\"guestCountBox\" class=\"l-s2-stepper-input js-adults-num \" value=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1), depth0))
    + " "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1)," gt ",1,{"name":"if-cond","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.program(56, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":51,"column":172},"end":{"line":51,"column":308}}})) != null ? stack1 : "")
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"perRoom") : stack1), depth0))
    + "\" data-min=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"min") : stack1), depth0))
    + "\" data-max=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"max") : stack1), depth0))
    + "\" readonly=\"true\">\n              <a href=\"#\" class=\"l-minus js-minus t-icon-minus analytics-click\" aria-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"remove") : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleAdults") : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"perRoom") : stack1), depth0))
    + "\"></a>\n\n              <a href=\"#\" class=\"l-plus js-plus t-icon-plus analytics-click\" aria-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"add") : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleAdults") : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"perRoom") : stack1), depth0))
    + "\"></a>\n              <label for=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_guestCount\" class=\"is-hidden-label\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"guestCount") : stack1), depth0))
    + "</label>\n              <select name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSavedHotelForm") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0, blockParams, depths),"inverse":container.program(60, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":56,"column":28},"end":{"line":56,"column":133}}})) != null ? stack1 : "")
    + "\" id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_guestCount\" class=\"is-hidden guestCount\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"options") : stack1),{"name":"each","hash":{},"fn":container.program(62, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":59,"column":25}}})) != null ? stack1 : "")
    + "              </select>\n            </div>\n          </div>\n          <div class=\"l-children js-children js-guests\">\n            <div class=\"labelContainer field-title\">"
    + ((stack1 = alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"children") : stack1), depth0)) != null ? stack1 : "")
    + "<span class=\"l-guests-error-msg\"> "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"maxGuestPerRoom") : stack1), depth0))
    + "</span></div>\n            <div class=\"clearfix l-s2-stepper\">\n              <label for=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childrenCountBox\" class=\"is-hidden-label\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"childCount") : stack1), depth0))
    + "</label>\n              <input type=\"text\" id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childrenCountBox\" name=\"childrenCountBox\" class=\"l-s2-stepper-input js-children-num \" value=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1), depth0))
    + " "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"eq",1,{"name":"if-cond","hash":{},"fn":container.program(64, data, 0, blockParams, depths),"inverse":container.program(66, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":67,"column":189},"end":{"line":67,"column":338}}})) != null ? stack1 : "")
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"perRoom") : stack1), depth0))
    + "\" data-min=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"min") : stack1), depth0))
    + "\" data-max=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"max") : stack1), depth0))
    + "\" readonly=\"true\">\n              <a href=\"#\" class=\"l-minus js-minus t-icon-minus\" aria-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"remove") : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"children") : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"perRoom") : stack1), depth0))
    + "\"></a>\n\n              <a href=\"#\" class=\"l-plus js-plus t-icon-plus\" aria-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"add") : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"children") : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"perRoom") : stack1), depth0))
    + "\"></a>\n              <label for=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childrenCount\" class=\"is-hidden-label\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"childCount") : stack1), depth0))
    + "</label>\n              <select name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSavedHotelForm") : depth0),{"name":"if","hash":{},"fn":container.program(68, data, 0, blockParams, depths),"inverse":container.program(70, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":72,"column":28},"end":{"line":72,"column":145}}})) != null ? stack1 : "")
    + "\" id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childrenCount\" class=\"childrenCount is-hidden\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"options") : stack1),{"name":"each","hash":{},"fn":container.program(72, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":16},"end":{"line":75,"column":25}}})) != null ? stack1 : "")
    + "              </select>\n            </div>\n          </div>\n          <p class=\"l-age-based js-age-based is-hidden\">"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"ageBased") : stack1), depth0))
    + "</p>\n          <input type=\"hidden\" name=\"childrenAges\" class=\"js-children-ages\" value=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"childrenAges") : stack1), depth0))
    + "\" />\n          <div id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_l-ages-append\" class=\"js-ages-append l-padding-bottom\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"childrenAgesArray") : stack1),{"name":"each","hash":{},"fn":container.program(74, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":12},"end":{"line":96,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n          <div id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_l-age-template\" class=\"js-age-template is-hidden \">\n            <div class=\"l-child-info field-title\"><span class=\"l-childnum-title\">"
    + ((stack1 = alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"child") : stack1), depth0)) != null ? stack1 : "")
    + " 1 : "
    + ((stack1 = alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"age") : stack1), depth0)) != null ? stack1 : "")
    + " </span><span class=\"l-first-room-text\"> ("
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"required") : stack1), depth0))
    + ")</span></div>\n            <div class=\"clearfix l-s2-stepper\">\n              <label for=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childAgesBox\" class=\"is-hidden-label\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"childCount") : stack1), depth0))
    + "</label>\n              <input type=\"text\" id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childAgesBox\" class=\"l-s2-stepper-input js-ages-num \" value=\""
    + ((stack1 = alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"age") : stack1), depth0)) != null ? stack1 : "")
    + "\" data-default=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"age") : stack1), depth0))
    + "\" data-lessthan=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"lessthan") : stack1), depth0))
    + "\" data-min=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"min") : stack1), depth0))
    + "\" data-max=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"max") : stack1), depth0))
    + "\" readonly=\"true\">\n              <a href=\"#\" class=\"l-minus js-minus t-icon-minus is-inactive analytics-click\" aria-label=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"ageDecrement") : stack1), depth0))
    + "\"></a>\n              <a href=\"#\" class=\"l-plus js-plus t-icon-plus analytics-click\" aria-label=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"ageIncrement") : stack1), depth0))
    + "\"></a>\n              <label for=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childrenAgesNum\" class=\"is-hidden-label\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"childCount") : stack1), depth0))
    + "</label>\n              <select id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childrenAgesNum\" class=\"childrenAgesNum js-children-age is-hidden\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"options") : stack1),{"name":"each","hash":{},"fn":container.program(79, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":16},"end":{"line":109,"column":25}}})) != null ? stack1 : "")
    + "              </select>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"l-margin-top-none l-rooms-guests-footer clearfix l-clear t-bg-standard-20 t-border-standard-120\">\n      <div class=\"rooms-guests-done js-done l-float-left is-hidden\"><a class=\"m-button m-button-secondary\" href=\"#\">"
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"done") : stack1), depth0)) != null ? stack1 : "")
    + "</a></div>\n      <div class=\"rooms-guests-clear js-reset l-margin-top\"><a href=\"#\" class=\"t-control-link t-line-height-l\">"
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"reset") : stack1), depth0)) != null ? stack1 : "")
    + "</a></div>\n    </div>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "l-s-col-4 "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isErsModifyRLM") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":123},"end":{"line":2,"column":171}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "l-xl-col-4 ";
},"5":function(container,depth0,helpers,partials,data) {
    return "l-xl-last-col";
},"7":function(container,depth0,helpers,partials,data) {
    return " l-xl-last-col ";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rateListMenu") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":467},"end":{"line":2,"column":515}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return " l-l-col-3";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-agent-property-code=\""
    + container.escapeExpression(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"gxpAgentPropertyCode")), depth0))
    + "\"";
},"15":function(container,depth0,helpers,partials,data) {
    return "data-is-ers-modify-path = \"true\"";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleRooms") : stack1), depth0)) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"singleRoom") : stack1), depth0)) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleAdults") : stack1), depth0)) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"singleAdult") : stack1), depth0)) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    return "is-hidden";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"child") : stack1), depth0)) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"children") : stack1), depth0)) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    return " l-pos-absolute";
},"33":function(container,depth0,helpers,partials,data) {
    return " l-pos-rel";
},"35":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"l-rooms js-rooms\">\n          <div class=\"labelContainer field-title\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleRooms") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n          <div class=\"clearfix l-s2-stepper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"selectedCount") : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "            <label for=\""
    + alias3(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_roomCountBox\" class=\"is-hidden-label\">"
    + alias3(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"roomCount") : stack1), depth0))
    + "</label>\n            <input type=\"text\" id=\""
    + alias3(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_roomCountBox\" name=\"roomCountBox\" class=\"l-s2-stepper-input js-rooms-num t-color-standard-10\" value=\""
    + alias3(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1), depth0))
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1)," gt ",1,{"name":"if-cond","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.program(40, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":24,"column":188},"end":{"line":24,"column":331}}})) != null ? stack1 : "")
    + "\" readonly=\"true\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"selectedCount") : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":349},"end":{"line":24,"column":412}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"selectedCount") : stack1),{"name":"unless","hash":{},"fn":container.program(44, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":42,"column":23}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <input type=\"hidden\" name=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"selectedCount") : stack1), depth0))
    + "\" />\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleRooms") : stack1), depth0));
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"singleRoom") : stack1), depth0));
},"42":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"44":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"#\" class=\"l-minus js-minus t-icon-minus analytics-click\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"remove") : stack1), depth0))
    + " "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleRooms") : stack1), depth0)) != null ? stack1 : "")
    + "\"></a>\n\n            <a href=\"#\" class=\"l-plus js-plus t-icon-plus analytics-click\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"add") : stack1), depth0))
    + " "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleRooms") : stack1), depth0)) != null ? stack1 : "")
    + "\"></a>\n            <label for=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_roomCount\" class=\"is-hidden-label\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"roomCount") : stack1), depth0))
    + "</label>\n            <select name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isSavedHotelForm") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.program(47, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":30,"column":26},"end":{"line":30,"column":130}}})) != null ? stack1 : "")
    + "\" id=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_roomCount\" class=\"is-hidden roomCount js-room-count\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"options") : stack1),{"name":"each","hash":{},"fn":container.program(49, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":14},"end":{"line":33,"column":23}}})) != null ? stack1 : "")
    + "            </select>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"gxpPropertyRoomOptions") : stack1),{"name":"if","hash":{},"fn":container.program(52, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":41,"column":19}}})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"form") : stack1)) != null ? lookupProperty(stack1,"fields") : stack1)) != null ? lookupProperty(stack1,"roomInputName") : stack1), depth0));
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0));
},"49":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <option value=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"eq",(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if-cond","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":40},"end":{"line":32,"column":124}}})) != null ? stack1 : "")
    + ">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0)) != null ? stack1 : "")
    + "</option>\n";
},"50":function(container,depth0,helpers,partials,data) {
    return " selected=\"selected\" ";
},"52":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <select name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSavedHotelForm") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.program(47, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":36,"column":132}}})) != null ? stack1 : "")
    + "\" id=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_roomCount_gxp\" class=\"is-hidden roomCount js-room-count-gxp\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"roomCount") : stack1)) != null ? lookupProperty(stack1,"gxpPropertyRoomOptions") : stack1),{"name":"each","hash":{},"fn":container.program(49, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":14},"end":{"line":39,"column":23}}})) != null ? stack1 : "")
    + "            </select>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"multipleAdults") : stack1), depth0));
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"singleAdult") : stack1), depth0));
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"form") : stack1)) != null ? lookupProperty(stack1,"fields") : stack1)) != null ? lookupProperty(stack1,"numAdultInputName") : stack1), depth0));
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0));
},"62":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"eq",(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if-cond","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":42},"end":{"line":58,"column":123}}})) != null ? stack1 : "")
    + ">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0)) != null ? stack1 : "")
    + "</option>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"child") : stack1), depth0));
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"children") : stack1), depth0));
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"form") : stack1)) != null ? lookupProperty(stack1,"fields") : stack1)) != null ? lookupProperty(stack1,"numChildrenInputName") : stack1), depth0));
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rooms") : depth0)) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0));
},"72":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),"eq",(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if-cond","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":42},"end":{"line":74,"column":132}}})) != null ? stack1 : "")
    + ">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0)) != null ? stack1 : "")
    + "</option>\n";
},"74":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class='agecontainer l-s2-stepper'>\n              <div class=\"l-child-info field-title\"><span class=\"l-childnum-title\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"child") : stack1), depth0)) != null ? stack1 : "")
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + " : "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"age") : stack1), depth0)) != null ? stack1 : "")
    + " </span><span class=\"l-first-room-text\"> ("
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"required") : stack1), depth0))
    + ")</span></div>\n              <label for=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childAgeBox_"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\" class=\"is-hidden-label\">"
    + alias2(alias1(((stack1 = ((stack1 = (depths[2] != null ? lookupProperty(depths[2],"labels") : depths[2])) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"ageTemplate") : stack1), depth0))
    + "</label>\n              <input type=\"text\" id=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childAgeBox_"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\" class=\"l-s2-stepper-input js-ages-num \" data-default=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"age") : stack1), depth0))
    + "\" data-lessthan=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"lessthan") : stack1), depth0))
    + "\" data-min=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"min") : stack1), depth0))
    + "\" data-max=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"max") : stack1), depth0))
    + "\" value=\""
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"age") : depth0), depth0)) != null ? stack1 : "")
    + "\" readonly=\"true\">\n              <a href=\"#\" class=\"l-minus js-minus t-icon-minus "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"age") : depth0),"eq",((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"min") : stack1),{"name":"if-cond","hash":{},"fn":container.program(75, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":63},"end":{"line":87,"column":141}}})) != null ? stack1 : "")
    + " analytics-click\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"ageDecrement") : stack1), depth0))
    + "\"></a>\n              <a href=\"#\" class=\"l-plus js-plus t-icon-plus "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"age") : depth0),"eq",((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"max") : stack1),{"name":"if-cond","hash":{},"fn":container.program(75, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":60},"end":{"line":88,"column":138}}})) != null ? stack1 : "")
    + " analytics-click\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"ageIncrement") : stack1), depth0))
    + "\"></a>\n              <label for=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childrenAgesNum_"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\" class=\"is-hidden-label\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"childCount") : stack1), depth0))
    + "</label>\n              <select id=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_childrenAgesNum_"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\" class=\"childrenAgesNum js-children-age is-hidden\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depths[2] != null ? lookupProperty(depths[2],"labels") : depths[2])) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"childAgeNumber") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"rooms") : depths[1])) != null ? lookupProperty(stack1,"guests") : stack1)) != null ? lookupProperty(stack1,"children") : stack1)) != null ? lookupProperty(stack1,"ages") : stack1)) != null ? lookupProperty(stack1,"options") : stack1),{"name":"each","hash":{},"fn":container.program(77, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":16},"end":{"line":93,"column":25}}})) != null ? stack1 : "")
    + "              </select>\n            </div>\n";
},"75":function(container,depth0,helpers,partials,data) {
    return "is-inactive";
},"77":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../../../../../../core/helpers/if-cond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"age") : depths[1]),"eq",(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if-cond","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":42},"end":{"line":92,"column":105}}})) != null ? stack1 : "")
    + ">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0)) != null ? stack1 : "")
    + "</option>\n";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0)) != null ? stack1 : "")
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rooms") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":122,"column":7}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});