var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"l-brand-category l-margin-bottom-half\">\n                    <p class=\"t-color-standard-100 t-font-weight-bold l-margin-bottom-half l-display-inline-block l-margin-top-none\"> "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"labels")),(depth0 != null ? lookupProperty(depth0,"categoryKey") : depth0),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":12,"column":134},"end":{"line":12,"column":169}}}))
    + " | </p>\n                    <a href=# class=\"select-all "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allSelected") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":48},"end":{"line":13,"column":83}}})) != null ? stack1 : "")
    + "\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"labels")),(depth0 != null ? lookupProperty(depth0,"selectAllCategoryKey") : depth0),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":13,"column":85},"end":{"line":13,"column":129}}}))
    + "</a>\n                    <a href=# class=\"clear-all-brand-specific "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"allSelected") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":62},"end":{"line":14,"column":105}}})) != null ? stack1 : "")
    + "\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"labels")),(depth0 != null ? lookupProperty(depth0,"clearAllCategoryKey") : depth0),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":14,"column":107},"end":{"line":14,"column":150}}}))
    + "</a>\n                    <ul class=\"l-display-flex l-margin-top-three-quarters\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"brandFilterOptions") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":25,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "is-hidden";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"l-filter-item l-filter-item-brands-icons l-display-inline-block "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":103},"end":{"line":17,"column":133}}})) != null ? stack1 : "")
    + "\"> \n                                <label class=\"brand-container l-width-max l-pos-relative\" title=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\" aria-label=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\">\n                                    <input class=\"brand-checkbox\" type=\"checkbox\" name=\""
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"categoryKey") : depths[1]), depth0))
    + "_"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":117},"end":{"line":19,"column":157}}})) != null ? stack1 : "")
    + " value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\">\n                                    <span data-for=\""
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"categoryKey") : depths[1]), depth0))
    + "_"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\" data-icon-code=\""
    + alias3(__default(require("../../../../../../../core/helpers/toLowerCase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"toLowerCase","hash":{},"data":data,"loc":{"start":{"line":20,"column":97},"end":{"line":20,"column":117}}}))
    + "\" class=\"brand-box js-brand-icon t-brand-logo-icon-"
    + alias3(__default(require("../../../../../../../core/helpers/toLowerCase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"toLowerCase","hash":{},"data":data,"loc":{"start":{"line":20,"column":168},"end":{"line":20,"column":188}}}))
    + " t-border-standard-110 t-color-option-enabled l-display-inline-block l-pos-rel\" >\n                                    <span class=\"l-display-none\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</span>\n                                    </span>\n                                </label>\n                            </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "last-brand";
},"7":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-field-control l-em-reset l-s-col-4 l-xl-col-4 js-hform-fields l-brand-section\">\n    <span class=\"field-title\">"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"labels"))) && lookupProperty(stack1,"brandsLabel")), depth0))
    + "</span>\n     <a href=\"#\" class=\"js-toggle js-brands-header t-blk l-h-toggle l-h-field analytics-click\" ><span class=\"l-h-toggle-brand-text\"><span class=\"default-selected is-hidden\">"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"labels"))) && lookupProperty(stack1,"allBrandsLabel")), depth0))
    + "</span><span class=\"brand-count selected-count is-hidden\">X</span><span class=\"selected-count is-hidden\"> "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"labels"))) && lookupProperty(stack1,"brandsSelected")), depth0))
    + "</span></span>\n     <span class=\"l-float-right t-icon t-icon-arrow-down l-middle-valign t-font-xs m-icon-trigger\"></span>\n     </a>\n\n    <div data-takeover-label=\"\" class=\"l-h-toggle-cont brands-section js-toggle-container is-hidden l-padding-top\">\n        <fieldset>\n            <legend></legend>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"brandCategoryList") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":28,"column":21}}})) != null ? stack1 : "")
    + "        </fieldset>\n        <div class=\"t-border-top t-border-color-standard-110 l-margin-top l-margin-bottom\"></div>\n        <div class=\"button-banner l-float-right\">\n            <a href=\"#\" class=\"t-control-link clear-selected-brands t-font-weight-bold\">"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"labels"))) && lookupProperty(stack1,"clearAllLabel")), depth0))
    + "</a>\n            <a href=\"#\" class=\"m-button m-button-secondary close-brand-container l-margin-right\">"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"labels"))) && lookupProperty(stack1,"applyLabel")), depth0))
    + "</a>\n        </div>\n        <div class=\"l-clear\"></div>\n    </div>\n</div>";
},"useData":true,"useDepths":true});