var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div data-roomkeyflag=\""
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"showRoomKeyPopup")), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"searchFormContainer") : depth0)) != null ? lookupProperty(stack1,"target") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":55},"end":{"line":2,"column":140}}})) != null ? stack1 : "")
    + " class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"searchFormContainer") : depth0)) != null ? lookupProperty(stack1,"cssClass") : stack1), depth0))
    + " is-opened "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"colorSet") : depth0), depth0))
    + "\" "
    + ((stack1 = container.invokePartial(require("./partials/errorMessages.hbs"),(depth0 != null ? lookupProperty(depth0,"errorMessages") : depth0),{"name":"partials/errorMessages","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " data-done-button-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"done") : stack1), depth0))
    + "\" data-id-prefix=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"idPrefix") : stack1), depth0))
    + "\" data-suppress-google-api=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"geoLocationNotCN") : depth0), depth0))
    + "\" data-enable-google-places-api=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"enableGooglePlaceAutoSuggestion") : depth0), depth0))
    + "\" data-autocomplete-supported-types="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"autocompleteSupportedTypes") : stack1), depth0))
    + " data-enable-user-location=\""
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"enableUserLocation")), depth0))
    + "\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"parentCssClass") : stack1), depth0))
    + "\">\n            "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"isEditSearch") : stack1),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":140}}})) != null ? stack1 : "")
    + "\n                <div class=\"clearfix l-clear\">\n                    <form id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" novalidate=\"novalidate\" method=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "\" action=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"action") : stack1), depth0))
    + "\" class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"cssClass") : stack1), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"recentSearchClass") : depth0), depth0))
    + "\" data-default-endpoint=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"defaultEndpoint") : stack1), depth0))
    + "\" data-suggestion-endpoint-mapping="
    + alias2(__default(require("../../../../../../core/helpers/parseJson.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"suggestionEndpointMapping") : stack1),{"name":"parseJson","hash":{},"data":data,"loc":{"start":{"line":6,"column":264},"end":{"line":6,"column":325}}}))
    + " data-is-group-search-applicable=\""
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isGroupSearchApplicable")), depth0))
    + "\" data-group-search-endpoint="
    + alias2(alias1(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"groupSubmitSearchURL")), depth0))
    + ">\n"
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"global") : stack1),{"name":"hiddenInputs","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"date") : stack1),{"name":"hiddenInputs","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"searchForm") : stack1),{"name":"hiddenInputs","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"location") : stack1),{"name":"hiddenInputs","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"dealsSearch") : stack1),{"name":"hiddenInputs","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        <div class=\"search-form-header\">\n                            <span class=\"search-form-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"headerText") : stack1), depth0))
    + "</span>\n                        </div>\n                        <div class=\"m-field-wrap l-hsearch-labels clearfix l-clear\">\n"
    + ((stack1 = container.invokePartial(require("./partials/dates.hbs"),depth0,{"name":"partials/dates","hash":{"rateListMenu":"true"},"data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/roomsGuests.hbs"),depth0,{"name":"partials/roomsGuests","hash":{"rateListMenu":"true"},"data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"specialRates") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":28},"end":{"line":21,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"useRewards") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":24,"column":35}}})) != null ? stack1 : "")
    + "                            <div class=\"m-field-control m-field-control-find l-xs-col-4 l-mml-col-9-split l-xl-col-4 l-margin-bottom-three-quarters l-hsearch-find\">\n"
    + ((stack1 = container.invokePartial(require("./partials/submitButton.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"searchButton") : stack1),{"name":"partials/submitButton","data":data,"indent":"                               ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </form>\n                </div>\n            "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"isEditSearch") : stack1),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":31,"column":58}}})) != null ? stack1 : "")
    + "\n        </div>\n     </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-target=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"searchFormContainer") : depth0)) != null ? lookupProperty(stack1,"target") : stack1), depth0))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"m-homepage-hsearch l-hsearch-cntnr l-hsearch-bottom t-bg-hsearch is-opened\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/specialRates.hbs"),depth0,{"name":"partials/specialRates","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/useRewards.hbs"),depth0,{"name":"partials/useRewards","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hideSearchForm") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":34,"column":12}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});