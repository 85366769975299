var SearchComponent = require('libs/search-components');
var PageTakeover = require('libs/pageTakeover');
var Constants = require('libs/constants');
var Config = require('libs/search_config');
var $ = require('jquery');
var ScrollBarHandler = require('libs/scrollbar');

var RoomGuestsHandler = SearchComponent.extend({
  events: {
    'click .js-rooms-guests-trigger': 'onRoomsGuestsClicked',
    'click .l-plus, .l-minus': 'onPlusMinusClicked',
    'blur .js-rooms-num': 'onRoomsBlur',
    'blur .js-adults-num': 'onAdultsBlur',
    'blur .js-children-num': 'onChildBlur',
    'blur .js-ages-num': 'onChildAgeBlur',
    'click .rooms-guests-clear': 'onClearClicked',
    'click .js-done': 'onDoneClicked',
    'click .js-back-icon': 'onDoneClicked',
    'click .done_button': 'hideTakeoverScreen'
  },
  options: {
    dropdownPanel: '.js-rooms-guests-panel',
    inactive:'is-inactive',
    scrollBarTarget : '.js-rooms-guests-panel > .rooms-guest-wrapper',
    dropdownTrigger: '.m-icon-trigger',
    dropdownActive: 't-icon-arrow-up',
    dropdownInActive: 't-icon-arrow-down',
  },
  textValues: {},
  init: function() {
    var _self = this;
    this.subscribeDOMEvents();
    this.optionMaxValue = this.$el.find('.roomCount option:last-child').val();
    this.setTextValues();
    this.$dropdownIcon = this.$el.find(this.options.dropdownTrigger);
    this.gxpAgentPropertyCode = this.$el.data('agent-property-code');

    $(document).on('click', function(evt) {
      if(!$(evt.target).is('.js-rooms-guests-trigger') && !$(evt.target).parents('#guestsrooms').length) {
        _self.onClose(evt, _self);
      }
    });
    this.$el.parents('.mfp-content').on('click', function(evt) {
      if(!$(evt.target).is('.js-rooms-guests-trigger') && !$(evt.target).parents('#guestsrooms').length) {
        _self.onClose(evt, _self);
      }
    });

    this.$parent.$el.find('input[type!=\'hidden\'], a, div[tabindex = \'0\']').on('focusin', function(evt) {
      _self.onClose(evt, _self);
    });

    this.prepareDom();
    this.onRoomsBlur();
    this.createTakeOverScreen(this.$el.find('.l-rooms-guests-panel'), this.$el.find('.l-rooms-guests-panel').data('takeover-label'), this.$parent.$el.data('done-button-label'));

    //Android specific fix
    var isAndroid = this.$parent.isAndroidDevice();
    if(isAndroid) {
      var _self = this;
      this.$el.on('focus', '.l-stepper-input', function() {
        _self.$el.find('.rooms-guest-wrapper').css(Config.ANDROID_INPUT_FIX);
      }).on('blur', '.l-stepper-input', function() {
        _self.$el.find('.rooms-guest-wrapper').css(Config.REMOVE_ANDROID_INPUT_FIX);
      });
    }

    this.initScroller();
  },
  initScroller: function() {
    var _self = this;
    var activeElement = document.activeElement;
    if (!this.scrollbar && (parseInt(this.$el.find('.js-children-num').val()) > 0) && !this.$parent.responsiveUtils.isMobileOrTablet()) {
      this.scrollbar = ScrollBarHandler.create({
        $el: this.$el,
        $scrollBarTarget: this.$el.find(_self.options.scrollBarTarget)
      });
      this.scrollbar.init();
      activeElement.focus();
    }
  },
  removeScroller: function() {
    if (this.scrollbar) {
      var activeElement = document.activeElement;
      this.scrollbar.destroy();
      delete(this.scrollbar);
      activeElement.focus();
    }
  },
  createTakeOverScreen: function($el, label, buttonLabel) {
    if (this.suppressTakeOver) {
      this.takeoverScreen = {
        isActive:false
      }
      return;
    }
    var _self = this;
    var TakeOverConfig = Config.TAKEOVER_VARIATION[this.$parent.getTakeOverVariation()];
    var takeoverVariation = this.$parent.getTakeOverVariation();
    this.takeoverScreen = PageTakeover.create({
      $el: $el,
      headerLabel: label,
      skipDimensions: true,
      isNotRetainDisplayAttribute: true,
      transition_speed: Constants.SEARCH_FORM.TAKE_OVER_SPEED,
      keepOverflowHidden: TakeOverConfig.KEEP_OVERFLOW_HIDDEN,
      customHeader: function() {
        this.$el.prepend('<div class="page-take-over-header"><span class="back-btn"></span>'+this.headerLabel+'<a class="done_button" href="#">'+buttonLabel+'</a></div>');
        if (!_self.addCustomHeaderStyle) {
          this.$el.find('.page-take-over-header').css(Config.TAKEOVER_HEADER_STYLING);
        }
      },
      beforeShow: function() {
        _self.$el.toggleClass('is-active');
        _self.$el.find('.js-rooms-guests-panel').slideToggle('fast').toggleClass('is-hidden');
        _self.$el.find('.js-rooms-guests-panel').attr('aria-expanded', !_self.$el.find('.js-rooms-guests-panel').attr('aria-expanded'));
        _self.$parent.$el.find('.t-more-search-options').css('overflow', 'visible');
        if (takeoverVariation === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS) {
          _self.$el.closest('.mfp-wrap').css('overflow', 'hidden');
        }
      },
      afterHide: function() {
         if (_self.$el.hasClass('is-active')) {
          _self.$el.removeClass('is-active');
          _self.$el.find('.js-rooms-guests-panel').slideUp();
          _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
          if (takeoverVariation === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS) {
            _self.$el.closest('.mfp-wrap').css({'overflow-x': 'hidden','overflow-y': 'scroll'});
          }
        }
      }
    });
  },
  hideTakeoverScreen: function(e) {
    if (this.suppressTakeOver) {return;}
    if (e) {
      e.preventDefault();
    }
    this.takeoverScreen.hide();
  },
  addTakeover: function() {
    if (this.suppressTakeOver) {return;}
    if (this.$parent.$el.find('.l-rooms-guests').hasClass('is-active')) {
      this.onRoomsGuestsClicked();
      this.takeoverScreen.reload();
      this.removeScroller();
      this.$el.css('z-index', 'auto');
    }
  },
  removeTakeover: function() {
    if (this.suppressTakeOver) {return;}
    if (this.$parent.$el.find('.l-rooms-guests').hasClass('is-active')) {
      this.takeoverScreen.remove();
      this.$el.find('.l-rooms-guests-panel').removeAttr('style').css('top', 'auto');
      this.onRoomsGuestsClicked();
      this.initScroller();
    } else {
      this.$el.find('.l-rooms-guests-panel').removeAttr('style').hide();
    }
    this.$el.css('z-index', '1');
  },
  setTextValues: function() {
    var roomGuestTriggerData = this.$el.find('.js-rooms-guests-trigger').data('options');
    if(roomGuestTriggerData){
      this.textValues.$roomText = roomGuestTriggerData.room;
      this.textValues.$roomsText = roomGuestTriggerData.rooms;
      this.textValues.$adultText = roomGuestTriggerData.adult;
      this.textValues.$adultsText = roomGuestTriggerData.adults;
      this.textValues.$childText = roomGuestTriggerData.child;
      this.textValues.$childrenText = roomGuestTriggerData.children;
      this.textValues.$perroomText = $(document).width() <= 480 ? roomGuestTriggerData.perroomMobile : roomGuestTriggerData.perroom;
      this.textValues.$perroomText = ($(document).width() >= 481 && $(document).width() < 1024) ? "" : this.textValues.$perroomText;
      this.textValues.$perroomFullText = roomGuestTriggerData.perroomfull;
      this.textValues.$upText = roomGuestTriggerData.up;
      this.textValues.$downText = roomGuestTriggerData.down;
      this.textValues.$ageText = roomGuestTriggerData.age;
      this.textValues.$lessthan = roomGuestTriggerData.lessthan;
      this.textValues.$defaultAge = roomGuestTriggerData.defaultAge;
      this.textValues.$forfirstroomText = roomGuestTriggerData.forfirstroom;
      this.textValues.$maxAgeLimit = roomGuestTriggerData.maxAgeLimit;
    }
    else{
      return false;
    }
  },
  handleDisplayFormat: function() {
    var roomGuestTriggerData = this.$el.find('.js-rooms-guests-trigger').data('options');
    if(roomGuestTriggerData){
      this.textValues.$perroomText = $(document).width() <= 480 ? roomGuestTriggerData.perroomMobile : roomGuestTriggerData.perroom;
      this.textValues.$perroomText = ($(document).width() >= 481 && $(document).width() < 1024) ? "" : this.textValues.$perroomText;
    }
    this.displayText();
  },
  prepareDom: function() {
    var _self = this;
    var childrenCount = parseInt(this.$el.find('.js-children-num').val());
    var ageBasedTextholder = this.$el.find('.l-age-based');
    (childrenCount>0?ageBasedTextholder.removeClass('is-hidden'):ageBasedTextholder.addClass('is-hidden'));

    //Bug fix should be removed when micorelib.js is removed
    this.$el.find('.js-ages-append').html('');
    //ends
    this.updateChildAgePickers(this.$el.find('.js-children-ages').val());

    this.toggleRoomButtons();
    this.toggleAdultButtons();
    this.toggleChildButtons();

    this.displayText();
  },
  updateChildAgePickers:function(childrenAgesString){
    var _self = this;
    var $childAgeHolder = _self.$el.find('.js-ages-append');
    var ageAppend = _self.$el.find('.js-age-template').html();
    var defaultValue = $childAgeHolder.find('.js-ages-num').attr('data-default');

    var childrenAgesArray = ((childrenAgesString !== '') ? childrenAgesString.split(','): []);
    for(var count = 0; count < childrenAgesArray.length; count++){
        $childAgeHolder.show();
        $childAgeHolder.append('<div class=\'agecontainer\'>' + ageAppend + '</div>');
        this.setChildrenAgesInputProperties($childAgeHolder, this.textValues.$childText + (count+1));

        if(childrenAgesArray[count] == -1) {
          childrenAgesArray[count] = this.textValues.$defaultAge;
        }else if(childrenAgesArray[count] == 0) {
          childrenAgesArray[count] = this.textValues.$lessthan;
        }

        $childAgeHolder.find('.agecontainer input').last().val(childrenAgesArray[count]);
        $childAgeHolder.find('.agecontainer select').last().val((isNaN(parseInt(childrenAgesArray[count]))?(childrenAgesArray[count]===defaultValue?-1:0):childrenAgesArray[count]));
        this.toggleChildAgeButtons($childAgeHolder.find('.agecontainer input').last());
    }
  },
  displayText: function() {
    var adultCount = parseInt(this.$el.find('.js-adults-num').val());
    var childrenCount = parseInt(this.$el.find('.js-children-num').val());
    var roomCount = parseInt(this.$el.find('.js-rooms-num').val());

    this.setRoomCount(roomCount);
    this.setAdultCount(adultCount);
    this.setChildrenCount(childrenCount);
  },
  displayTextOnReset: function() {
    var roomCount = parseInt(this.$el.find('.js-rooms-num').val());
    this.setRoomCount(roomCount);
  },
  setRoomCount: function(roomCount) {
    var childrenCount = parseInt(this.$el.find('.js-children-num').val()),
        $numberOfRooms = this.$el.find('.js-number-of-rooms'),
        isERSModifyPath = $numberOfRooms.data('is-ers-modify-path');
    $numberOfRooms.html(roomCount);
    if(isERSModifyPath && !roomCount){
      return;
    }
    if (roomCount === 1) {
      this.$el.find('.js-rooms-text').html(' ' + this.textValues.$roomText);
    } else {
      this.$el.find('.js-rooms-text').html(' ' + this.textValues.$roomsText);
    }
    if (this.isGXPAgentSelfProperty()) {
      var $roomCountSelect = this.$el.find('.js-room-count-gxp');
      if(roomCount < 0) {
        $numberOfRooms.html($roomCountSelect.find('option:first-child').val());
      } else if(roomCount > 20) {
        $numberOfRooms.html($roomCountSelect.find('option:last-child').val());
      }
      return;
    }
    if (roomCount && roomCount <= 4) {
      this.$el.find('.js-adlt').removeClass('is-hidden');
      if(childrenCount>0){
        this.$el.find('.js-chld').removeClass('is-hidden');
      }
      this.$el.find('.js-all-guests').removeClass('is-hidden');
      if (roomCount === 4) {
        $numberOfRooms.html('4-9');
      }
    } else {
      if (roomCount === 10) {
        $numberOfRooms.html('10-25');
      }else {
        $numberOfRooms.html('26+');
      }
      this.$el.find('.js-adlt,.js-chld').addClass('is-hidden');
      this.$el.find('.js-all-guests').addClass('is-hidden');
    }
  },
  setAdultCount: function(adultCount) {
    this.$el.find('.js-number-of-adults').html(adultCount);
    if (adultCount === 1) {
      this.$el.find('.js-adults-text').html(' ' + this.textValues.$adultText);
    } else {
      this.$el.find('.js-adults-text').html(' ' + this.textValues.$adultsText);
    }
  },
  setChildrenCount: function(childrenCount) {
    var roomCount = parseInt(this.$el.find('.js-rooms-num').val());
    var childFullText = ' ' + this.textValues.$childText + this.textValues.$perroomText,
    childrenFullText = ' ' + this.textValues.$childrenText + this.textValues.$perroomText;

    this.$el.find('.js-number-of-children').html(childrenCount);
    if (childrenCount === 0) {
        this.removeScroller();
        this.$el.find('.js-chld').addClass('is-hidden');
        if(roomCount<10){
            this.$el.find('.js-adults-perroom').removeClass('is-hidden');
        }
    }else {
      this.initScroller();
      if (childrenCount === 1) {
        this.$el.find('.js-children-text').html(childFullText);
      } else {
        this.$el.find('.js-children-text').html(childrenFullText);
      }
      if(roomCount<10){
        this.$el.find('.js-chld').removeClass('is-hidden');
      }
      this.$el.find('.js-adults-perroom').addClass('is-hidden');
    }
  },
  setRoomGuestScrollPosition : function(childrenCount) {
    var roomGuestWrapper = this.$el.find('.rooms-guest-wrapper');
    var scrollTop = roomGuestWrapper.height();
    if (childrenCount == 1) {
    scrollTop = scrollTop - roomGuestWrapper.find('.agecontainer').height();
    this.$el.find('.rooms-guest-wrapper').scrollTop(scrollTop);
    }
  },
  onRoomsGuestsClicked: function(evt,openStickySearchForm) {
    if (evt) {
      evt.preventDefault();
    }
    var _self = this;
    if (this.$parent.responsiveUtils.isMobileOrTablet() && !this.suppressTakeOver) {
      this.takeoverScreen.init();
    }else {
      this.$el.toggleClass('is-active');
      _self.$el.find('.js-rooms-guests-panel').slideToggle('fast').toggleClass('is-hidden').parents('div.l-rooms-guests');
      _self.$dropdownIcon.toggleClass(_self.options.dropdownActive).toggleClass(_self.options.dropdownInActive);
      _self.$el.find('.js-rooms-guests-panel').attr('aria-expanded', !_self.$el.find('.js-rooms-guests-panel').attr('aria-expanded'));
      this.$parent.$el.find('.t-more-search-options').css('overflow', 'visible');
    }
    if (typeof this.expandSearchForm === 'function') {
      this.expandSearchForm();
    }
    _.defer(function(){
      if (_.isFunction(openStickySearchForm)) {
        openStickySearchForm();
      }
    })
  },
  onPlusMinusClicked: function(evt) {
    var $target = this.$el.find(evt.target);
    var _self = this;
    if ($target.hasClass('is-inactive')) {
      return;
    }

    if ($target.parent().find('.js-children-num').length == 1) {
      this.childrenButtonClickHandler(evt, _self);
    } else if ($target.parent().find('.js-ages-num').length == 1) {
      this.childAgeButtonClickHandler(evt, _self);
    } else if ($target.parent().find('.js-adults-num').length == 1) {
      this.guestButtonClickHandler(evt, _self, _self.optionMaxValue);
    } else if ($target.parent().find('.js-rooms-num').length == 1) {
      this.roomButtonClickHandler(evt, _self, _self.optionMaxValue);
    }

    _self.displayText();

    if ($target.parent().find('.js-children-num').length == 1) {
      this.setRoomGuestScrollPosition(parseInt(this.$el.find('.js-number-of-children').html()));
    }
  },
  onDoneClicked: function(evt) {
    evt.preventDefault();
    this.$el.find('.l-rooms-guests-trigger-box').trigger('click');
  },
  onRoomsBlur: function(evt) {
    var $target;
    if(evt) {
      $target = this.$el.find(evt.target);
    }else{
      $target = this.$el.find('.js-rooms-num');
    }
    var numberOfRooms = parseInt($target.val(), 10);
    var _self = this;
    var greaterThanThreeOptions = _self.optionMaxValue > 3;
    var rmsIndex = 0;
    var roomPostText, value, isGXPSelfProperty;

    if (this.isGXPAgentSelfProperty()) {
      isGXPSelfProperty = true;
    }
    if(!isNaN(numberOfRooms)) {
      if (numberOfRooms < 1) {
        value = '1';
      } else if (numberOfRooms > 3 && numberOfRooms < 10 && greaterThanThreeOptions && !isGXPSelfProperty) {
        value = '4-9';
        rmsIndex = 3;
      } else if (numberOfRooms > 9 && numberOfRooms < 26 && greaterThanThreeOptions && !isGXPSelfProperty) {
        value = '10-25';
        rmsIndex = 4;
      } else if (numberOfRooms > 25 && greaterThanThreeOptions && !isGXPSelfProperty) {
        value = '26+';
        rmsIndex = 5;
      } else {
        if (numberOfRooms > _self.optionMaxValue) {
          value = _self.optionMaxValue;
          rmsIndex = _self.optionMaxValue - 1;
        }else {
          value = numberOfRooms;
          rmsIndex = numberOfRooms - 1;
        }
      }
    }else {
      value = '1';
    }
    roomPostText = (parseInt(value) > 1) ? this.textValues.$roomsText : this.textValues.$roomText;
    if (typeof this.roomsModified === 'function') {
      this.roomsModified(value);
    }

    $target.val(value);
    this.$el.find('.js-rooms-num').val(value + ' ' + roomPostText);
    if (isGXPSelfProperty) {
      $target.siblings('.js-room-count-gxp').val(parseInt(value)).prop('selectedIndex', rmsIndex);
    } else {
      $target.siblings('.js-room-count').val(parseInt(value)).prop('selectedIndex', rmsIndex);
    }
    _self.displayText();
    this.toggleRoomButtons();
  },
  onAdultsBlur: function(evt) {
    var $target;
    if(evt) {
      $target = this.$el.find(evt.target);
    }else{
      $target = this.$el.find('.js-adults-num');
    }
    var numberOfGuests = parseInt($target.val());
    var $childDiv = this.$el.find('.js-children-num');
    var childrenCount = parseInt($childDiv.val());
    var max = parseInt($target.attr('data-max'));
    var min = parseInt($target.attr('data-min'));
    var value;
    var gstIndex = 0;
    var _self = this;

    if (!isNaN(numberOfGuests)) {
      if (numberOfGuests <= min) {
        value = min +' '+ this.textValues.$adultText + ' ' + this.textValues.$perroomFullText;
        gstIndex = 0;
      } else if (numberOfGuests + childrenCount > max) {
        value = (max - childrenCount) +' '+ this.textValues.$adultsText + ' ' + this.textValues.$perroomFullText;
        gstIndex = 5;
      } else if (((numberOfGuests + childrenCount) > min) && ((numberOfGuests + childrenCount) <= max)) {
        value = numberOfGuests +' '+ this.textValues.$adultsText + ' ' + this.textValues.$perroomFullText;
        gstIndex = numberOfGuests - 1;
      }
    } else {
      value = min +' '+ this.textValues.$adultText + ' ' + this.textValues.$perroomFullText;
      gstIndex = 0;
    }

    $target.val(value);
    this.$el.find('.guestCount').val(parseInt(value));
    this.$el.find('.js-adults-num').val(value);
    _self.displayText();
    $target.siblings('select').prop('selectedIndex', gstIndex);
    this.toggleChildButtons();
    this.toggleAdultButtons();
  },
  onChildBlur: function(evt) {
    var $target = this.$el.find(evt.target);
    var max = parseInt($target.attr('data-max'));
    var min = parseInt($target.attr('data-min'));
    var adultsCount = parseInt(this.$el.find('.js-adults-num').val());
    var numberOfChildren = parseInt($target.val());
    var value;

    if (!isNaN(numberOfChildren)) {
      if (numberOfChildren < 0) {
        value = min +' '+ this.textValues.$childrenText + ' ' + this.textValues.$perroomFullText;
        this.handleChildAgeDiv(min);
      } else if (numberOfChildren + adultsCount > (max + 1)) {
        value = ((max + 1) - adultsCount) +' '+ ((((max + 1) - adultsCount) > 1) ? this.textValues.$childText + ' ' + this.textValues.$perroomFullText : this.textValues.$childrenText +' '+this.textValues.$perroomFullText);
        this.handleChildAgeDiv((max + 1) - adultsCount);
      } else {
        value = numberOfChildren +' '+ ((numberOfChildren >= 1) ? this.textValues.$childrenText + ' ' + this.textValues.$perroomFullText : this.textValues.$childrenText + ' ' + this.textValues.$perroomFullText);
        this.handleChildAgeDiv(numberOfChildren);
      }
    } else {
      value = min +' '+ this.textValues.$childrenText + ' ' + this.textValues.$perroomFullText;
      this.handleChildAgeDiv(min);
    }

    if (parseInt(value) === 0) {
      this.removeScroller();
    }else {
      this.initScroller();
    }

    $target.val(value);
    this.$el.find('.childrenCount').val(parseInt(value));
    this.$el.find('.js-children-num').val(value);
    this.toggleAdultButtons();
    this.toggleChildButtons();
    this.displayText();
  },
  onChildAgeBlur: function(evt) {
    var $target = this.$el.find(evt.target);
    var defaultValue = $target.attr('data-default');
    var lessThanOne = $target.attr('data-lessthan');
    var dataMax = parseInt($target.attr('data-max'), 10);
    var age = parseInt($target.val(), 10);

    if (!isNaN(age)) {
      if (age > dataMax) {
        $target.val(dataMax);
      } else if (age <= 0) {
        $target.val(lessThanOne);
      } else {
        $target.val(age);
      }
    } else {
      if($target.val() === defaultValue ? $target.val(defaultValue) : $target.val(lessThanOne));
    }
    this.setChildAgeValues();
    this.toggleChildAgeButtons($target);
  },
  toggleChildButtons: function() {
    var $childDiv = this.$el.find('.js-children-num');
    var adultCount = parseInt(this.$el.find('.js-adults-num').val());
    var childCount = parseInt($childDiv.val());
    var min = parseInt($childDiv.attr('data-min'));
    var max = parseInt($childDiv.attr('data-max'));
    var plusButton = $childDiv.parent().find('.l-plus');
    var minusButton = $childDiv.parent().find('.l-minus');

    if ((childCount + adultCount) >= (max + 1)) {
      plusButton.addClass(this.options.inactive);
      if (childCount === min)
          minusButton.addClass(this.options.inactive);
      else
          minusButton.removeClass(this.options.inactive);
    } else {
      plusButton.removeClass(this.options.inactive);
      if (childCount > min)
          minusButton.removeClass(this.options.inactive);
      else
          minusButton.addClass(this.options.inactive);
    }
  },
  toggleAdultButtons: function(showMinus, showPlus) {
    var $adultDiv = this.$el.find('.js-adults-num');
    var childCount = parseInt(this.$el.find('.js-children-num').val());
    var adultCount = parseInt($adultDiv.val());
    var min = parseInt($adultDiv.attr('data-min'));
    var max = parseInt($adultDiv.attr('data-max'));
    var plusButton = $adultDiv.parent().find('.l-plus');
    var minusButton = $adultDiv.parent().find('.l-minus');

    if (childCount + adultCount >= max) {
      plusButton.addClass(this.options.inactive);
      if (adultCount === min)
          minusButton.addClass(this.options.inactive);
      else
          minusButton.removeClass(this.options.inactive);
    } else {
      plusButton.removeClass(this.options.inactive);
      if (adultCount > min)
          minusButton.removeClass(this.options.inactive);
      else
          minusButton.addClass(this.options.inactive);
    }
  },
  toggleChildAgeButtons: function($target) {
    var min = $target.attr('data-min');
    var max = $target.attr('data-max');
    var defaultValue = $target.attr('data-default');
    var lessThanOne = $target.attr('data-lessthan');
    var value = $target.val();
    var plusButton = $target.parent().find('.l-plus');
    var minusButton = $target.parent().find('.l-minus');

    if (value === defaultValue || value === lessThanOne) {
      minusButton.addClass(this.options.inactive);
    } else if (parseInt(value) >= parseInt(max)) {
      minusButton.removeClass(this.options.inactive);
      plusButton.addClass(this.options.inactive);
    } else {
      minusButton.removeClass(this.options.inactive);
      plusButton.removeClass(this.options.inactive);
    }
  },
	toggleRoomButtons: function() {
		var $roomDiv = this.$el.find('.js-rooms-num');
		var roomCount = parseInt($roomDiv.val());
		var plusButton = $roomDiv.siblings('.js-plus');
		var minusButton = $roomDiv.siblings('.js-minus');
		var specialRate = $('#specialRates').text();
		var specialRates = specialRate.toString().toUpperCase();
		if (specialRates === 'H77' || specialRates === 'YX3') {
			minusButton.addClass(this.options.inactive);
			plusButton.addClass(this.options.inactive);
		} else if (specialRates === '7TA')  {
			if (roomCount === 1) {
				minusButton.addClass(this.options.inactive);
				plusButton.removeClass(this.options.inactive);
			} else if (roomCount === 2) {
				plusButton.addClass(this.options.inactive);
				minusButton.removeClass(this.options.inactive);
			}
		} else {
			if (roomCount === 1) {
				minusButton.addClass(this.options.inactive);
				plusButton.removeClass(this.options.inactive);
			} else if (roomCount === parseInt(this.optionMaxValue, 10)) {
				minusButton.removeClass(this.options.inactive);
				plusButton.addClass(this.options.inactive);
			} else {
				minusButton.removeClass(this.options.inactive);
				plusButton.removeClass(this.options.inactive);
			}
		}
	},
  handleChildAgeDiv: function(divCount) {
    var _self = this;
    var ageAppend = this.$el.find('.js-age-template').html();
    var oldChildSelectorCount = this.$el.find('.js-ages-append .agecontainer').length;
    var currentVal;
    if(!divCount){
        this.$el.find('.js-chld').addClass('is-hidden');
        this.$el.find('.js-ages-append').html('');
        this.$el.find('.js-age-based').hide();
    }
    else{
        this.$el.find('.js-age-based').show();
        if (divCount > oldChildSelectorCount) {
          currentVal = oldChildSelectorCount + 1;
          for (var count = oldChildSelectorCount; count < divCount; count++) {
            this.$el.find('.js-ages-append').append('<div class=\'agecontainer l-s2-stepper\'>' + ageAppend + '</div>');
            var text;
            this.$el.find('.js-ages-append').each(function(index) {
                text = _self.textValues.$childText +' '+ currentVal ;
                var numberOfChild = $(this).find('.agecontainer').length;
                var recentAddedChild = $(this).find('.agecontainer').last();

                var oldId =  recentAddedChild.find('.js-ages-num').attr('id');
                $(this).find('.agecontainer .l-childnum-title').last().text(_self.textValues.$childText +' '+ currentVal + ' : ' +' '+ _self.textValues.$ageText);

                recentAddedChild.find('.js-ages-num').attr('id', oldId + "_" + numberOfChild);
                var labelForChildAgeBox = recentAddedChild.find('label[for="'+oldId+'"]');
                labelForChildAgeBox.attr('for', oldId + "_" + numberOfChild);
                labelForChildAgeBox.html(text+' '+ _self.textValues.$ageText+' '+_self.textValues.$maxAgeLimit);

                var childrenAgesNumId = recentAddedChild.find('.childrenAgesNum').attr('id');
                recentAddedChild.find('.childrenAgesNum').attr('id', childrenAgesNumId + "_" + numberOfChild);
                var labelForChildAgeNum = recentAddedChild.find('label[for="'+childrenAgesNumId+'"]');
                labelForChildAgeNum.attr('for', childrenAgesNumId + "_" + numberOfChild);
            });
            currentVal++;
          }
        } else {
          for (var count = divCount; count < oldChildSelectorCount; count++) {
            _self.$el.find('.js-ages-append .agecontainer').last().remove();
          }
        }
    }
    this.setChildAgeValues();
  },
  onClearClicked: function(event) {
    var _self = this;
    if (event) {
      event.preventDefault();
    }
    _self.$el.find('.roomCount,.guestCount').val(1);
    _self.$el.find('.js-rooms-num').val('1 ' + _self.textValues.$roomText);
    _self.$el.find('.js-adults-num').val('1 ' + _self.textValues.$adultText + ' ' + _self.textValues.$perroomFullText);
    _self.$el.find('.childrenCount').val(0);
    _self.$el.find('.js-children-ages').val('');
    _self.$el.find('.js-children-num').val('0 '+_self.textValues.$childrenText +' '+_self.textValues.$perroomFullText).blur();
    _self.$el.find('.js-rooms-guests-panel input[type="text"]').not('.js-ages-num,.js-children-num').blur();
    _self.$el.find('.js-ages-append').html('');
    _self.$el.find('.js-chld').addClass('is-hidden');
    _self.$el.find('.js-age-based').hide();
    _self.displayText();
  },
	resetRoomCountOnChange: function(event) {
		var $roomDiv = this.$el.find('.js-rooms-num');
		var plusButton = $roomDiv.siblings('.js-plus');
		var minusButton = $roomDiv.siblings('.js-minus');
		var specialRate = $('input[data-special-rate-type="corp"]').val();
		var specialRates = specialRate.toString().toUpperCase();
		var _self = this;
		if (event) {
			event.preventDefault();
		}
		_self.$el.find('.roomCount').val(1);
		_self.$el.find('.js-rooms-num').val('1 ' + _self.textValues.$roomText);
		_self.displayTextOnReset();
		if (specialRates === 'H77' || specialRates === 'YX3') {
			minusButton.addClass(this.options.inactive);
			plusButton.addClass(this.options.inactive);
		} else {
			minusButton.addClass(this.options.inactive);
			plusButton.removeClass(this.options.inactive);
		}
	},	
	resetRoomCountOnClick: function(event) {
		var $roomDiv = this.$el.find('.js-rooms-num');
		var plusButton = $roomDiv.siblings('.js-plus');
		var minusButton = $roomDiv.siblings('.js-minus');
		var _self = this;
		if (event) {
			event.preventDefault();
		}
		_self.$el.find('.roomCount').val(1);
		_self.$el.find('.js-rooms-num').val('1 ' + _self.textValues.$roomText);
		_self.displayTextOnReset();
		minusButton.addClass(this.options.inactive);
		plusButton.removeClass(this.options.inactive);
	},
  onClose: function(evt, _self) {
    if (_self.$el.find(evt.target).length === 0 && _self.$el.hasClass('is-active')) {
      if (!_self.$parent.responsiveUtils.isDevice() && _self.$parent.$el.closest('.is-sticky-top').length ) {
        _self.pubsub.publish('SHOW_HWS_NAVIGATION_BAR');
      }
      _self.$el.removeClass('is-active');
      _self.$el.find('.js-rooms-guests-panel').slideUp();
      _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
    }
  },
  childrenButtonClickHandler: function(evt, _self) {
    evt.preventDefault();
    var currentVal = parseInt(_self.$el.find('.js-children-num').val());
    var max = parseInt(_self.$el.find('.js-children-num').attr('data-max'));
    var min = parseInt(_self.$el.find('.js-children-num').attr('data-min'));
    var ageAppend = _self.$el.find('.js-age-template').html();
    var $target = _self.$el.find(evt.target);
    var adultsCount = parseInt(_self.$el.find('.js-adults-num').val());

    if ($target.is('.l-plus') && ((currentVal + adultsCount) < 6)) {
        _self.$el.find('.js-age-based').show();
        if (currentVal < max) {
          currentVal = currentVal + 1;
          _self.$el.find('.js-chld').removeClass('is-hidden');
          _self.$el.find('.js-ages-append').show();
          _self.$el.find('.js-ages-append').append('<div class=\'agecontainer l-s2-stepper\'>' + ageAppend + '</div>');
          var text;
          this.$el.find('.js-ages-append').each(function(index) {
            text = _self.textValues.$childText +' '+ currentVal ;
            _self.setChildrenAgesInputProperties($(this), text);
          });

          if (currentVal === 1) {
            _self.$el.find('.js-number-of-children').text('1');
            _self.$el.find('.js-children-text').text(' '+_self.textValues.$childText);
            _self.$el.find('.js-children-num').val(currentVal + ' ' + _self.textValues.$childText +' '+ _self.textValues.$perroomFullText);
          } else {
            _self.$el.find('.js-number-of-children').text(currentVal);
            _self.$el.find('.js-children-text').text(' '+_self.textValues.$childrenText);
            _self.$el.find('.js-children-num').val(currentVal + ' ' +_self.textValues.$childrenText +' '+_self.textValues.$perroomFullText);
          }
        }
    } else if($target.is('.l-minus')){
      var adultsMax = parseInt(_self.$el.find('.js-adults-num').attr('data-max'));

      if (currentVal > min) {
        currentVal = currentVal - 1;
        _self.$el.find('.js-ages-append .agecontainer').last().remove();
        _self.$el.find('.js-children-num').val(currentVal +' '+_self.textValues.$childrenText +' '+_self.textValues.$perroomFullText);
      }

      if (currentVal === 1) {
        _self.$el.find('.js-number-of-children').text('1');
        _self.$el.find('.js-children-text').text(' '+this.textValues.$childText);
        _self.$el.find('.js-children-num').val(currentVal + ' ' + _self.textValues.$childText +' '+ _self.textValues.$perroomFullText);
      } else if (currentVal === 0) {
        _self.$el.find('.js-number-of-children').text(currentVal);
        _self.$el.find('.js-children-text').text(' '+_self.textValues.$childrenText);
        _self.$el.find('.js-chld').addClass('is-hidden');
        _self.$el.find('.js-age-based').hide();
      } else {
        _self.$el.find('.js-number-of-children').text(currentVal);
        _self.$el.find('.js-children-text').text(' '+_self.textValues.$childrenText);
      }
    }
    _self.$el.find('.childrenCount').val(currentVal);
    _self.$el.find('.js-children-num').data('oldVal', currentVal);
    this.setChildAgeValues();
    this.toggleAdultButtons();
    this.toggleChildButtons();
  },

  /*
   * Setting the Input/Select/Label Properties for Children ages field
   */
  setChildrenAgesInputProperties: function($childAgeHolder, text) {
    var numberOfChild = $childAgeHolder.find('.agecontainer').length;
    var recentAddedChild = $childAgeHolder.find('.agecontainer').last();
    var oldId =  recentAddedChild.find('.js-ages-num').attr('id');
    var isResLink = this.$parent.$el.find('.l-reslink-room-guests-container');
    // if (isResLink) {
      recentAddedChild.find('.l-childnum-title').text(text + ' : ' +this.textValues.$ageText);
    // }

    recentAddedChild.find('.js-ages-num').attr('id', oldId + "_" + numberOfChild);
    var labelForChildAgeBox = recentAddedChild.find('label[for="'+oldId+'"]');
    labelForChildAgeBox.attr('for', oldId + "_" + numberOfChild);
    labelForChildAgeBox.html(text +' '+ this.textValues.$ageText+' '+this.textValues.$maxAgeLimit);

    var childrenAgesNumId = recentAddedChild.find('.childrenAgesNum').attr('id');
    recentAddedChild.find('.childrenAgesNum').attr('id', childrenAgesNumId + "_" + numberOfChild);
    var labelForChildAgeNum = recentAddedChild.find('label[for="'+childrenAgesNumId+'"]');
    labelForChildAgeNum.attr('for', childrenAgesNumId + "_" + numberOfChild);
  },

  /*
   * roomsGuests partial : click handler for '+' and '-' for Child age
   */
  childAgeButtonClickHandler: function(evt, _self) {
    evt.preventDefault();
    var $target = _self.$el.find(evt.target);
    var max = $target.siblings('.js-ages-num').attr('data-max');
    var min = $target.siblings('.js-ages-num').attr('data-min');
    var defaultValue = $target.siblings('.js-ages-num').attr('data-default');
    var lessThanOne = $target.siblings('.js-ages-num').attr('data-lessthan');
    var currentVal = $target.siblings('.js-ages-num').val();

    if ($target.is('.l-plus')) {
						if(isNaN(currentVal)) {
							currentVal = (currentVal === defaultValue) ? lessThanOne : '1';
						}else{
									currentVal = parseInt(currentVal) + 1;
						}

      if (currentVal > (parseInt(max) - 1)) {
        currentVal = max;
      }
      $target.siblings('.js-ages-num').val(currentVal);
      $target.siblings('span.js-ages-text').text(currentVal);
    } else {
      if (currentVal > 0) {
        currentVal = parseInt(currentVal) - 1;
        $target.siblings('.js-ages-text').text(currentVal);
        if (currentVal == 0) {
          currentVal = lessThanOne;
        }
        $target.siblings('.js-ages-num').val(currentVal);
      } else if (isNaN(currentVal)) {
      		currentVal = lessThanOne;
      		$target.siblings('.js-ages-num').val(currentVal);
      }
    }

    this.setChildAgeValues();
    this.toggleChildAgeButtons($target.siblings('.js-ages-num'));
  },

  setChildAgeValues: function() {
    var _self = this;
    var childAgeArray = [];
    this.$el.find('.js-ages-num:visible').each(function() {
      if ($(this).val() === _self.textValues.$defaultAge || $(this).val() === _self.textValues.$lessthan) {
        if ($(this).val() === _self.textValues.$defaultAge ? childAgeArray.push('-1') : childAgeArray.push('0'));
      }else{
        childAgeArray.push($(this).val());
      }
    });
    this.$el.find('.js-children-ages').val(childAgeArray.join(','));
  },

  /*
   * roomsGuests partial : click handler for '+' and '-' for Adult count
   */
  guestButtonClickHandler: function(evt, _self, optionMaxValue) {
    evt.preventDefault();
    var currentVal = parseInt(_self.$el.find('.js-adults-num').val());
    var $target = _self.$el.find(evt.target);
    var childrenCount = parseInt(_self.$el.find('.js-children-num').val());
    var childText;

    if ($target.is('.l-plus')) {
      if (currentVal + childrenCount < 6) {
        if (currentVal >= 6) {
          return false;
        }
        currentVal = currentVal + 1;
        _self.$el.find('.js-adults-num').val(currentVal + ' ' + _self.textValues.$adultsText + ' ' + _self.textValues.$perroomFullText);
      }
    }

    if ($target.is('.l-minus')) {
      var childrenMax = parseInt(_self.$el.find('.js-children-num').attr('data-max'));

      if (currentVal == 1) {
        _self.$el.find('.js-number-of-adults').text(currentVal);
        _self.$el.find('.js-adults-text').text(' Adult');
        return false;
      }
      currentVal = currentVal - 1;

      if (currentVal != 1) {
        childText = currentVal + ' ' + _self.textValues.$adultsText + ' ' + _self.textValues.$perroomFullText;
      } else {
        childText = currentVal + ' ' + _self.textValues.$adultText + ' ' + _self.textValues.$perroomFullText;
      }
      _self.$el.find('.js-adults-num').val(childText);
    }

    if (currentVal == 1) {
      _self.$el.find('.js-number-of-adults').text(currentVal);
      _self.$el.find('.js-adults-text').text(' Adult');
    } else {
      _self.$el.find('.js-number-of-adults').text(currentVal);
      _self.$el.find('.js-adults-text').text(' Adults');
    }

    _self.$el.find('.guestCount').val(currentVal);
    this.toggleChildButtons();
    this.toggleAdultButtons();
  },

  /*
   * roomsGuests partial : click handler for '+' and '-' for room count
   */
  roomButtonClickHandler: function(evt, _self, optionMaxValue) {
    var $target = _self.$el.find(evt.target);
    var numberRoomsOrGuests = parseInt($target.siblings('input').val());
    var grCount, i, $selectedRoomCount;
    evt.preventDefault();

    if (this.isGXPAgentSelfProperty()) {
      $selectedRoomCount = $target.siblings('select.js-room-count-gxp');
      this.optionMaxValue = this.$el.find('.js-room-count-gxp option:last-child').val();
    } else {
      $selectedRoomCount = $target.siblings('select.js-room-count');
      this.optionMaxValue = this.$el.find('.js-room-count option:last-child').val();
    }
    
    $selectedRoomCount.find('option').each(function(i) {
      if ($target.is('.l-plus')) {
        if (parseInt(_self.$el.find(this).text()) === numberRoomsOrGuests && i < $selectedRoomCount.find('option').length && !$target.hasClass(_self.options.inactive)) {
          i++;
          $selectedRoomCount.prop('selectedIndex', i);
          grCount = $selectedRoomCount.find('option:selected').text().trim();
          $target.siblings('input').val(grCount);
        }
      } else {
        if (parseInt(_self.$el.find(this).text()) === numberRoomsOrGuests && i > 0) {
          i--;
          $selectedRoomCount.prop('selectedIndex', i);
          grCount = $selectedRoomCount.find('option:selected').text().trim();
          $target.siblings('input').val(grCount);
        }
      }
    });

    if (typeof this.roomsModified === 'function') {
      this.roomsModified(grCount);
    }

    this.toggleRoomButtons();
  },
  /**
   * This method binds the click event on elements
   * @method isGXPAgentSelfProperty
   * @returns {boolean} true when the current property code is AgentSelfProperty
  */
  isGXPAgentSelfProperty: function _isGXPAgentSelfProperty () {
    if (this.$parent.$el.find('.search-property-code').val() && (this.$parent.$el.find('.search-property-code').val() === this.gxpAgentPropertyCode && !this.$parent.$el.find('.js-userewards-checkbox').is(':checked'))) {
      this.$el.data('is-agent-self-property', true);
      this.$el.find('.js-room-count').attr('disabled', 'disabled');
      this.$el.find('.js-room-count-gxp').removeAttr('disabled');
      return true;
    }
    this.$el.find('.js-room-count-gxp').attr('disabled', 'disabled');
    this.$el.find('.js-room-count').removeAttr('disabled');
    return false;
  }
});

module.exports = RoomGuestsHandler;
