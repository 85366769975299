/**
 * Common Utility Methods
 *
 * @type {Object}
 */
module.exports = {
  /**
   * Get value of a queryParam from a given queryString
   * @param {string} name - name of the queryParam
   * @param {string} url - querystring
   * @returns {string} value of queryparam
   */
  getParameterByName: function(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
};
