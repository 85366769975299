var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "l-userewards-checkbox";
},"3":function(container,depth0,helpers,partials,data) {
    return "l-h-field";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"useRewards") : depth0)) != null ? lookupProperty(stack1,"wrapperCss") : stack1), depth0))
    + " use-rewards-custom\">\n     <div class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"associateSearch") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":17},"end":{"line":3,"column":76}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isModifyRateList") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":77},"end":{"line":3,"column":117}}})) != null ? stack1 : "")
    + "\" id=\"rewards-use-search\">\n        <fieldset class=\"t-border-none l-margin-none l-padding-none\">\n            <legend class=\"is-hidden\" aria-hidden=\"true\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"useRewards") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</legend>\n            <input type=\"checkbox\" name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"useRewards") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" id=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"useRewards") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"useRewards") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" class=\"l-h-toggle-checkbox js-h-toggle-checkbox  js-userewards-checkbox search-useRewardsPoint checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"useRewards") : depth0)) != null ? lookupProperty(stack1,"checked") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":245},"end":{"line":6,"column":296}}})) != null ? stack1 : "")
    + ">\n            <label for=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"useRewards") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" class=\"l-userewards-checkmark\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"useRewards") : depth0)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</label>\n        </fieldset>\n    </div>\n</div>\n";
},"useData":true});