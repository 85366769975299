var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "l-has-saved-hotels";
},"3":function(container,depth0,helpers,partials,data) {
    return "search-form-dest-page";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/specialRates.hbs"),depth0,{"name":"partials/specialRates","hash":{"isModifyRLM":"true"},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/useRewards.hbs"),depth0,{"name":"partials/useRewards","hash":{"isModifyRateList":true},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tile-modify-ratelist-form m-homepage-hsearch l-hsearch-2 l-hsearch-takeover l-hsearch-cntnr l-hsearch-bottom l-clear js-modify-rlm-search-form js-check-data-change is-opened "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":186},"end":{"line":1,"column":238}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDestPageSearchForm") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":239},"end":{"line":1,"column":295}}})) != null ? stack1 : "")
    + "\" data-roomkeyflag=\""
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"showRoomKeyPopup")), depth0))
    + "\" data-done-button-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"done") : stack1), depth0))
    + "\" data-id-prefix=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"idPrefix") : stack1), depth0))
    + "\" data-suppress-google-api=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"geoLocationNotCN") : depth0), depth0))
    + "\" "
    + ((stack1 = container.invokePartial(require("./partials/errorMessages.hbs"),(depth0 != null ? lookupProperty(depth0,"errorMessages") : depth0),{"name":"partials/errorMessages","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " data-enable-google-places-api=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"enableGooglePlaceAutoSuggestion") : depth0), depth0))
    + "\" data-autocomplete-supported-types="
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"autocompleteSupportedTypes") : stack1), depth0))
    + " data-radius-oriented-countries="
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"countriesSupportingRadiusSearch") : stack1), depth0))
    + " data-enable-user-location=\""
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"enableUserLocation")), depth0))
    + "\">\n    <div class=\"clearfix homepage-search-form l-form-container\">\n        <a name=\"search-content\"></a>\n        <form action=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"action") : stack1), depth0))
    + "\" novalidate=\"novalidate\" id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" method=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "\" class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"cssClass") : stack1), depth0))
    + " l-clear\" data-default-endpoint=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"defaultEndpoint") : stack1), depth0))
    + "\" data-suggestion-endpoint-mapping="
    + alias3(__default(require("../../../../../../core/helpers/parseJson.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"suggestionEndpointMapping") : stack1),{"name":"parseJson","hash":{},"data":data,"loc":{"start":{"line":4,"column":238},"end":{"line":4,"column":299}}}))
    + " data-is-group-seach-applicable=\""
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isGroupSearchApplicable")), depth0))
    + "\" data-group-search-endpoint="
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"groupSubmitSearchURL")), depth0))
    + ">\n\n"
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"global") : stack1),{"name":"hiddenInputs","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"availabilitySearch") : stack1),{"name":"hiddenInputs","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/searchHeaderTakeover.hbs"),depth0,{"name":"partials/searchHeaderTakeover","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div class=\"l-form-group m-field-wrap l-hsearch-labels l-clear\">\n"
    + ((stack1 = container.invokePartial(require("./partials/location.hbs"),depth0,{"name":"partials/location","hash":{"isReadonly":"true"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/dates.hbs"),depth0,{"name":"partials/dates","hash":{"isModifyRLM":"true"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n\n\n            <div class=\"t-more-search-options l-xs-col-4 l-xl-col-12\">\n"
    + ((stack1 = container.invokePartial(require("./partials/roomsGuests.hbs"),depth0,{"name":"partials/roomsGuests","hash":{"isErsModifyRLM":"true","hideRooms":"true"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"specialRates") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useRewards") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":26,"column":23}}})) != null ? stack1 : "")
    + "\n                <div class=\"m-field-control l-xs-col-4 l-xl-col-3 l-xl-last-col l-hsearch-find  js-hform-fields\">\n"
    + ((stack1 = container.invokePartial(require("./partials/submitButton.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"searchButton") : stack1),{"name":"partials/submitButton","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n          </div>\n        </form>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});