// This file is used to initialize core autocomplete handler and passes autocomplete options as an parameter
// Parameter consists labels(location,recent,search,clear),callbacks(onSearch,onSelect,onListClose) and list container element
var $ = require('jquery');
var _ = require('lodash');
var extend = require('extend');
var Autocomplete = require('libs/autocomplete');
var AutocompleteFormFieldMapper = require('./autocompleteFormFieldMapper');
var AutocompleteFormMapping = require('./autocompleteFormMapping');
var Popup = require('libs/popup');
var ClearInputHandler = require('libs/clear-input-handler');
var Promise = require('bluebird');
var Cookies = require('libs/cookies');
var Constants = require('libs/constants');

/**
 * Constructor method to create autcomplete handler for Search module
 * @param {HTMLElement} el - HTMLElement (input) to add autocomplete
 * @param {Object} googleApiWrapper - Google APIs wrapper service 
 */
function AutocompleteHandler(scope, googleApiWrapper, options) {
  var autocompleteCategoryParams = scope.$el.find('.autosuggest-cat-headers').data('category-params');
  var autocompleteOptions = extend({
      currentLocLabel: autocompleteCategoryParams.currentLocation,
      recentLabel: autocompleteCategoryParams.recent,
      searchLabel: autocompleteCategoryParams.searchResults,
      clearRecentLabel: autocompleteCategoryParams.clear,
      onSearch: this.autocompleteSearch.bind(this),
      onSelect: this.autocompleteSelected.bind(this),
      listContainerEl: options.listContainerEl,
      onListClose: options.onListClose
    }, (options || {}));
  this.options = this.getOptions(options);
  this.$parent = scope;
  this.target = scope.autocompleteInputEl;
  this.form = this.target.form;
  // While initiating autocomplete handler googleApiWrapper object and autocomplete options are passed through components(searchFormHorizontal,dealSeachForm)
  this.autocompleteService = googleApiWrapper.getAutocompleteService();
  this.autocompleteSupportedTypes = options.autocompleteSupportedTypes;
  this.geocoderService = googleApiWrapper.getGeocoderService();
  this.getPlacesService = googleApiWrapper.getPlacesService(document.createElement('div'));
  this.geometryService = google.maps.geometry;

  this.formFieldMapper = new AutocompleteFormFieldMapper(this.form, AutocompleteFormMapping.get(this.form.getAttribute('id')));
  this.hideSuggestions = function(){};

  new Autocomplete(scope, this.target, autocompleteOptions);

  this.populateCurrentLocation();
  this.initClearInputHandler(scope);
}

/**
 * Autocomplete API
 */
var AutocompleteHandlerAPI = {
  populateCurrentLocation: function() {
    if(sessionStorage && Cookies.readCookie('loadCurrentLocation') === "true") {
      Cookies.eraseCookie('loadCurrentLocation');
      this.findLocation();
    }
  },
  /*
  *Initializing clear input handler
  */
  initClearInputHandler: function (scope) {    
    this.clearInputHandler = new ClearInputHandler({
      $el: scope.$el.find('.js-auto-complete'),
      $parent: this
    });
    this.clearInputHandler.init();
  },
  /**
   * Search callback for Autocomplete component
   * @param {string} searchTerm - Search term
   * @returns {Object} - Promise object to be resolved/rejected with API response
   */
  autocompleteSearch: function(searchTerm) {
    var self = this;
    var searchQuery = {
      input: searchTerm,
      types: this.options.types,
      // disable google's default biased search
      location: new google.maps.LatLng({lat: 0, lng: 0}),
      types: this.autocompleteSupportedTypes,
      radius: 20000000
    };

    this.formFieldMapper.resetFormFields();

    return new Promise(function(resolve, reject) {
      
      self.autocompleteService.searchPlaces(searchQuery)
        .then(function(predictions) {
          resolve({
            searchTerm: searchTerm, 
            searchResult: self.parseAutocompletePredictions(predictions)
          });
        }, function(err) {
          console.log(err);
          reject(err);
        });
    });
  },

  /**
   * Select suggestion callback for Autocomplete component
   * @param {string} type - Type of suggestion item selected (location, recent or prediction)
   * @param {HTMLElement} el - Selected suggestion list item
   * @param {Object} data - Data associated with selected suggestion listitem
   */
  autocompleteSelected: function(type, el, data) {
    switch (type) {
        case 'prediction':
          this.findPlaceDetail(data);
          break;
        case 'recent':
          this.findPlaceDetail(data);
          break;
        case 'currentLoc':
          this.findLocation();  
        default:
          break;;
    }
  },

  /**
   * Parse search results predictions
   * @param {Array} predictions - Google Api predictions list
   * @param {Array} - Formatted data to show into autocomplete list
   */
  parseAutocompletePredictions: function(predictions) {
    var data = [];

    predictions.forEach(function(prediction) {
      if (prediction.types.indexOf('colloquial_area') === -1 ) {
        data.push({
          mainText: prediction.structured_formatting.main_text,
          secondaryText: prediction.structured_formatting.secondary_text || '',
          placeId: prediction.place_id,
          formattedAddress: prediction.description
        });
      }
    });
    this.autoSuggestMobileFix();
    return data;
  },

  /**
   * Navigate current geolocation and geocode lookup
   */
  findLocation: function() {
    var protocol="https:";
    if (window.location.protocol !== protocol) {
      Cookies.createCookie('loadCurrentLocation', true);
      window.location.replace(protocol + '//' + window.location.host + window.location.pathname);
      return;
    }
    navigator.geolocation.getCurrentPosition(this.positionSuccess.bind(this), this.positionError.bind(this));
  },

  /**
   * Position success handler
   * @param {Number} lat - Latitude
   * @param {Number} lng - Longitude
   */
  positionSuccess: function(position) {
    var input = {
      location: {
        lat: Number(position.coords.latitude),
        lng: Number(position.coords.longitude)
      }
    };

    this.geocoderService.geocode(input)
      .then(this.geocodeSuccess.bind(this), this.geocodeError.bind(this));
  },

  /**
  * This function is called when error occurs on getting address data on basis of latitude and longitude
  * @param {Object} target DOM hidden element that contains information in data attributes.
  * @param {Object} errMsg error message returned from current location.
  */
  positionError: function() {
    var $dataValues = this.$parent.$el.find('.js-location-nearme-values');
    var errorMsg = '<div class="l-padding-left l-padding-right mi-popover" id="no-Currentloc-popup">'
      + '<p class="l-center-align t-font-bold">'
      + $dataValues.data('errhead') + '</p>'
      + '<p class="l-center-align">' + $dataValues.data('errmessage') + '</p>'
      + '</div>';

    var errorPopup = new Popup({
      mainClass: 'm-modal t-modal-small',
      items: {src: errorMsg},
      alternateCloseMarkup: '<div class="l-padding-left l-padding-right "><button class="js-mfp-close l-width-max m-button-lightestGray">' + $dataValues.data('error-popup-button-text') + '</button></div>',
      closeOnContentClick: true,
      open: true,
      ajaxLoaded: false
    });
    errorPopup.register();
  },

  /**
   * Find place detail of a place
   * @param {Object} data - Search input
   */
  findPlaceDetail: function(data) {
    this.getPlacesService.getDetails({placeId: data.placeId})
      .then(this.placeDetailSuccess.bind(this, data), this.placeDetailError.bind(this));
  },
  
  /**
   * Success handler for geocode lookup success
   * @param {Object} result - Geocode result
   */
  geocodeSuccess: function(result) {
    var parsedGeocodeResult;
    var $dataValues = this.$parent.$el.find('.js-location-nearme-values');
    if (result[0]) {
      parsedGeocodeResult = this.parseGeocodeResult(result[0]);
      this.updateFormFields(parsedGeocodeResult);
      this.target.value = $dataValues.data('neartext') + ' ' + parsedGeocodeResult.city + ', ' + parsedGeocodeResult.stateProvinceShort + ', ' + parsedGeocodeResult.countryShort;
    }
  },

  /**
   * Error handler for geocode lookup error
   * @param {Object} result - Geocode result
   */
  geocodeError: function(result) {
    formFieldMapper.resetFormFields();
  },

  /**
   * Parse geocode result into a common format for showing into autocomplete and storing into recent search
   * @param {Object} geocodeResult - Geocode result
   * @returns {Object} - common format for showing into autocomplete and storing into recent search
   */
  parseGeocodeResult: function(geocodeResult) {
    var response = {},
      result = {
        name: geocodeResult.name || '',
        latitude: '',
        longitude: '',
        city: '',
        cityCodes: '',
        stateProvince: '',
        stateProvinceShort: '',
        countryShort: '',
        placeId: geocodeResult.place_id,
        mainText: geocodeResult.mainText ? geocodeResult.mainText : (geocodeResult.formatted_address || ''),
        secondaryText: geocodeResult.secondaryText || '',
        showAddressPin: false,
        website: geocodeResult.website,
        address: geocodeResult.formatted_address,
        locality: '',
        postalcode: '',
        types: geocodeResult.types + ''
      };

    var fullAddress = $("<div>" + geocodeResult.adr_address + '</div>');
    this.radiusOrientedCountries = (this.$parent.$el.data('radius-oriented-countries')) ? this.$parent.$el.data('radius-oriented-countries').split(',') : [];
    result.postalcode = fullAddress.find('.postal-code').text();
    geocodeResult.address_components.forEach(function(item) {
      var itemProp = item.types[0];
      if(item.short_name){
        response[itemProp + '_short'] = item.short_name;
      }
      response[itemProp] = item.long_name;
    });
    result.locality = response.locality_short || response.sublocality_level_1_short || '';
    if (this.isGeocodeResultState(geocodeResult)) {
      result.stateProvinceShort = geocodeResult.address_components[0].short_name;
      result.countryShort = geocodeResult.address_components[1].short_name;
      // use short names if prediction is state or country
      result.stateProvince = geocodeResult.address_components[0].short_name;
      result.country = geocodeResult.address_components[1].short_name;

      if (_.includes(this.radiusOrientedCountries, result.country)) {
        result.latitude = geocodeResult.geometry.location.lat();
        result.longitude = geocodeResult.geometry.location.lng();
        // Convert radius into miles
        result.radius = this.getRadiusFromLatLng(geocodeResult) * Constants.GLOBAL.MILE_CONVERSION_MULTIPLY_FACTOR;
      }
    } else if (this.isGeocodeResultCountry(geocodeResult)) {
      result.country = geocodeResult.address_components[0].short_name;
      result.countryShort = geocodeResult.address_components[0].short_name;
    } else {
      result.latitude = geocodeResult.geometry.location.lat();
      result.longitude = geocodeResult.geometry.location.lng();
      result.city = response.locality || '';
      result.cityCodes = response.locality_short || '';
      result.stateProvince = response.administrative_area_level_1_short || '';
      result.stateProvinceShort = response.administrative_area_level_1_short || '';
      result.country = response.country || '';
      result.countryShort = response.country_short || '';
    }
    
    result.showAddressPin = this.isSpecificAddrType(geocodeResult);    

    return result;
  },
  getRadiusFromLatLng: function(geocodeResult) {
    var center = geocodeResult.geometry.viewport.getCenter();
    var ne = geocodeResult.geometry.viewport.getNorthEast();

    return this.geometryService.spherical.computeDistanceBetween(center, ne);
  },
  /**
   * Check if geocode result is a state
   * @param {Object} geocodeResult - Google api geocode result
   * @returns {Boolean} TRUE if geocode result is a state
   */
  isGeocodeResultState: function(geocodeResult) {
    return geocodeResult.address_components.length === 2
      && geocodeResult.address_components[0].types.indexOf('administrative_area_level_1') > -1
      && geocodeResult.address_components[1].types.indexOf('country') > -1
  },

  /**
   * Check if searched destination is one out of 'establishment', 'premise', 'route', 'street_address'
   * @param {Object} geocodeResult - Google api geocode result
   * @returns {Boolean} TRUE if geocode result is a state
   */
  isSpecificAddrType: function(geocodeResult) {
    var specificAddresses = ['establishment', 'premise', 'route', 'street_address'];        

    return geocodeResult.types.some(function(item, index){            
      if($.inArray(item, specificAddresses) > -1){        
        return true;
      }
    });       
  },

  /**
   * Check if geocode result is a country
   * @param {Object} geocodeResult - Google api geocode result
   * @returns {Boolean} TRUE if geocode result is a country
   */
  isGeocodeResultCountry: function(geocodeResult) {
    return geocodeResult.address_components.length === 1
      && geocodeResult.address_components[0].types.indexOf('country') > -1
  },

  /**
   * Success handler for place detail request
   * @param {Object} result - Place detail result
   */
  placeDetailSuccess: function(prediction, geocodeResult) {
    var data;
    if (prediction && geocodeResult) {
      data = extend({}, prediction, geocodeResult);
      this.updateFormFields(this.parseGeocodeResult(data));
    }
  },

  /**
   * Error handler for place detail request
   * @param {string} err - Place detail error message
   */
  placeDetailError: function(err) {
    this.formFieldMapper.resetFormFields();
  },

  /**
   * Update form fields with options
   * @param {string} options - Key/Value pair options list for form fields
   */
  updateFormFields: function(options) {
    this.formFieldMapper.setFormData(options);
  },

  /**
   * Set options for autocomplete handler
   * @param {object} options - Autocomplete handler options
   */
  getOptions: function(options) {
    return {
      types: options.types || null
    };
  },
 
  /**
   * Autosuggest mobile height fix for iPhone landscape view
   */
  autoSuggestMobileFix: function() {
    var _self = this;
    var $searchFormContainer = _self.$parent.$el.find('.l-form-container');
    if (!$searchFormContainer.length) {
      $searchFormContainer = _self.$parent.$el.find('.m-search-tabs');
    }
    if( navigator.userAgent.length && /iPhone|iPad|iPod/i.test( navigator.userAgent ) && (this.$parent.responsiveUtils && this.$parent.responsiveUtils.isMobileOrTablet())) {
      _self.realHeight = _self.$parent.$el.height();
      _self.fixHeight = $searchFormContainer.find('.autocomplete-list').height() + 1000 + "px";
      
      $searchFormContainer.css("height", _self.fixHeight);
      _self.$parent.$el.find('.single-search').on('focus', function() {
        if(_self.$parent.responsiveUtils.isMobileOrTablet()) {
          $searchFormContainer.css("height", _self.realHeight + 'px');
        }
      });
      _self.$parent.$el.find('.single-search').on('blur', function() {
        if(_self.$parent.responsiveUtils.isMobileOrTablet()) {
          $searchFormContainer.css("height", _self.realHeight + 'px');
        }
      });
    }
  }
};

extend(AutocompleteHandler.prototype, AutocompleteHandlerAPI);

module.exports = AutocompleteHandler;