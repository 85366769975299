var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"searchButton") : stack1), depth0))
    + "\" data-analytics=\"{'location':'searchForm'}\" class=\"analytics-click js-is-roomkey-enabled  m-button m-button-primary\" type=\"submit\">\n    "
    + alias2(alias1(depth0, depth0))
    + "\n</button>";
},"useData":true});