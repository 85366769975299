var $ = require('jquery');
var _ = require('lodash');
var Popup = require('libs/popup');
var ComponentMapper = require('./component-mapper');

module.exports = ComponentMapper.extend({
  autoSuggestItem: '',
  options: {
    class: {
      turnedon: 'is-location-nearme',
      active: 'is-location-nearme-active'
    },
    inputs: {
      location: '.js-geoloc-target',
      lat: '.search-latitude',
      long: '.search-longitude',
      states: '.search-state',
      countries: '.search-country',
      dataValues: '.js-location-nearme-values'
    },
    inputMap: {
      '.js-geoloc-target': 'location',
      '.js-search-radius': 'radius',
      '.search-latitude': 'latitude',
      '.search-longitude': 'longitude',
      '.search-city': 'city',
      '.search-state': 'state',
      '.search-country': 'country',
      '.autosuggest-item-type': 'itemtype'
    }
  },
  init: function() {
    var _self = this;
    _self.subscribeDOMEvents();
    _self.$inputLocation = _self.$parent.$el.find(_self.options.inputs.location);
    _self.$dataValues = _self.$parent.$el.find(_self.options.inputs.dataValues);

    if (!$('body.lt-ie9').length && _self.$inputLocation.length && navigator.geolocation) {
      if(_self.autoComplete.permittedSiteId()){
        _self.autoSuggestItem = '<li class="autocomplete-list-header" role="menuitem">' +
        '<a class="ui-corner-all js-geoloc " data-index="0" tabindex="-1">' +
        '<span class="icon-current-location"></span>' +
        _self.$dataValues.data('title') + '</a></li>';
      }
    }
  },
  /**
  * This function is used to get current longitude and latitude position 
  */
  doLocalDetails: function() {
    var _self = this;
    navigator.geolocation.getCurrentPosition(
      _.bind(_self.onLocationFound, _self, _self.$dataValues[0]),
      _.bind(_self.onError, _self));
  },
  /**
  * This function is used to get address data on basis of latitude and longitude
  * @param {Object} target DOM hidden element that contains information in data attributes.
  * @param {Object} position result object from current location.
  */
  onLocationFound: function(target, position) {
    var _self = this;
    var place;
    var result;
    var latlng = {
      lat: parseFloat(position.coords.latitude), 
      lng: parseFloat(position.coords.longitude)
    };
    window.geocoder.geocode({'location': latlng}, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          place = results[0];
          result = place.address_components.map(function(item){
            var obj = {}, 
                placeType = item.types[0];
            obj[placeType] = item.long_name;
            if (placeType === 'administrative_area_level_1' || placeType === 'country') {
              obj[placeType] = item.short_name;
            }
            return obj;
          });
          _self.populateGeoLoc(result, position);
        }
      }
    });
  },
  /**
  * This function is called when error occurs on getting address data on basis of latitude and longitude
  * @param {Object} target DOM hidden element that contains information in data attributes.
  * @param {Object} errMsg error message returned from current location.
  */
  onError: function(target, errMsg) {
    var _self = this;
    var errorMsg = '<div class="l-padding-left l-padding-right mi-popover" id="no-current-loc-popup"><p class="l-center-align t-font-bold">' +
    _self.$dataValues.data('errhead') + '</p><p class="l-center-align">' +
    _self.$dataValues.data('errmessage') + '</p></div>';
    var errorPopup = new Popup({
      mainClass: 'm-modal t-modal-small',
      items: {src: errorMsg},
      alternateCloseMarkup: '<div class="l-padding-left l-padding-right "><button class="js-mfp-close l-width-max m-button-lightestGray">'+_self.$dataValues.data('error-popup-button-text')+'</button></div>',
      closeOnContentClick: true,
      open: true,
      ajaxLoaded: false
    });
    errorPopup.register();
  },
  /**
  * This function is used to populate address data
  * @param {Object} address_components address object containing details.
  * @param {Object} position result object from current location.
  */
  populateGeoLoc: function(address_components, position) {
    var _self = this;
    var $parentEl = _self.$parent.$el;
    var locationMap = {};
    var address_map = {};
    _.each(address_components, function(value) {
      _.each(value, function(val, key) {
        address_map[key] = val;
      }); 
    });
    locationMap.city = address_map.locality;
    locationMap.state = address_map.administrative_area_level_1;
    locationMap.country = address_map.country;
    locationMap.latitude = position.coords.latitude;
    locationMap.longitude = position.coords.longitude;
    locationMap.itemtype = 'hotelsnearme';
    locationMap.location = _self.$dataValues[0].getAttribute('data-neartext') + ' ' + locationMap.city + ', ' + locationMap.state + ', ' + locationMap.country;

    _.each(_self.options.inputMap, function(name, selector) {
      $parentEl.find(selector).val(locationMap[name]);
    });
    _self.$inputLocation.addClass(_self.options.class.active).focus();
  }
});
