var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "l-has-saved-hotels";
},"3":function(container,depth0,helpers,partials,data) {
    return "search-form-dest-page";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-target=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"searchFormContainer") : depth0)) != null ? lookupProperty(stack1,"target") : stack1), depth0))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "<div class=\"m-homepage-hsearch l-hsearch-cntnr l-hsearch-bottom t-bg-hsearch is-opened\">";
},"9":function(container,depth0,helpers,partials,data) {
    return " t-bg-standard-20";
},"11":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tile-modify-review-res-form m-homepage-hsearch l-hsearch-2 l-hsearch-takeover l-hsearch-cntnr l-hsearch-bottom "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":123},"end":{"line":1,"column":175}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDestPageSearchForm") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":176},"end":{"line":1,"column":232}}})) != null ? stack1 : "")
    + "\"  id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"searchFormContainer") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"searchFormContainer") : depth0)) != null ? lookupProperty(stack1,"target") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":267},"end":{"line":1,"column":352}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = container.invokePartial(require("./partials/errorMessages.hbs"),(depth0 != null ? lookupProperty(depth0,"errorMessages") : depth0),{"name":"partials/errorMessages","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " data-done-button-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"done") : stack1), depth0))
    + "\" data-id-prefix=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"idPrefix") : stack1), depth0))
    + "\" data-enable-google-places-api=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"enableGooglePlaceAutoSuggestion") : depth0), depth0))
    + "\" data-suppress-google-api=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"geoLocationNotCN") : depth0), depth0))
    + "\" data-autocomplete-supported-types="
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"autocompleteSupportedTypes") : stack1), depth0))
    + " data-enable-user-location=\""
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"enableUserLocation")), depth0))
    + "\">\n    <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"parentCssClass") : stack1), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./partials/searchHeaderTakeover.hbs"),depth0,{"name":"partials/searchHeaderTakeover","hash":{"hwsSearchForm":"true"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"isEditSearch") : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":136}}})) != null ? stack1 : "")
    + "\n            <div id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"parentId") : stack1), depth0))
    + "\" class=\"clearfix l-clear\">\n                <form id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"idPrefix") : stack1), depth0))
    + "_"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" method=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "\" action=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"action") : stack1), depth0))
    + "\" data-template=\"modify-review-change\" class=\"js-modify-review-res-form "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"cssClass") : stack1), depth0))
    + " "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"recentSearchClass") : depth0), depth0))
    + "\" data-default-endpoint=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"defaultEndpoint") : stack1), depth0))
    + "\" data-suggestion-endpoint-mapping="
    + alias3(__default(require("../../../../../../core/helpers/parseJson.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"suggestionEndpointMapping") : stack1),{"name":"parseJson","hash":{},"data":data,"loc":{"start":{"line":6,"column":317},"end":{"line":6,"column":378}}}))
    + " data-is-group-search-applicable=\""
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isGroupSearchApplicable")), depth0))
    + "\" data-group-search-endpoint="
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"groupSubmitSearchURL")), depth0))
    + ">\n"
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"global") : stack1),{"name":"hiddenInputs","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"date") : stack1),{"name":"hiddenInputs","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"searchForm") : stack1),{"name":"hiddenInputs","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"location") : stack1),{"name":"hiddenInputs","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"availabilitySearch") : stack1),{"name":"hiddenInputs","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    <div class=\"l-l-col-9\">\n                        <div class=\"m-field-wrap l-l-col-6 homepage-search-labels clearfix l-clear"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromExternalSite") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":98},"end":{"line":14,"column":146}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./partials/dates.hbs"),depth0,{"name":"partials/dates","hash":{"rateListMenu":"true"},"data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                        <div id=\"viewBtn\" class=\"l-l-col-3 m-field-control m-field-control-find l-margin-top-five-quarters\">\n"
    + ((stack1 = container.invokePartial(require("./partials/submitButton.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"searchButton") : stack1),{"name":"partials/submitButton","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </form>\n            </div>\n        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"isEditSearch") : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":54}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n\n";
},"usePartial":true,"useData":true});