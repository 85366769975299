var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"showDatePicker") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":94,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"l-clear\">\n            <div class=\"calendar-title t-font-s t-line-height-m l-margin-bottom-quarter "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":88},"end":{"line":5,"column":137}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":139},"end":{"line":5,"column":233}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"redEyeDate") : stack1)) != null ? lookupProperty(stack1,"showRedEyeDate") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "            <input type=\"hidden\" id=\"calendar-reset-label\" value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"reset") : stack1), depth0))
    + "\" />\n            <input type=\"hidden\" id=\"date-reset-flag\" value=\"false\" />\n            <input type=\"hidden\" id=\"checkin-reset-date\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":13,"column":64},"end":{"line":13,"column":160}}})) != null ? stack1 : "")
    + "\" />\n            <input type=\"hidden\" id=\"checkout-reset-date\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":14,"column":65},"end":{"line":14,"column":163}}})) != null ? stack1 : "")
    + "\" />            \n            <div class=\"m-field-control m-date-picker l-date-picker add-segment-datepicker "
    + ((stack1 = __default(require("../helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"isError") : stack1),"or",((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"isError") : stack1),{"name":"if-cond","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":91},"end":{"line":15,"column":174}}})) != null ? stack1 : "")
    + "\" data-trigger=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"validateDates") : stack1), depth0))
    + "\" data-is-non-dated-search="
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"isNonDatedSearch") : depth0), depth0))
    + ">\n                <span class=\"field-title "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isErrorMessageHidden") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":41},"end":{"line":16,"column":90}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"isError") : stack1),"or",((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"isError") : stack1),{"name":"if-cond","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":91},"end":{"line":16,"column":174}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = __default(require("../helpers/if-cond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"isError") : stack1),"or",((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"isError") : stack1),{"name":"if-cond","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":19,"column":32}}})) != null ? stack1 : "")
    + "                    "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? lookupProperty(stack1,"selectDates") : stack1), depth0))
    + "\n                </span>\n                <div class=\"js-datepick-container l-pos-rel is-flex-hidden t-singlefield\" data-check-in-label=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\" data-check-out-label=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\" data-takeover-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"stayDates") : stack1), depth0))
    + "\" data-country-code=\""
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"countryCode")), depth0))
    + "\">\n                    <div class=\"js-toggle-picker l-picker-toggle l-border-box js-moreoptions-expander-click l-display-block l-h-field l-padding-left-half t-no-decor t-blk\" data-target=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"target") : stack1), depth0))
    + "\">\n                        <div class=\"is-hidden\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"fromToDate") : stack1), depth0))
    + "</div>\n                        <input data-invalidmsg=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"invalidDateText") : stack1), depth0))
    + "\" size=\"15\" name=\"fromToDate\" type=\"text\" id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_hotel-fromToDate"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":159},"end":{"line":25,"column":207}}})) != null ? stack1 : "")
    + "\" maxlength=\"20\" aria-label=\"hotel-from-date\" data-min=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.program(29, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":25,"column":263},"end":{"line":25,"column":355}}})) != null ? stack1 : "")
    + "\" data-max=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.program(33, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":25,"column":367},"end":{"line":25,"column":460}}})) != null ? stack1 : "")
    + "\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":25,"column":469},"end":{"line":25,"column":565}}})) != null ? stack1 : "")
    + "\" data-value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":25,"column":579},"end":{"line":25,"column":675}}})) != null ? stack1 : "")
    + "\" class=\"js-date-from-to is-hidden\" autocomplete=\"off\" />\n\n                        <input type=\"hidden\" name=\"fromDate\" data-value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":27,"column":73},"end":{"line":27,"column":169}}})) != null ? stack1 : "")
    + "\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":27,"column":178},"end":{"line":27,"column":274}}})) != null ? stack1 : "")
    + "\" class=\"js-submit-from\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"lastInventoryDate") : stack1)) != null ? lookupProperty(stack1,"showLastInventoryDate") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":32,"column":31}}})) != null ? stack1 : "")
    + "\n                        <input type=\"hidden\" name=\"toDate\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":34,"column":66},"end":{"line":34,"column":164}}})) != null ? stack1 : "")
    + "\" class=\"js-submit-to\" />\n\n                        <input type=\"hidden\" name=\"flexibleDateSearch\" value=\"false\">\n\n                        <div class=\"is-hidden\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"ada") : stack1)) != null ? lookupProperty(stack1,"flexibleDateSearch") : stack1), depth0))
    + "</div>\n                        <input type=\"checkbox\" name=\"flexibleDateSearch\" value=\"true\" id=\"flexible-date-search\" class=\"js-flex-check is-hidden\" aria-label=\"search-flexible-date\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"selected") : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":178},"end":{"line":39,"column":237}}})) != null ? stack1 : "")
    + ">\n\n                        <input class=\"l-date l-no-ie-clear js-date-from l-h-field-input l-no-outline "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"isError") : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":101},"end":{"line":41,"column":145}}})) != null ? stack1 : "")
    + "\" size=\"15\" type=\"text\" placeholder=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":41,"column":214},"end":{"line":41,"column":310}}})) != null ? stack1 : "")
    + "\" aria-label=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\" id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_hotel-fromDate"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":391},"end":{"line":41,"column":439}}})) != null ? stack1 : "")
    + "\" maxlength=\"17\" autocomplete=\"off\" />\n\n                        <label for=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_hotel-fromDate"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":74},"end":{"line":43,"column":122}}})) != null ? stack1 : "")
    + "\" class=\"l-date is-hidden-label\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</label>\n\n                        <div class=\"l-date is-hidden-label\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</div>\n\n                        <span class=\"l-date is-hidden-label\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"dateFormatPatternForDisplay") : stack1), depth0))
    + "</span>\n\n                        <span class=\"l-flex l-flex-display js-flex-display\" data-label=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"flexibleInLabel") : stack1), depth0))
    + "\"></span>\n\n                        <span class=\"l-date\">-</span>\n                        <input class=\"l-date l-no-ie-clear js-date-to js-flex-out l-h-field-input l-no-outline "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"isError") : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":111},"end":{"line":52,"column":156}}})) != null ? stack1 : "")
    + "\" size=\"15\" type=\"text\" placeholder=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":52,"column":226},"end":{"line":52,"column":324}}})) != null ? stack1 : "")
    + "\" aria-label=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\" id=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_hotel-toDate"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":404},"end":{"line":52,"column":452}}})) != null ? stack1 : "")
    + "\" maxlength=\"17\" autocomplete=\"off\"/>\n\n                        <label for=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"form"))) && lookupProperty(stack1,"idPrefix")), depth0))
    + "_hotel-toDate"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":72},"end":{"line":54,"column":120}}})) != null ? stack1 : "")
    + "\" class=\"l-date is-hidden-label\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</label>\n\n                        <div class=\"l-date is-hidden-label\" >"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</div>\n                        <span class=\"l-date is-hidden-label\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"dateFormatPatternForDisplay") : stack1), depth0))
    + "</span>\n                        <span class=\"l-toggle-active\"></span>\n                        <span class=\"l-float-right t-icon t-icon-arrow-down l-middle-valign t-font-xs m-icon-trigger\"></span>\n                    </div>\n                     <div class=\"js-date-pick-container\" data-options='{\"flexdates\":\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\", \"specificdates\":\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"specificDatesLabel") : stack1), depth0))
    + "\", \"flexibleInMonthTitleInitial\":\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"flexibleInMonthTitleInitial") : stack1), depth0))
    + "\", \"flexibleNight\":\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"lengthOfStay") : stack1), depth0))
    + "\", \"monthFullList\":\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"monthFullList") : stack1), depth0))
    + "\", \"monthShortList\":\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"monthShortList") : stack1), depth0))
    + "\", \"weekdayFullList\":\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"weekdayFullList") : stack1), depth0))
    + "\", \"weekdayShortList\":\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"weekdayShortList") : stack1), depth0))
    + "\", \"nightLabel\":\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"nightLabel") : stack1), depth0))
    + "\", \"nightsLabel\":\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"nightsLabel") : stack1), depth0))
    + "\", \"clearText\":\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"clear") : stack1), depth0))
    + "\", \"doneText\":\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"done") : stack1), depth0))
    + "\"}'>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowMultipleDatePicker") : depth0),{"name":"unless","hash":{},"fn":container.program(42, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":24},"end":{"line":89,"column":35}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "field-title";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"labels"))) && lookupProperty(stack1,"datesLabel")), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"datesLabel") : stack1), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"hidden\" id=\"red-eye\" class=\"js-red-eye\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"redEyeDate") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" />\n                <input type=\"hidden\" id=\"show-expanded-datepicker\" />\n                <input type=\"hidden\" id=\"addsegment-datepicker\" />\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"datesData") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"datesData") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0));
},"19":function(container,depth0,helpers,partials,data) {
    return " is-error";
},"21":function(container,depth0,helpers,partials,data) {
    return "l-display-none";
},"23":function(container,depth0,helpers,partials,data) {
    return "                        <i class=\"icon icon-error t-form-error\"></i>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((data && lookupProperty(data,"index")), depth0));
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"datesData") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"min") : stack1), depth0));
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"min") : stack1), depth0));
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"datesData") : depth0)) != null ? lookupProperty(stack1,"checkout") : stack1)) != null ? lookupProperty(stack1,"max") : stack1), depth0));
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"checkin") : stack1)) != null ? lookupProperty(stack1,"max") : stack1), depth0));
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"lastInventoryDate") : stack1),{"name":"with","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":31,"column":37}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <input type=\"hidden\" name=\"inventoryDate\" data-inventory='{\"label\":\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\",\"value\":\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"}' class=\"inventory-date\"/>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"40":function(container,depth0,helpers,partials,data) {
    return "is-error";
},"42":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"m-flex-frame-wrapper\">\n                                <div class=\"m-flex-frame\">\n                                    <span class=\"l-center-align t-font-bold l-em-reset l-padding-bottom travelDates\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"travelLabel") : stack1), depth0))
    + "</span>\n                                    <div class=\"l-flex-months\">\n                                        <div class=\"l-flex-months-holder js-flex-months-holder\"></div>\n                                    </div>\n                                    <div class=\"l-center-align t-font-bold l-em-reset l-padding-top how-many-nights-label\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"howManyNightsLabel") : stack1), depth0))
    + "</div>\n                                    <div class=\"l-stepper l-overflow-hidden l-margin-zero-auto\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"lastInventoryDate") : stack1)) != null ? lookupProperty(stack1,"showLastInventoryDate") : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":40},"end":{"line":75,"column":47}}})) != null ? stack1 : "")
    + "                                        <input type=\"text\" aria-label=\"number-of-nights\" id=\"length-of-stay-input\" class=\"js-stepper-input l-stepper-input l-no-ie-clear\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"lengthOfStay") : stack1), depth0))
    + "\" maxlength=\"5\">\n                                        <a href=\"javascript:void(0)\" class=\"js-flex-stepper l-minus js-minus t-icon-minus\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"remove") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"nightsLabel") : stack1), depth0))
    + "\"></a>\n                                        <a href=\"javascript:void(0)\" class=\"js-flex-stepper l-plus js-plus t-icon-plus\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"add") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"nightsLabel") : stack1), depth0))
    + "\"></a>\n                                        <div class=\"is-hidden\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"howManyNightsLabel") : stack1), depth0))
    + "</div>\n\n                                        <select name=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"selectName") : stack1), depth0))
    + "\" aria-label=\"days-of-stay\" id=\"length-of-stay\" class=\"js-flex-input is-hidden\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"options") : stack1),{"name":"each","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":44},"end":{"line":84,"column":53}}})) != null ? stack1 : "")
    + "                                        </select>\n                                    </div>\n                                </div>\n                            </div>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"dates") : depth0)) != null ? lookupProperty(stack1,"lastInventoryDate") : stack1),{"name":"with","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":44},"end":{"line":74,"column":53}}})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <input type=\"hidden\" name=\"inventoryDate\" data-inventory='{\"label\":\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\",\"value\":\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"}' class=\"inventory-date\"/>\n";
},"46":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../helpers/if-cond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"label") : depth0),"eq",((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"dates") : depths[1])) != null ? lookupProperty(stack1,"flexibleDate") : stack1)) != null ? lookupProperty(stack1,"lengthOfStay") : stack1),{"name":"if-cond","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":70},"end":{"line":83,"column":161}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "</option>\n";
},"47":function(container,depth0,helpers,partials,data) {
    return " selected=\"selected\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dates") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":95,"column":7}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});