var SearchComponent = require('./search-components');
var PageTakeover = require('libs/pageTakeover');
var Constants = require('search_libs/constants');
var Config = require('search_libs/config');
var $ = require('jquery');
var ScrollBarHandler = require('libs/scrollbar');

var SpecialRatesHandler = SearchComponent.extend({
  events: {
    'click .js-toggle': 'toggleSpecialRates',
    'click .js-special-rates-header': 'openDropDown',
    'click .js-h-toggle': 'codeInputToggle',
    'click .l-back-icon': 'closeSpecialRatesSection',
    'blur .radio-secondary-input': 'onBlurEvent',
    'click .done_button': 'hideTakeoverScreen'
  },
  options: {
    dropdownTrigger: '.m-icon-trigger',
    dropdownActive: 't-icon-arrow-up',
    dropdownInActive: 't-icon-arrow-down',
    scrollBarTarget: '.special-rates-section .l-spreacial-rate-scroll-cont'
  },
  init: function() {
    var _self = this;
    var specialRatesHeader = _self.$el.find('.l-h-toggle-text');
    specialRatesHeader.html(specialRatesHeader.data('value'));
    this.subscribeDOMEvents();
    this.$dropdownIcon = this.$el.find(this.options.dropdownTrigger);

    this.$el.find('.js-h-toggle').each(function() {
      if ($(this).find('input[type="radio"]').prop('checked')) {
        $(this).parent().addClass('is-checked');
      }
    });

    this.createTakeOverScreen(this.$el.find('.special-rates-section'), this.$el.find('.special-rates-section').data('takeover-label'), this.$parent.$el.data('done-button-label'));
    $(document).on('click', _.bind(this.closeSpecialRatesSection, this));
    this.$el.parents('.mfp-content').on('click', _.bind(this.closeSpecialRatesSection, this));

    this.$parent.$el.find('input[type!=\'hidden\'], a, div[tabindex = \'0\']').on('focusin', _.bind(function(evt) {
      this.closeSpecialRatesSection(evt);
    }, this));

    this.initScroller();
  },
  createTakeOverScreen: function($el, label, buttonLabel) {
    if (this.suppressTakeOver) {
      this.takeoverScreen = {
        isActive:false
      }
      return;
    }
    var _self = this;
    var TakeOverConfig = Config.TAKEOVER_VARIATION[this.$parent.getTakeOverVariation()];
    var takeoverVariation = this.$parent.getTakeOverVariation();
    this.takeoverScreen = PageTakeover.create({
      $el: $el,
      headerLabel: label,
      transition_speed: Constants.SEARCH_FORM.TAKE_OVER_SPEED,
      keepOverflowHidden: TakeOverConfig.KEEP_OVERFLOW_HIDDEN,
      isNotRetainDisplayAttribute: true,
      customHeader: function() {
        this.$el.prepend('<div class="page-take-over-header"><span class="back-btn"></span>'+this.headerLabel+'<a class="done_button" href="#">'+buttonLabel+'</a></div>');
        if (!_self.addCustomHeaderStyle) {
          this.$el.find('.page-take-over-header').css(Config.TAKEOVER_HEADER_STYLING);
        }
      },
      beforeShow: function() {
        var $this = _self.$el.find('.js-toggle');
        $this.addClass('is-active');
        $this.next('.js-toggle-container').slideToggle();
        if (takeoverVariation === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS) {
          _self.$el.closest('.mfp-wrap').css('overflow', 'hidden');
        }
      },
      afterHide: function() {
        _self.$el.find('.js-toggle-container').css('display', 'none');
        _self.$el.find('.js-toggle').removeClass('is-active');
        _self.$el.find('.m-dropdown-trigger').removeClass('dropdown-is-active');
        if (takeoverVariation === Constants.SEARCH_FORM.VARIATIONS.CHECK_AVAILABILITY_HWS) {
          _self.$el.closest('.mfp-wrap').css({'overflow-x': 'hidden','overflow-y': 'scroll'});
        }
      }
    });
  },
  hideTakeoverScreen: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.takeoverScreen.hide();
  },
  addTakeover: function() {
    if (this.$parent.$el.find('.l-h-toggle').hasClass('is-active') && !this.suppressTakeOver) {
      this.$el.find('.page-take-over-header').show();
      this.$el.find('.js-toggle-container').toggle();
      this.takeoverScreen.reload();
      this.$el.css('z-index', 'auto');
    }
  },
  removeTakeover: function() {
    if (this.$parent.$el.find('.l-h-toggle').hasClass('is-active') && !this.suppressTakeOver) {
      this.takeoverScreen.remove();
      this.$el.find('.special-rates-section').css({'padding': '0','height':'auto'});
      this.$el.find('.code-container').css('padding', '0 20px 10px 20px');
      this.$el.css('z-index', '1');
      this.$el.find('.js-toggle').trigger('click');
    }
  },
  closeSpecialRatesSection: function(e) {
    var _self = this;
    var container = _self.$el;
    if (!container.is(e.target) && (container.has(e.target).length === 0 || this.$el.find(e.target).hasClass('l-back-icon')) ) {
      _self.$el.find('.js-toggle-container').slideUp();
      container.find('.js-toggle').removeClass('is-active');
      _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
    }
  },
  toggleSpecialRates: function(e) {
    e.preventDefault();
    var _self = this;
    var $this = this.$el.find(e.currentTarget);
    if (this.$parent.responsiveUtils.isMobileOrTablet() && !this.suppressTakeOver) {
      this.takeoverScreen.init();
    }else {
      $this.toggleClass('is-active');
      if ($this.hasClass('is-active')) {
        _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
      } else {
        _self.$dropdownIcon.addClass(_self.options.dropdownActive).removeClass(_self.options.dropdownInActive);
      }
      $this.next('.js-toggle-container').slideToggle();
    }
  },
  openDropDown: function(e) {
    var $this = this.$el.find(e.currentTarget);
    var _self =  this;
    e.preventDefault();
    if ($this.hasClass('is-active')) {
      _self.$dropdownIcon.addClass(_self.options.dropdownActive).removeClass(_self.options.dropdownInActive);
    } else {
      _self.$dropdownIcon.removeClass(_self.options.dropdownActive).addClass(_self.options.dropdownInActive);
    }
  },
  /**
  * Accepts callback onItemClicked
  **/
  codeInputToggle: function(e) {
    var $this = $(e.currentTarget);
    var _self = this;
    var $checkbox = $this.find('input[type="radio"]');
    if ($checkbox.length > 0) {
      e.preventDefault();
      $checkbox.prop('checked', true);

      $this.parents().find('.l-h-toggle-cont .is-checked').removeClass('is-checked');
      $this.parent().addClass('is-checked');
      $this.parents().find('.l-h-toggle-cont .special-rate-code').addClass('is-hidden').hide();
      var $toggleCheckboxParent = this.$parent.$el.find('.js-h-toggle-checkbox').parents();
      $toggleCheckboxParent.find('.l-userewards-checkmark').removeClass('t-icon-checkmark');
      this.$parent.$el.find('.js-h-toggle-checkbox').prop('checked', false).attr('checked', false);

      if(this.onItemClicked){
        this.onItemClicked($this);
      }

      if ($checkbox.val() == 'corp' || $checkbox.val() == 'group') {
        if ($checkbox.prop('checked')) {
          $this.parents(".special-rates-section").find('.'+$checkbox.val()+'-code').show().removeClass('is-hidden');
          $this.parents(".special-rates-section").find('.'+$checkbox.val()+'-code').find('input:text').focus();
        } else {
          $this.parents(".special-rates-section").find('.'+$checkbox.val()+'-code').addClass('is-hidden');
        }
      }
       else {
        this.closeToggle($checkbox, $checkbox.val());
        this.$el.find('.special-rate-code').addClass('is-hidden').hide();
        _.defer(function(){
          _self.$el.find('.js-special-rates-header').focus().attr('tabIndex','2');
        })
      }
    }
    _self.initScroller();
  },
  onBlurEvent: function(e) {
    if (e) {
      if(this.$el.find(e.relatedTarget).hasClass('js-h-toggle')) {
        return false;
      }
    }
    var $checkbox = this.$el.find('.l-h-toggle-opt').find('#' + this.$parent.idPrefix + '_' + this.$el.find(e.currentTarget).attr('data-special-rate-type'));
    this.closeToggle($checkbox, $checkbox.val());
  },
  closeToggle: function($obj, objVal, changeWithoutToggle) {
    var $toggleHeader = this.$el.find('.l-h-toggle');
    var $specialRates = this.$el.find('.special-rates-section');

    var $toggleHeaderText = $toggleHeader.find('.l-h-toggle-text');
    if (objVal == 'none') {
      $toggleHeaderText.html($toggleHeaderText.data('defaultheader'));
      this.$el.find('.js-h-toggle-checkbox').parents('ul').find('.radio-secondary-input').val('');
      if (this.$el.find('.js-h-toggle-checkbox').prop('checked', true)) {
        $toggleHeaderText.html($toggleHeaderText.data('defaultheader'));
      }

      if (!this.$parent.responsiveUtils.isMobileOrTablet()) {
        if (!changeWithoutToggle) {
          $toggleHeader.click();
        } else {
          $specialRates.hide();
          this.$el.find('.js-special-rates-header').removeClass('is-active');
        }
      }
    }
    else {
      if (objVal == 'corp' || objVal === 'group'){
        if (this.$el.find('.special-rate-code').find('input[data-special-rate-type=' + objVal + ']').val() == '') {
            if (!this.$parent.responsiveUtils.isMobileOrTablet()) {
              $obj.parents('.l-h-toggle-cont').find('.special-rate-code').hide('fast');
              var $searchClustercodeParent = this.$el.find('.search-clusterCode-none').prop('checked', true).parents('.l-h-toggle-opt');
              $searchClustercodeParent.addClass('is-checked');
              $searchClustercodeParent.parent().find('.is-checked').not($searchClustercodeParent).removeClass('is-checked');
              $toggleHeaderText.html($toggleHeaderText.data('defaultheader'));
            }
          }
        else {
          $toggleHeaderText.html(this.$el.find('.special-rate-code').find('input[data-special-rate-type=' + objVal + ']').val());
        }
      }
      else {
        $toggleHeaderText.html($obj.siblings('legend').find('.cluster-code-label').html().trim());
      }
      if (!this.$parent.responsiveUtils.isMobileOrTablet()) {
        if (objVal !== 'corp' && objVal !== 'group'){
          $toggleHeader.click();
        }
      }
    }
  },
  initScroller: function() {
    var _self = this;
    if (!this.scrollbar && !this.$parent.responsiveUtils.isMobileOrTablet()) {
      this.scrollbar = ScrollBarHandler.create({
        $el: this.$el,
        $scrollBarTarget: this.$el.find(_self.options.scrollBarTarget),
        $className : "l-special-rate-max-height scrollbar-outer"
      });
      this.scrollbar.init();
    }
  },
});

module.exports = SpecialRatesHandler;