var Constants = require('libs/constants');

Constants.FILTER = {
  ADD: 'add',
  REMOVE: 'remove',
  CLEAR_ALL: 'clearAll',
  RESET: 'reset',
  APPLY: 'apply',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  MODIFIED: 'modified',
  CURRENT: 'current',
  ERROR_REDIRECT_URI: 'error-redirect-uri',
  TAKE_OVER_SPEED: 500
};

Constants.SEARCH_FORM = {
	TAKE_OVER_SPEED: 500,
	VARIATIONS: {
		ADVANCED_SEARCH: 'ADVANCED_SEARCH',
		HOMEPAGE: 'HOMEPAGE',
		EDIT_SEARCH: 'EDIT_SEARCH',
		RATE_LIST_EDIT: 'RATE_LIST_EDIT',
		MODIFY_RES: 'MODIFY_RES',
		HEADER: 'HEADER',
		AVAILABILITY_SEARCH: 'AVAILABILITY_SEARCH',
		DEALS_AVAILABILITY_SEARCH: 'DEALS_AVAILABILITY_SEARCH',
    CHECK_AVAILABILITY_HWS: 'CHECK_AVAILABILITY_HWS',
    ASSOCIATE_SEARCH: 'ASSOCIATE_SEARCH',
	}
};

Constants.PROPERTY_RECORD = {
  TAKE_OVER_SPEED: 500,
  NAVIGATION_SPEED: 500,
  SWIPE_THRESHOLD_DISTANCE: 100,  //required min distance traveled to be considered swipe
  MOBILE_WIDTH: 480,
  MARKER_PIN_BLUE: 'blue',
  MARKER_PIN_BLACK: 'black',
  MAP_INFO_WINDOW_IDLE_TIME: 5000,
  HOTEL_QUICK_VIEW_ANIMATION_SPEED: 300
};

Constants.KEY_CODE = {
  ENTER_KEY: 13,
  ESC_KEY: 27,
  UP_KEY: 38,
  DOWN_KEY: 40
};

Constants.SCREEN_WIDTH = {
  DATE_SHORT_FORMAT_START_WIDTH: 0,
  DATE_SHORT_FORMAT_END_WIDTH: 0,
  DATE_SHORT_FORMAT_END_WIDTH_HWS: 1140
};

module.exports = Constants;
