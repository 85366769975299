/**
 * Returns mapper object for specified form id
 * @param {string} formId - Search form Id
 * @returns {Object} Form mapper object
 */
function get(formId) {
  var mapper;

  switch (formId) {
    case 'deals-search':
      mapper = {
        'city': 'city',
        'state': 'stateProvinceShort',
        'country': 'countryShort'
      };
      break;
    case 'existing-reservation-form':
      mapper = {
        'cityName': 'city',
        'cityCodes': 'cityCodes',
        'regionCode': 'stateProvinceShort',
        'countryCode': 'countryShort'
      };
      break;
    default:
      mapper = {
        'destinationAddress.name': 'name',
        'destinationAddress.latitude': 'latitude', 
        'destinationAddress.longitude': 'longitude',
        'destinationAddress.city': 'city',
        'cityCodes': 'cityCodes',
        'destinationAddress.stateProvince': 'stateProvince',
        'destinationAddress.country': 'country',
        'destinationAddress.mainText': 'mainText',
        'destinationAddress.secondaryText': 'secondaryText',
        'destinationAddress.placeId': 'placeId',
        'showAddressPin': 'showAddressPin',
        'destinationAddress.website': 'website',
        'destinationAddress.address': 'address',
        'destinationAddress.locality': 'locality',
        'destinationAddress.postalCode': 'postalcode',
        'destinationAddress.types': 'types',
        'destinationAddress.stateProvinceShort': 'stateProvinceShort',
        'destinationAddress.countryShort': 'countryShort'
      };
  }

  return mapper;
}


module.exports = {
  get: get
};