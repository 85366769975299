var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "l-has-saved-hotels";
},"3":function(container,depth0,helpers,partials,data) {
    return "search-form-dest-page";
},"5":function(container,depth0,helpers,partials,data) {
    return " animate-search-form ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(depth0, depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":889},"end":{"line":1,"column":918}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "#";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"t-more-search-options l-xs-col-4 l-xl-col-12 is-hidden\">\n"
    + ((stack1 = container.invokePartial(require("./partials/roomsGuests.hbs"),depth0,{"name":"partials/roomsGuests","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"specialRates") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":27,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useRewards") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":30,"column":23}}})) != null ? stack1 : "")
    + "\n                <div class=\"m-field-control l-xs-col-4 l-mml-col-9-split l-mml-last-col-split l-xl-col-4 l-xl-last-col l-hsearch-find l-find-bottom js-hform-fields\">\n"
    + ((stack1 = container.invokePartial(require("./partials/submitButton.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"searchButton") : stack1),{"name":"partials/submitButton","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/specialRates.hbs"),depth0,{"name":"partials/specialRates","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/useRewards.hbs"),depth0,{"name":"partials/useRewards","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"l-margin-top t-saved-hotel is-hidden-xs is-visible-xl\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"savedProperties") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":49,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n";
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    \n                        <span class=\"field-title\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"labels") : stack1)) != null ? lookupProperty(stack1,"savedHotels") : stack1), depth0))
    + " : </span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"savedProperties") : stack1),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":44,"column":33}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"more") : stack1),{"name":"with","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":47,"column":33}}})) != null ? stack1 : "")
    + "                    \n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"analytics-click\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"propertyURL") : depth0), depth0))
    + "\" data-analytics='"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"data-analytics") : depths[1]), depth0))
    + "' title=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"labels") : depths[1])) != null ? lookupProperty(stack1,"savedHotels") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"propertyName") : depth0), depth0))
    + "</a>&nbsp;|&nbsp;\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"analytics-click\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"href") : depth0), depth0))
    + "\" data-analytics='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"data-analytics") : depth0), depth0))
    + "' >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tile-hsearch-homepage m-homepage-hsearch l-hsearch-2 l-hsearch-takeover l-hsearch-cntnr l-hsearch-bottom "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":117},"end":{"line":1,"column":169}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDestPageSearchForm") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":170},"end":{"line":1,"column":226}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"autoCompleteHintTextList")),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":227},"end":{"line":1,"column":293}}})) != null ? stack1 : "")
    + "\" data-roomkeyflag=\""
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"showRoomKeyPopup")), depth0))
    + "\" data-done-button-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"done") : stack1), depth0))
    + "\" data-id-prefix=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"idPrefix") : stack1), depth0))
    + "\" data-suppress-google-api=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"geoLocationNotCN") : depth0), depth0))
    + "\" "
    + ((stack1 = container.invokePartial(require("./partials/errorMessages.hbs"),(depth0 != null ? lookupProperty(depth0,"errorMessages") : depth0),{"name":"partials/errorMessages","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " data-enable-google-places-api=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"enableGooglePlaceAutoSuggestion") : depth0), depth0))
    + "\" data-autocomplete-supported-types="
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"autocompleteSupportedTypes") : stack1), depth0))
    + " data-radius-oriented-countries="
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"countriesSupportingRadiusSearch") : stack1), depth0))
    + " data-custom-auto-suggest="
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"enableSmartDestinationAutoSuggestion") : depth0), depth0))
    + "  data-placeholder-list=\""
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"autoCompleteHintTextList")),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":841},"end":{"line":1,"column":927}}})) != null ? stack1 : "")
    + "\" data-enable-hint-text="
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"enableHintText")), depth0))
    + " data-enable-user-location=\""
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"enableUserLocation")), depth0))
    + "\">\n    <div class=\"clearfix homepage-search-form l-form-container l-clear\">\n        <a name=\"search-content\"></a>\n        <form action=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"action") : stack1), depth0))
    + "\" id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" novalidate=\"novalidate\" method=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "\" class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"cssClass") : stack1), depth0))
    + " \" data-default-endpoint=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"defaultEndpoint") : stack1), depth0))
    + "\" data-suggestion-endpoint-mapping="
    + alias3(__default(require("../../../../../../core/helpers/parseJson.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"location") : depth0)) != null ? lookupProperty(stack1,"autocomplete") : stack1)) != null ? lookupProperty(stack1,"suggestionEndpointMapping") : stack1),{"name":"parseJson","hash":{},"data":data,"loc":{"start":{"line":4,"column":231},"end":{"line":4,"column":292}}}))
    + " data-is-group-search-applicable=\""
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isGroupSearchApplicable")), depth0))
    + "\" data-group-search-endpoint="
    + alias3(alias2(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"groupSubmitSearchURL")), depth0))
    + ">\n\n"
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"global") : stack1),{"name":"hiddenInputs","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../../../../core/partials/hiddenInputs.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"hiddenInputs") : depth0)) != null ? lookupProperty(stack1,"availabilitySearch") : stack1),{"name":"hiddenInputs","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/searchHeaderTakeover.hbs"),depth0,{"name":"partials/searchHeaderTakeover","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div class=\"l-form-group m-field-wrap l-hsearch-labels clearfix\">\n"
    + ((stack1 = container.invokePartial(require("./partials/location.hbs"),depth0,{"name":"partials/location","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/dates.hbs"),depth0,{"name":"partials/dates","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"l-xs-col-4 l-xl-col-4 l-xl-last-col l-hsearch-find l-find-top js-hform-fields\">\n                    \n"
    + ((stack1 = container.invokePartial(require("./partials/submitButton.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"labels") : depth0)) != null ? lookupProperty(stack1,"searchButton") : stack1),{"name":"partials/submitButton","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? lookupProperty(stack1,"collapsible") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":36,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"savedHotels") : depth0)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":51,"column":19}}})) != null ? stack1 : "")
    + "          </div>  \n        </form>\n    </div>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true});