var AriesComponent = require('libs/aries-component');
var $ = require('jquery');

var validateMarshaHandler = AriesComponent.extend({
  validateMarshaCode: function(params, context, successHandler, errorHandler, successHandlerAttr, errorHandlerAttr){
      _.bind(successHandler, context)(successHandlerAttr);
    }
});

module.exports = validateMarshaHandler;
