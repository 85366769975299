var Constants = require('../libs/constants'); 
 
/**
  * @param {Object, Object,value} selected date and max date possible and required max to date possible
  * @returns {Object} date which is "max to date possible" days more than selected date
*/

module.exports = function(selectedFromDate,maxToDate,reqDiffDays) {
    var diffDays = parseInt((maxToDate - selectedFromDate) / (Constants.DATES.MILLISECONDS * Constants.DATES.MINUTE_AND_HOUR * Constants.DATES.MINUTE_AND_HOUR * Constants.DATES.HOURS_IN_DAY)),
    maxDate;
    if(diffDays < reqDiffDays) {
      maxDate = new Date(maxToDate);
    } else {
      maxDate = new Date(selectedFromDate);
      maxDate.setDate(selectedFromDate.getDate() + (reqDiffDays-1));
    }
    return maxDate;
  }