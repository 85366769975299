var $ = require('jquery');
var _ = require('lodash');
var ComponentMapper = require('./component-mapper');
var GeoLocationHandler = require('./geolocation-handler');
var ClearInputHandler = require('./clear-input-handler');
var localStorage = require('local-storage-fallback');
var PubSub = require('libs/pub-sub');
var Cookies = require('libs/cookies');
var GoogleApi = require('libs/google-api');

var properties = {
  listHtml:'',
  listCount:0,
  timeoutId: 0,
  permittedLocales: [],  
  recentSearch: {
    max:3,
    list:[],
    inputClass:'js-recent-search'
  },
  options: {
    searchType: '.js-searchtype',
    addressSearch: 'NearAddress',
    isPropertyCodeFlag: '.search-property-code-flag',
    inputs: {
      radius:'.js-search-radius',
      latitude:'.search-latitude',
      longitude:'.search-longitude',
      cityPop:'.js-search-city-pop',
      cityPopDensity:'.js-search-city-pop-density',
      cityDisplay:'.js-search-city-display',
      city:'.search-city',
      state:'.search-state',
      country:'.search-country',
      countryname: '.search-country-name',
      poiname:'.search-poiname',
      airport:'.search-airport',
      airportName: '.search-airport-name',
      autoSuggestOmni:'.js-autosuggest-item-type',
      singleSearchAuto:'.single-search-autosuggest',
      stateProvince: '.search-state-province',
      propertyName: '.search-property-name',
      propertyCode: '.search-property-code',
      primaryDescription: '.search-primary-description',
      secondaryDescription: '.search-secondary-description',
      placeId: '.search-place-id',
      address: '.destination-address',
      postalCode: '.destination-postal-code',
      types: '.destination-types',
      website: '.destination-website',
      showAddressPin: '.show-address-pin'
    },
    categoryParams : {
      label: ''
    },
    autoCompleteList: '.js-auto-complete-list',
    maxCategory: 10
  },
  templates: {
    autoCompleteCustom: _.template(
      '<li class="autocomplete-listitem <% if( index == 0 ) { %> autocomplete-listitem-active <% } %>" role="menuitem">' +
      '<a data-index="<%=index%>" data-place-id="<%=placeId%>" data-primary-description="<%=primaryDescription%>" data-secondary-description="<%=secondaryDescription%>">' +
      '<strong><%=primaryDescription%></strong><% if(typeof secondaryDescription !== "undefined" && secondaryDescription) { %>, <%=secondaryDescription%> <%}%></a>' +
      '</li>'),
    autoListHeader: _.template(
      '<li class="autocomplete-list-header">' +
      '<span class="t-icon-search autocomplete-search-icon"></span><strong><%=category%></strong>' +
      '</li>'),
    autoListMsg: _.template('<li class="t-autosuggest-msg t-font-semi-bold"><%=label%></li>'),
    autoCompleteRecent: _.template(
      '<li class="autocomplete-listitem <% if( index == 0 ) { %> autocomplete-listitem-active <% } %>" role="menuitem">' +
      '<a data-index="<%=index%>" data-type="<%=type%>"  data-searchtype="<%=searchtype%>" <% if(typeof city !== "undefined") { %> data-city="<%=city%>"<%}%><% if(typeof state !== "undefined") { %> data-state="<%=state%>"<%}%>' +
      '<% if(typeof country !== "undefined") { %> data-countrycode="<%=country%>"<%}%><% if(typeof countryname !== "undefined") { %> data-country="<%=countryname%>"<%}%> <% if(typeof airportname !== "undefined") { %> data-airport-name="<%=airportname%>"<%}%><% if(typeof airportcode !== "undefined") { %> data-airport-code="<%=airportcode%>"<%}%>' +
      '<% if(typeof poiname !== "undefined") { %> data-poi-name="<%=poiname%>"<%}%><% if(typeof geocode !== "undefined") { %> data-geo="<%=geocode%>"<%}%>'+
      '<% if(typeof citypopulation !== "undefined") { %> data-city-population="<%=citypopulation%>"<%}%>' +
      '<% if(typeof primaryDescription !== "undefined") { %> data-primary-description="<%=primaryDescription%>"<%}%>' +
      '<% if(typeof secondaryDescription !== "undefined") { %> data-secondary-description="<%=secondaryDescription%>"<%}%>' +
      '<% if(typeof placeId !== "undefined") { %> data-place-id="<%=placeId%>"<%}%>' +
      '<% if(typeof longitude !== "undefined") { %> data-longitude="<%=longitude%>"<%}%>' +
      '<% if(typeof radius !== "undefined") { %> data-radius="<%=radius%>"<%}%>' +
      '<% if(typeof latitude !== "undefined") { %> data-latitude="<%=latitude%>"<%}%>' +
      '<% if(typeof address !== "undefined") { %> data-address="<%=address%>"<%}%>' +
      '<% if(typeof postalCode !== "undefined") { %> data-postalCode="<%=postalCode%>"<%}%>' +
      '<% if(typeof types !== "undefined") { %> data-types="<%=types%>"<%}%>' +
      '<% if(typeof website !== "undefined") { %> data-website="<%=website%>"<%}%>' +
      '<% if(typeof stateprovincedisplayname !== "undefined") { %> data-state-province="<%=stateprovincedisplayname%>"<%}%>' +
      '<% if(typeof citypopulationdensity !== "undefined") {%> data-city-population-density="<%=citypopulationdensity%>"<%}%> data-label="<%=label%>">' +
      '<% if(typeof primaryDescription !== "undefined" && primaryDescription) { %><strong><%=primaryDescription%></strong><% if(typeof secondaryDescription !== "undefined" && secondaryDescription) { %>, <%=secondaryDescription%><%}%><%} else {%> <%=label%> <%}%></a></li>'),
    autoCompleteCluster: _.template(
    '<li class="autocomplete-listitem <% if( index == 0 ) { %> autocomplete-listitem-active <% } %>" role="menuitem">' +
      '<a data-index="<%=index%>" data-type="<%=(typeof type !== "undefined")?type:""%>"  data-code="<%=ratecluster%>"'+
      'data-number="<%=(typeof rateclustersetnum !== "undefined")?rateclustersetnum:""%>" data-number-type="<%=(typeof rateclustersetnumtype !== "undefined")?rateclustersetnumtype:""%>"' +
      'data-desc="<%=displaydescription%>" >' +
      '<%=ratecluster%>[SET#<%=(typeof rateclustersetnum !== "undefined")?rateclustersetnum:""%>] - <%=displaydescription%></a>' +
      '</li>')
  },
  templateSettings: {
    interpolate: /{{([\s\S]+?)}}/g,
    variable: '_data',
    imports: {
      any: function(one, two) {
        return one || two;
      },
      mergeItem: function(seperator) {

        var result = '', idx = 0;
        for(idx = 1; idx < arguments.length; idx++) {
          result = result + ( (arguments[idx] && (
              ( (result && seperator) || '') + arguments[idx]) ) || '' )
        }
        return result;
        },
       /* cluster code format for input box*/
        clusterFormat: function(code, number) {
          return (code + ' ['+number+']');
        }
    }
  },
  events: {
    'keyup .js-auto-complete-input-v2': 'getChoiceList',
    'keydown .js-auto-complete-input-v2': 'navigateList'
  },
  subscribe: {
    'GOOGLE_API_LOADED': 'populateCurrentLocation'
  },
  init: function(){
    var _self = this;
    var options = _self.options;
    //Clear cluster code related hidden value on load
    _self.clearClusterCodeData();
    var autoCompleteOptions = this.$el.data('options');
    _self.options.placesUrl = autoCompleteOptions.placesUrl;
    _self.options.autoCompleteURL = autoCompleteOptions.autoCompleteURL;
    _self.options.suggestionSortOrder = autoCompleteOptions.suggestionSortOrder;
    _self.options.clusterSearchHandler = _self.clusterSearchHandler;
    
    //If permitted locales string is not empty
    if (autoCompleteOptions.permittedLocales) {
      _self.permittedLocales = autoCompleteOptions.permittedLocales.split(',');
    }
    _self.googleMap = this.$parent.$el.find(".search-google-map").data('options');

    _self.$el.find('.autocomplete-list').remove();
    _.extend(_.templateSettings, this.templateSettings);

    _self.subscribeDOMEvents();
    _self.subscribePubSubMessages();
    if (!_self.$parent.hideCurrentLocation) {
      _self.initGeoLocationHandler();
      _self.initGoogleApi();
    }
    _self.initClearInputHandler();

    // set category parameters from dom element
    if (_self.$parent.$el.find('.autosuggest-cat-headers').length) {
      _self.options.categoryParams = _self.$parent.$el.find('.autosuggest-cat-headers').data('category-params');
    }

    _self.$scrollbarWrapper = $('<div class="autocomplete-scroller-wrapper custom-wrapper"><ul></ul></div>');
    _self.$autoCompleteList = _self.$scrollbarWrapper.find('ul');
    
    _self.$autoCompleteList.addClass('autocomplete-list').css({
        'z-index': 999,
        'top': '0px',
        'left': '0px',
        'display': 'none'
      })
      .on('mousedown', '.autocomplete-listitem a', _.bind(_self.listItemSelect, _self))
      .on('mouseover', '.autocomplete-listitem a,.autocomplete-list-header', _.bind(_self.highLightListItem, _self))
      .on('mousedown', '.clear-recent-search', _.bind(_self.clear, _self));

      _self.$scrollbarWrapper.css({
        'display': 'none'
      });

    _self.$narrate = this.$el.find('div[aria-live="assertive"]');
    _self.$el.append(_self.$scrollbarWrapper).append(_self.$narrate).find(_self.$autoCompleteList);
    _self.$input = _self.$el.find('.js-auto-complete-input-v2').attr('aria-autocomplete','list');
    _self.$input.on('blur', _.bind(_self.hideSuggestions, _self, null, true));
    _self.recentSearchTerm = _self.$input.val();
    _self.addRecent();
    _self.lat = 0;
    _self.long = 0;
    if(_self.enableUserLocation) {
      _self.setLatLong();
    }
  },

   /**
  * This function is used to set and store latitude and longitude in local storage.
  * @return null
  **/
  setLatLong: function() {
    var _self = this;
    var latLongObj = JSON.parse(window.localStorage.getItem('latLongObj'));
    if(latLongObj && latLongObj.sessionId === _self.sessionId) {
      _self.lat = latLongObj.lat || 0;
      _self.long = latLongObj.long || 0;
    } else {
      window.localStorage.removeItem('latLongObj');
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(function(position){
          latLongObj = {};
          latLongObj.lat = position.coords.latitude;
          latLongObj.long = position.coords.longitude;
          latLongObj.sessionId = _self.sessionId;
          _self.lat = latLongObj.lat || 0;
          _self.long = latLongObj.long || 0;
          window.localStorage.setItem('latLongObj',JSON.stringify(latLongObj));
        });
      }
    }
  },

  populateCurrentLocation: function() {
    var _self = this;
    if(sessionStorage && Cookies.readCookie('loadCurrentLocation') === "true") {
      Cookies.eraseCookie('loadCurrentLocation');
      _self.geoLocationHandler.doLocalDetails();
    }
  },
  /**
  * This function creates a geolocation object that is used for current location search.
  * @return null
  **/
  initGeoLocationHandler: function() {
    this.geoLocationHandler = new GeoLocationHandler({
      $el: this.$el,
      $parent: this.$parent,
      autoComplete: this
    });
    this.geoLocationHandler.init();
  },
  /**
  * This function loads google maps API.
  * @param callback - called if google maps API is loaded successfully
  * @param fallback - called if google maps API fails to load
  * @return null
  **/
  initGoogleApi: function() {
    var _self = this;
    if (_self.googleMap) {
      var googleApi = GoogleApi.create({
        apiUrl: _self.googleMap.api
      });    
      googleApi.init();
    }
  },
  /**
  * This function create handler object for clear button present on search input box.
  * @return null
  **/
  initClearInputHandler: function() {
    this.clearInputHandler = new ClearInputHandler({
      $el: this.$el,
      $parent: this
    });
    this.clearInputHandler.init();
  },
  /**
  * This function checks if current site id is present in list of permitted locales.
  * @return true|false
  * Returns true if permitted locales array is empty
  **/
  permittedSiteId: function() {
    var siteID = ($('html').attr('lang') || $('html').attr('xml:lang'));
    if (_.isEmpty(this.permittedSiteId)) {
      return true;
    }
    return (siteID && _.includes(this.permittedLocales, siteID));
  },
  /**
  * This function determines ajax call to get data for a specific keyword search.
  * @return null
  **/
  getChoiceList: function(evt) {
    var _self = this;
    var searchTerm = evt.target.value,
      parseCall = _.bind(_self.parseResult, _self, searchTerm, $(evt.target));
    _self.options.searchTypeVal = _self.$parent.$el.find(_self.options.searchType).val();
    if (!searchTerm.length || (_self.recentSearchTerm !== searchTerm)) {
       if(_self.recentSearchTerm !== searchTerm){
        delete(_self.initialSearchTerm);
       }
      if(!_self.options.clusterSearchHandler){
        _self.resetInputs();
      }
      else{
        if(_self.xhrRequest) {
          _self.xhrRequest.abort();
        }
        _self.listHtml='';
      }  
    }
    if (!searchTerm.length) {
      _self.suggestionListShow = false;
    }
    if (!searchTerm.length||(_self.recentSearchTerm === searchTerm)) {
      if (((evt.keyCode === 38) || (evt.keyCode === 40) || (evt.keyCode === 8)) && !_self.suggestionListShow) {
        if ((_self.options.searchTypeVal !== _self.options.addressSearch) && !_self.listHtml) {
          if(!_self.isDesktopVersion()){
            _self.suggestGeoLoc();
          }
          _self.suggestRecent( searchTerm );
        }
        _self.showList($(evt.target));
      }
      _self.recentSearchTerm = searchTerm;
      return;
    }

    _self.recentSearchTerm = searchTerm;
    if(_self.options.clusterSearchHandler){
      _self.performClusterSearch(parseCall);
    }
    else{
      _self.performLocationSearch(parseCall) ;
    }
  },
  /**
  * This function performs location search on basis of search term.
  * @return null
  **/
  xhrRequest: false,
  performLocationSearch: function(parseCall) {
    var _self = this;
    var searchRequest = {
        type: 'GET',
        url: _self.options.placesUrl,
        contentType: 'text/html',
        cache: true,
        data: {
          searchTerm: _self.recentSearchTerm,
          suggestionSortOrder: _self.options.suggestionSortOrder,
          latitude: _self.lat,
          longitude: _self.long
        },
        success: parseCall
      };

      if(_self.xhrRequest) {
        _self.xhrRequest.abort();
      }
      _self.xhrRequest = _self.makeAjaxCall(searchRequest);
  },
   performClusterSearch: function(parseCall) {
      var _self = this;
      var searchRequest = {
          type: 'GET',
          url: _self.options.autoCompleteURL,
          contentType: 'text/html',
          data: {
            searchTerm: _self.recentSearchTerm,
            suggestionSortOrder: _self.options.suggestionSortOrder
          },
          success: parseCall
        };

        if(_self.xhrRequest) {
          _self.xhrRequest.abort();
        }
        _self.xhrRequest = _self.makeAjaxCall(searchRequest);
    },
  /**
  * This function helps in hiding autocomplete list.
  * @return null
  **/
  hideSuggestionsFlag: true,
  blankLocationInputFlag: true,
  hideSuggestions: function(event, suppressCallback) {
    var _self = this, 
    inputMap='',
    searchInput = _self.$el.find('.single-search').val();

    if(_self.hideSuggestionsFlag){
      _self.$autoCompleteList.hide();
      _self.$scrollbarWrapper.scrollTop(0).parent().find('.scroll-bar').css('top','0px')
      _self.$scrollbarWrapper.hide();
      _self.suggestionListShow = false;
      _self.$parent.$el.find('.js-find-container .l-h-field').text(searchInput);
      if(_self.blankLocationInputFlag && _self.onListClose && !suppressCallback) {
        _self.onListClose();
      }
      if(_self.options.clusterSearchHandler && suppressCallback && event!=null){
        inputMap={
        hiddenClusterCode: event.target.value,
          rateClusterSetNum: null,
          rateClusterSetNumType: 'unknownCode'
        };
        _.each(inputMap, function(value, name) {
          _self.$parent.$el.find('input[name='+name+']').val(value || '');
        }, _self);
        if(event){
          event.preventDefault(); 
          _self.listItemSelect(event);
        }
      }
      // this is used to prevent popup close on click of auto complete suggestions when autocomplete is on a popup and suggestions exceed the popup area
      setTimeout(function(){
        $('body').removeClass('disable-click');
      }, 100);
    }
  },
  resetInputs: function() {
    var _self = this;
    var autoSuggestItemType='.js-autosuggest-item-type';
    var isAdvSearchAssociate=_self.$el.hasClass('l-adv-search-associate');
    var isAssociateSearchWrapper=_self.$parent.$el.hasClass('adv-search-associate-form');
    _self.listHtml = '';
    _self.listCount = 0;
    _.forEach(_self.options.inputs, function(selectors) {
      if((isAdvSearchAssociate || isAssociateSearchWrapper) && selectors===autoSuggestItemType){
        return;
      }
      _self.$parent.$el.find(selectors).val('');
      if(_.isFunction(_self.$parent.propertyCodeChangeHandler)){
        _self.$parent.propertyCodeChangeHandler();
      }
    }, _self);
    _self.$parent.$el.find(_self.options.inputs.singleSearchAuto).val(false);
  },
  /**
  * This function creates recent search column in autocomplete list.
  * @return null
  **/
  suggestRecent:function(searchTerm) {
    var _self = this;
    if (_self.permittedSiteId()) {
      var recentSearchList = _self.getAutocompleteItem(searchTerm, _self.$input),
      renderList = '';
    }

    if ( recentSearchList && recentSearchList.length ) {
      //Recent category header
      renderList += "<li class='autocomplete-list-header'><span class='t-icon-clock autocomplete-recent-icon'></span> <strong>" +
      _self.options.categoryParams.recent +
      '</strong><span class="t-control-link clear-recent-search">' +
      _self.options.categoryParams.clear + "</span></li>";
      for (var i = 0; i < recentSearchList.length; i++) {
        recentSearchList[i].index = _self.listCount + i;
        renderList += _self.markSearchTerm( searchTerm, _self.templates.autoCompleteRecent( recentSearchList[i] ) );
      }
      _self.listHtml += renderList;
      _self.listCount += recentSearchList.length;
    }
    return _self.listCount;
  },
  /**
  * This function sets autocomplete list to its container and modifies css accordingly.
  * @return null
  **/
  showList: function( inputTarget) {
    var _self = this;
    if(_self.disablePopupCloseOnSuggestion){
      $('body').addClass('disable-click');
    }
    _self.blankLocationInputFlag = true;
    if (_self.listCount) {
       if(_self.options.clusterSearchHandler) {
        _self.$input = $(inputTarget);
        _self.$autoCompleteList = _self.$input.parent().find('.autocomplete-scroller-wrapper ul');
        _self.$input.parent().find('.autocomplete-scroller-wrapper').show();
        _self.$autoCompleteList.show().html(_self.listHtml);
        setTimeout(function(){
          _self.$autoCompleteList.width(inputTarget.outerWidth());
        },100);
      } else {
        _self.$autoCompleteList.html(_self.listHtml);
      }
      var narrate="";
      var destinationLabelHeight = _self.$parent.$el.find('.homepage-search-labels').length ? 0 : _self.$parent.$el.find('.field-title').height();

      if( _self.$autoCompleteList.filter(':hidden') ) {
        _self.$autoCompleteList.show();
        _self.$scrollbarWrapper.show();
      }

      _self.$autoCompleteList.css({          
        left: '0px',
        width: inputTarget.outerWidth() + 'px'
      });

      // Adding mousedown event to detect elements where no event should take place on click
      _self.$autoCompleteList.mousedown(function(e){
        if($(e.target).hasClass('t-autosuggest-msg') || $(e.target).hasClass('autocomplete-list-header')){
          _self.hideSuggestionsFlag = false;
        }else{
          _self.hideSuggestionsFlag = true;
          _self.blankLocationInputFlag = false;
          _self.hideSuggestions(null,false);
        }
      });

      _self.$input.attr('data-count', _self.listCount);
      _self.suggestionListShow = _self.listCount > 0;

      //ask narrator to speak the options
      if (_self.$autoCompleteList.find('.autocomplete-listitem-active a').length) {
        narrate = _self.$autoCompleteList.find('.autocomplete-listitem-active a').text();
        _self.$narrate.text( narrate );
      }

      var autoCompleteListHeight = _self.$el.find('.autocomplete-list').height(),
          autoCompleteMaxHeight = _self.$scrollbarWrapper.height(),
          isDefaultList = true;

      //handle autocomplete list with options and without options
      if (autoCompleteListHeight > autoCompleteMaxHeight) {
        isDefaultList = false;
      }else{
        isDefaultList = true;
      }

    } else if (!_self.listCount && !_self.listHtml) {
      _self.hideSuggestions(null, true);
    }
  },
  /**
  * This function generates autocomplete list as per API response.
  * @return null
  **/
  parseResult: function(searchTerm, inputTarget, data) {
    var _self = this;
    var listHtml = '';
    _self.listHtml = '';

    // For cluster autosuggest - this identifier is used 
    if(_self.options.clusterSearchHandler){
      if((Object.keys(data)).length > 0){
        listHtml = _self.filterClusterSearchData(data);
      }else{
        _self.clearClusterCodeData();
      }
    }
    else{
      listHtml = _self.filterLocationSearchData(data);
    }
    
    _self.listHtml += listHtml;
    _self.showList (inputTarget, true);
  },
  /** Clear cluster code related hidden input text from form**/
  clearClusterCodeData:function(){
    this.$el.find('input[name="hiddenClusterCode"],input[name="rateClusterSetNum"],input[name="rateClusterSetNumType"]').val('');
  },
  /**
  * This function creates autocomplete list out of location search response.
  * @return Html for list
  **/
  filterLocationSearchData: function(data) {
    var _self = this;
    var resultType, autocompleteData;
    var listHtml = '';
    var categoryItems = 0;
    autocompleteData = _.extend({ index: count}, _self.templateSettings.imports);
    var count = _self.suggestRecent(_self.recentSearchTerm);
    if(data.suggestions){
       listHtml += _self.templates.autoListHeader({
         category: _self.options.categoryParams['searchResults']
       });
    }else{
      if(!_self.isDesktopVersion()){
          _self.suggestGeoLoc();
        }
    }
    _.each(data.suggestions, function(suggestion) {
          autocompleteData['index'] = count;
          autocompleteData['primaryDescription'] = suggestion.primaryDescription;
          autocompleteData['secondaryDescription'] = suggestion.secondaryDescription;
          autocompleteData['placeId'] = suggestion.placeId;
          listHtml += _self.markSearchTerm( _self.recentSearchTerm, _self.templates.autoCompleteCustom(autocompleteData));
          count++;
    });
    _self.listCount = count;
    return listHtml;
  },
  
  /** 
   * This function is specifically for cluster
   * @return list of options 
   **/
  filterClusterSearchData: function(data) {
      var _self = this,jsonData='';
      var tabValue = this.$parent.$el.find('.js-searchtype').val();
      var resultType, autocompleteData;
      var listHtml = '';
      var categoryItems = 0;
      var count = _self.suggestRecent(_self.recentSearchTerm);
     if(data.clusterDetailsList){
        jsonData=data.clusterDetailsList;
      }else{
        jsonData=data.clusters;
      }
      _.each(jsonData, function(suggestions) {
        autocompleteData = _.extend({ index: count}, _self.templateSettings.imports);
          categoryItems = 0;
        if( _self.options.maxCategory > categoryItems ) {
            _.each(suggestions, function(value, key){
                autocompleteData[key.toLowerCase()] = value;
                if(tabValue === 'InHotelSearch'){
                  autocompleteData['type']='property';
                }
            });
            autocompleteData['index'] = count;
            autocompleteData['searchterm'] = _self.recentSearchTerm;
            listHtml += _self.markSearchTerm( _self.recentSearchTerm, _self.templates.autoCompleteCluster(autocompleteData) );
            count++;
        }
        categoryItems++;
      });

      _self.listCount = count;
      return listHtml;
    },
  /**
  * This function handles click on an item selected through autocomplete list.
  * @return null
  **/
  xhrPlacesRequest: false,
  listItemSelect: function(evt) {
    var _self = this;
    _self.blankLocationInputFlag = false;
    var $selectedItem = _self.$autoCompleteList.find('.autocomplete-listitem-active a'),
      placeId = $selectedItem.attr('data-place-id');
    // Won't set location field value if selected item is not available
    if (!$selectedItem.length) {
      _.bind(_self.hideSuggestions(), _self);
      if(_self.options.clusterSearchHandler){
        inputMap={
          hiddenClusterCode: $($selectedItem).val(),
          rateClusterSetNum: null,
          rateClusterSetNumType: 'unknownCode'
        };
        _.each(inputMap, function(value, name) {
          _self.$parent.$el.find('input[name='+name+']').val(value || '');
        }, _self);
      }
      return;
    }
    if(placeId && ($selectedItem.attr('data-searchtype')!=='recent')){
     var placesRequest = {
        type: 'GET',
        url: _self.options.placesUrl,
        contentType: 'text/html',
        cache: true,
        data: {
          placeId: placeId
        },
        success: _.bind(_self.populateInputMap,_self,$selectedItem)
      };

      if(_self.xhrPlacesRequest) {
        _self.xhrPlacesRequest.abort();
      }
      _self.xhrPlacesRequest = _self.makeAjaxCall(placesRequest);
    }else{
      _self.populateInputMap($selectedItem);
    }
  },
  /**
  * This function populates the data into hidden fields.
  * @return null
  **/
  populateInputMap: function($selectedItem,data){
    var _self=this,locDetails={},radiusValue,destinationDetails={};
    try{
    if(_self.options.clusterSearchHandler){
      var inputMap={
        hiddenClusterCode: $selectedItem.attr('data-code'),
        rateClusterSetNum: $selectedItem.attr('data-number'),
        rateClusterSetNumType: $selectedItem.attr('data-number-type')
      };
      _self.$input.val(_self.recentSearchTerm = (_self.templateSettings.imports.mergeItem(", ", _self.templateSettings.imports.clusterFormat(inputMap.hiddenClusterCode , inputMap.rateClusterSetNum))));
      _.each(inputMap, function(value, name) {
        _self.$parent.$el.find('input[name='+name+']').val(value || '');
      }, _self);
      if (_self.onListItemSelect) {
        _self.onListItemSelect(inputMap);
      }
      return;
    }
    if ($selectedItem.hasClass('js-geoloc')) {
      _self.geoLocDetails();
    }
    if(data){
      locDetails = data.suggestions[0].details.location;
      radiusValue = data.suggestions[0].details.distance;
      destinationDetails.address = data.suggestions[0].details.location.address;
      destinationDetails.postalCode = data.suggestions[0].details.location.postalCode;
      destinationDetails.types = data.suggestions[0].details.types?data.suggestions[0].details.types.join(','):null;
      destinationDetails.website = data.suggestions[0].details.url;

    }else{
       geoCode = $selectedItem.attr('data-geo');
       if(geoCode){
        geoCode = (geoCode && geoCode.split(',')) || ['', ''];
        locDetails.latitude = geoCode[0];
        locDetails.longitude = geoCode[1];
       }
    }
    var inputMap = {
      latitude: locDetails.latitude||$selectedItem.attr('data-latitude'),
      longitude: locDetails.longitude||$selectedItem.attr('data-longitude'),
      route: $selectedItem.attr('data-route'),
      cityPop: $selectedItem.attr('data-city-population'),
      cityPopDensity: $selectedItem.attr('data-city-population-density'),
      city: locDetails.city||$selectedItem.attr('data-city'),
      state: locDetails.state||$selectedItem.attr('data-state'),
      countryname: $selectedItem.attr('data-country'),
      country: locDetails.country || $selectedItem.attr('data-countrycode'),
      poiname: $selectedItem.attr('data-poi-name'),
      airport: $selectedItem.attr('data-airport-code'),
      airportName: $selectedItem.attr('data-airport-name'),
      singleSearchAuto: null,
      autoSuggestOmni: locDetails.property?'property':$selectedItem.attr('data-type'),
      stateProvince: $selectedItem.attr('data-state-province'),
      propertyName: $selectedItem.attr('data-property-name'),
      propertyCode: locDetails.property||$selectedItem.attr('data-property-code'),
      searchType: $selectedItem.attr('data-searchtype'),
      primaryDescription: $selectedItem.attr('data-primary-description'),
      secondaryDescription: $selectedItem.attr('data-secondary-description'),
      placeId: $selectedItem.attr('data-place-id'),
      radius: radiusValue||$selectedItem.attr('data-radius'),
      address: destinationDetails.address||$selectedItem.attr('data-address'),
      postalCode: destinationDetails.postalCode||$selectedItem.attr('data-postalCode'),
      types: destinationDetails.types||$selectedItem.attr('data-types'),
      website: destinationDetails.website||$selectedItem.attr('data-website')
    };

    if(inputMap.autoSuggestOmni) {
      inputMap.singleSearchAuto = 'true';
    } else {
      inputMap.singleSearchAuto = 'false';
      inputMap.singleSearchAuto = 'Unmatched';
    }
 
    if(!!inputMap.clusterCode) {
      _self.$input.val(_self.recentSearchTerm = (_self.templateSettings.imports.mergeItem(", ", _self.templateSettings.imports.clusterFormat(inputMap.clusterCode , inputMap.clusterDesc))));
    } else {
      if($selectedItem.attr('data-secondary-description')){
        _self.$input.val(_self.recentSearchTerm = $selectedItem.attr('data-primary-description')+", "+$selectedItem.attr('data-secondary-description'));
      }else{
        _self.$input.val(_self.recentSearchTerm = $selectedItem.attr('data-primary-description'));
      }
    }
    
    inputMap.city = ( inputMap.airport || inputMap.city);
    inputMap.showAddressPin = this.isSpecificAddrType(destinationDetails.types||$selectedItem.attr('data-types')); 
    _.forEach(inputMap, function(value, name) {
      _self.$parent.$el.find(_self.options.inputs[name]).val(value || '');
    }, _self);

    if (inputMap.searchType === 'recent') {
      if($selectedItem.attr('data-primary-description')){
        _self.recentSearchTerm = $selectedItem.attr('data-secondary-description')?$selectedItem.attr('data-primary-description')+", "+$selectedItem.attr('data-secondary-description') :$selectedItem.attr('data-primary-description');
        _self.$el.find('.single-search-destination').val($selectedItem.attr('data-secondary-description')?$selectedItem.attr('data-primary-description')+", "+$selectedItem.attr('data-secondary-description'):$selectedItem.attr('data-primary-description'));
      }else{
        _self.recentSearchTerm = $selectedItem.attr('data-label');
        _self.$el.find('.single-search-destination').val($selectedItem.attr('data-label'));
      }
    }

    _self.hideSuggestions();

    if (_self.onListItemSelect) {
      _self.onListItemSelect(inputMap);
    }

    if(_self.isDesktopVersion()) {
      setTimeout(function() {
        _self.$input.focus();
      });
    }
  }
    catch(err){
      console.error(err);
    }

  },
  /**
   * Check if searched destination is one out of 'establishment', 'premise', 'route', 'street_address'
   * @param {Object} geocodeResult - Google api geocode result
   * @returns {Boolean} TRUE if geocode result is a state
   */
  isSpecificAddrType: function(geocodeResult) {
    var specificAddresses = ['establishment', 'premise', 'route', 'street_address'];        

    if (!geocodeResult) {
      return false;
    }
    return geocodeResult.split(',').some(function(item, index){            
      if($.inArray(item, specificAddresses) > -1){        
        return true;
      }
    });       
  },
  /**
  * This function handles geo location details when the protocol is http.
  * @return null
  **/
  geoLocDetails: function() {
    var _self = this;
    var protocol = 'https:';
    if (window.location.protocol !== "https:") {
      Cookies.createCookie('loadCurrentLocation', true);
      window.location.replace(protocol + '//' + window.location.host + window.location.pathname);
    } else {
      _self.geoLocationHandler.doLocalDetails();
    }
  },
  /**
  * This function highlights current option on hover in autocomplete list.
  * @return null
  **/
  highLightListItem: function(evt) {
    var _self = this;
    if ( evt.target ) {
      evt.stopImmediatePropagation();

      _self.$autoCompleteList.find('.autocomplete-listitem-active').removeClass('autocomplete-listitem-active');
      if(!$(evt.target).hasClass('autocomplete-list-header')) {
        var $thisTarget = (evt.target.nodeName == "A")? $(evt.target): $(evt.target).closest('a');
        $thisTarget.parent().addClass('autocomplete-listitem-active');
      }
    }
  },
  /**
  * This function used to return the value to desktop version true/false
  * @return boolean value
  **/
  isDesktopVersion: function(){
    return !this.$parent.responsiveUtils.isMobileOrTablet();
  },
  /**
  * This function decides whether or not to show current location option in autocomplete list.
  * @return null
  **/
  suggestGeoLoc:function() {
    var _self =  this;
    if (!_self.$parent.hideCurrentLocation) {
      _self.listHtml = _self.geoLocationHandler.autoSuggestItem;
      if(_self.listHtml === '') {
        _self.listCount = 0;
      }else{
        _self.listCount = 1;
      }
    }
  },
  /**
  * This function highlights current search term in the resulted autocomplete list.
  * @return null
  **/
  markSearchTerm: function(searchTerm, nodeText) {
    if(searchTerm) {
      var searchTermRegx = new RegExp(searchTerm, 'ig');
      return nodeText.replace(/>([^><]+)<\//i, function(label) {
        return label.replace(searchTermRegx, function(match) {
          return '<strong>' + match + '</strong>';
        })
      });
    }else{
      return nodeText;
    }
  },
  /**
  * This function is called when user navigates the list up and down with keyboard.
  * @return null
  **/
  navigateList: function(evt) {
    var _self = this,$selectedItem='',inputMap='';
    if(!_self.$input.length) {
      _self.setLocationInput();
    }
    if(_self.suggestionListShow) {
      var keyCode = evt.keyCode || evt.which;
      var dataCount = parseInt(evt.target.getAttribute('data-count'));

      /**
      * Keycodes
      *  38 - Up
      *  40 - Down
      *  37 - Left
      *  39 - Right
      *  13 - Enter
      *  27 - Esc
      *  9 - Tab
      *  8 - Backspace
      **/
      switch(keyCode) {
        case 38:
        case 40:
          evt.preventDefault();
          var $selectedItem = _self.$autoCompleteList.find('.autocomplete-listitem-active');
          var dataIndex = $selectedItem.length && $selectedItem.find('a').attr('data-index') || -1;
          //For first element in list
          if(parseInt(dataIndex) === 0) {
            if(!_self.initialSearchTerm) {
              _self.initialSearchTerm = _self.$input.val();
            }
            if (keyCode === 38) {
              _self.recentSearchTerm = _self.initialSearchTerm;
              _self.$input.val(_self.recentSearchTerm);
              $selectedItem.removeClass('autocomplete-listitem-active');
              return;
            }
          }

          //For last element in list
          if (((parseInt(dataCount) - parseInt(dataIndex)) === 1) && keyCode === 40){
            _self.recentSearchTerm = _self.initialSearchTerm;
            _self.$input.val(_self.recentSearchTerm);
            $selectedItem.removeClass('autocomplete-listitem-active');
            return;
          }

          if( isFinite(dataIndex = parseInt(dataIndex)) ) {

            $selectedItem.removeClass('autocomplete-listitem-active');
            dataIndex = dataIndex + (keyCode - 39);

            if (dataIndex >= dataCount) {
              dataIndex -= dataCount;
            } else if( dataIndex < 0 ) {
              dataIndex = Math.max(-1, dataIndex) + dataCount;
            }

            $selectedItem = _self.$autoCompleteList.find('[data-index="' + dataIndex + '"]');
            $selectedItem.closest('li').addClass('autocomplete-listitem-active');
          }
          if($selectedItem.attr('data-primary-description')){
              if($selectedItem.attr('data-secondary-description')){
                _self.recentSearchTerm = $selectedItem.attr('data-primary-description')+", "+$selectedItem.attr('data-secondary-description');
              }else{
                _self.recentSearchTerm = $selectedItem.attr('data-primary-description');
              }
          }else{
            _self.recentSearchTerm = $selectedItem.attr('data-label');
          }
          

          if ($selectedItem.hasClass('js-geoloc')) {
            _self.recentSearchTerm = '';
          }

          if ( $selectedItem.hasClass('js-geoloc') ) {
            _self.$narrate.text( $selectedItem.text() );
          };

          _self.$input.val( _self.recentSearchTerm );

          /** scroller with keyboard up and down keys*/
          if(_self.isDesktopVersion()){
            _self.$scrollbarWrapper.scrollTop(0);//set to top
            var selectedItemOffset = $selectedItem.parent('li').next().offset().top;
            _self.$scrollbarWrapper.scrollTop(selectedItemOffset - _self.$scrollbarWrapper.height()*2);            
          }

          break;
        case 37:
        case 39:
          _self.recentSearchTerm = _self.initialSearchTerm;
          break;
        case 13:
        $selectedItem=evt.target.value;
          if(_self.options.clusterSearchHandler){
            inputMap={
              hiddenClusterCode: $selectedItem,
              rateClusterSetNum: null,
              rateClusterSetNumType: 'unknownCode'
            };
            _.each(inputMap, function(value, name) {
              _self.$parent.$el.find('input[name='+name+']').val(value || '');
            }, _self);
            evt.preventDefault();
            _self.listItemSelect(evt);
          }
          if(_self.$autoCompleteList.find('.autocomplete-listitem-active').length) {
            evt.preventDefault();
            _self.listItemSelect(evt);
          }
          break;
        case 27:
          _self.hideSuggestions(null, true);
          evt.preventDefault();
          break;
        case 9:
          _self.listItemSelect(evt);
          break;
        default:
          break;
      }
    }
  },
  /**
  * This function is utility Function to merge the given inputs
  * @return null
  **/
  merge: function(list, seperator) {
    var result = '', idx = 0;
    for(idx = 0; idx < list.length; idx++) {
      result + (list[idx] && (list[idx] + seperator)) || ''
    }
  },
  /**
  * This function loads recent search list from browsers local storage
  * @return null
  **/
  loadList: function(){
    if ( localStorage.default.miRecentSearch ) {
      this.recentSearch.list = $.parseJSON( localStorage.default.miRecentSearch.replace(/<\/?[^>]+(>|$)/g, "") );
    }
  },
  /**
  * This function is utility Function
  * @return null
  **/
  getAutocompleteItem: function(filterChars,$formField){
    if ( $formField.hasClass(this.recentSearch.inputClass) ) {
      this.loadList();
      if ( this.recentSearch.list.length > 0 ) {
        /*if filterChars is supplied, filter the list.labels for lowercase of filterchars (searchterm)*/
        if (filterChars) {
          return $.grep( this.recentSearch.list , function( o, i ) {
                // return the items that match the starting of words
                return RegExp("\\b" + filterChars.toLowerCase(), "gi").test(o.label);
              });
        } else {
          return this.recentSearch.list;
        }
      }
    }
  },
  /**
  * This function is utility Function
  * @return null
  **/
  indexOf: function( key, value ){
    var list = this.recentSearch.list;
    var max = list.length;
    var result = -1;
    for (var i = 0; i < max; i++) {
      if(list[i][key] == value) {
        result = i;
        break;
      }
    }
    return result;
  },
  /**
  * This function adds recent search list to browsers local storage
  * @return null
  **/
  addRecent: function(){
    var _self = this;
    if(this.permittedSiteId() && localStorage.default && this.options.searchTypeVal !== _self.options.addressSearch) {
      var newItem = {};
      var editSearchForm = this.$parent.$el.find('.js-recent-search-inputs');
      var countryName, labelArray, airportname;
      var nearText = this.$parent.$el.find('.for-hotels-nearme').val() + ' ';

      newItem.label = editSearchForm.find('.js-search-location').val();
      newItem.city = editSearchForm.find('.js-search-city-display').val() || editSearchForm.find('.search-city').val();
      newItem.poiname = editSearchForm.find('.search-poiname').val();
      newItem.state = editSearchForm.find('.search-state').val();

      newItem.country = editSearchForm.find('.search-country').val();
      newItem.stateprovincedisplayname = editSearchForm.find('.search-state-province').val();
      newItem.type = editSearchForm.find('.js-autosuggest-item-type').val();
      countryName = editSearchForm.find('.search-country-name').val();
      if(countryName) {
        newItem.countryname = countryName;
      }else {
        labelArray = newItem.label ? newItem.label.split(',') : [];
        if(labelArray.length) {
          newItem.countryname = labelArray[(labelArray.length - 1)];
        }
      }

      newItem.airportcode = editSearchForm.find('.search-airport').val();
      if (newItem.airportcode) {
        airportname = editSearchForm.find('.search-airport-name').val();
        if(airportname) {
          newItem.airportname = airportname;
        }else {
          labelArray = newItem.label ? newItem.label.split(',') : [];
          if(labelArray.length) {
            newItem.airportname = labelArray[0];
          }
        }
      }

      newItem.value = newItem.airportcode || newItem.label;
      newItem.geocode = editSearchForm.find('.search-latitude').val() + ',' +  editSearchForm.find('.search-longitude').val();
      newItem.searchtype = 'recent';
      newItem.analytics = '{"location":"searchForm","sendNow":"true","description":"Recent Search"}';
      newItem.label = newItem.label && newItem.label.replace(/<(?:.|\n)*?>/gm, '');
      newItem.placeId = editSearchForm.find('.search-place-id').val();
      newItem.primaryDescription = editSearchForm.find('.search-primary-description').val();
      newItem.secondaryDescription = editSearchForm.find('.search-secondary-description').val();
      newItem.latitude = editSearchForm.find('.search-latitude').val();
      newItem.longitude = editSearchForm.find('.search-longitude').val();
      newItem.radius = editSearchForm.find('.js-search-radius').val();
      newItem.address = editSearchForm.find('.destination-address').val();
      newItem.postalCode = editSearchForm.find('.destination-postal-code').val();
      newItem.types = editSearchForm.find('.destination-types').val();
      newItem.website = editSearchForm.find('.destination-website').val();



      this.loadList();

      if ( newItem.label && newItem.label.indexOf(nearText)<0) {

        var indexOfExistingValue = this.indexOf( 'label', newItem.label );

        if ( indexOfExistingValue != -1 ) {
          //remove the existing entry of same search
          this.recentSearch.list.splice ( indexOfExistingValue, 1 );
        }

        this.recentSearch.list.unshift( newItem );
        this.recentSearch.list.splice( this.recentSearch.max );
        localStorage.default.setItem('miRecentSearch', JSON.stringify( this.recentSearch.list) );
      }
    }
  },
  /**
  * This function is used to clear recent searches
  * @return null
  **/
  clear:function(){
    localStorage.default.removeItem('miRecentSearch');
    localStorage.default.removeItem('recentSearch');
    this.recentSearch.list=[];
  }
};


module.exports = {
  create: function(props) {
    var obj = _.cloneDeep(properties);
    var AutocompleteInstance = ComponentMapper.extend(obj);
    return new AutocompleteInstance(props);
  }
}
